import React, { Component } from "react";
import { fetchAllContests } from "../../store/actions/contestActions";
import Cookie from "js-cookie";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import { USER_TYPE } from "../../utils/constants";
import dateFormat from "dateformat";
import ReactHtmlParser, { } from 'react-html-parser';
import { formatDate } from "../../utils/utils";

class AdminContestList extends Component {

  state = {
    isLoaded: false,
    contests: [],
    accountType: null
  };


  /**
   * get all contests annd toggle componet loaded state when all data is loaded
   */
  componentDidMount() {
    const user = JSON.parse(Cookie.get("user"));
    this.setState({ "accountType": user.accountType });
    this.props.getContests(user._id)
      .then(() => (
        this.setState({ contests: this.props.contests, isLoaded: true })
      ))
  }



  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Contests</h4>

                    <div className="page-title-right">
                      <button
                        type="submit"
                        onClick={() =>
                          window.location = `/${this.state.accountType !== null && this.state.accountType === USER_TYPE.SPONSOR ? "sponsor" : "admin"}/hackathon/new`
                        }
                        style={{
                          backgroundColor: "#74788d",
                          borderColor: "#74788d",
                          color: "white",
                        }}
                        className="btn waves-effect waves-light"
                      >
                        add contest
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              <div className="row">
                {this.state.contests.length > 0 ?

                  this.state.contests.map((contest, index) => (
                    <div className="col-md-3 col-xl-3" key={index}>
                      {/* <!-- Simple card --> */}
                      <div
                        onClick={() =>
                          this.props.history.push(`/${this.state.accountType !== null && this.state.accountType === USER_TYPE.SPONSOR ? "sponsor" : "admin"}/hackathon/` + contest._id)
                        }
                        className="card"
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        <div style={{
                          height: "150px",
                          width: "100%"
                          , backgroundImage: "url(" + contest.eventBanner.data + ")",
                          backgroundOrigin: "center",
                          backgroundSize: "cover"
                        }}>
                          {/* <img
                            src={contest.eventBanner.data}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: 'cover'
                            }}
                            alt="Card image cap"
                          /> */}
                          {/* <img
                          className="card-img-top img-fluid"
                          src={contest.eventBanner.data}
                          style={{
                            backgroundSize: "contain",
                            height: "150px",
                            width: "auto"
                          }}
                          alt="Card image cap"
                        /> */}
                        </div>
                        {contest.isApproved ?
                          contest.status ?
                            <span
                              style={{ position: "absolute", right: "-20px", padding: "5px 10px", background: "#1bbe1b" }}
                              href="#"
                              className="badge badge-primary font-size-11 m-1"
                            >
                              Approved
                            </span>
                            :
                            <span
                              style={{ position: "absolute", right: "-20px", padding: "5px 10px", background: "gray" }}
                              href="#"
                              className="badge badge-primary font-size-11 m-1"
                            >
                              Suspended
                            </span>

                          :
                          <span
                            style={{ position: "absolute", right: "-20px", padding: "5px 10px", background: "red" }}
                            href="#"
                            className="badge badge-primary font-size-11 m-1"
                          >
                            Not Approved
                          </span>
                        }

                        <div className="card-body" style={{ textAlign: "left", height: "157px", overflow: "hidden" }}>

                          <h4 className="card-title mt-0 text-dark" style={{ fontSize: '15px' }}>{contest.title}</h4>
                          <p className="card-text text-muted" style={{ fontSize: "12px" }}>
                            <b className="text-primary">Starts: </b>{formatDate(new Date(contest.startDate),)}
                            <br />
                            <b className="text-primary">Ends: </b>{formatDate(new Date(contest.endDate),)}
                          </p>
                          <p className="text-muted" style={{ fontWeight: "500", WebkitLineClamp: 3, textOverflow: "ellipsis", display: "-webkit-box", WebkitBoxOrient: "vertical", overflow: "hidden" }}>{ReactHtmlParser(contest.shortDescription.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 40).trim().padEnd(3, "."))}</p>
                          {/* <button className="btn btn-primary ml-auto">VIEW</button> */}
                          <div style={{ textAlign: "left" }}>
                            {contest.tags ? contest.tags.map((tag, index) => (
                              <span key={{ index }}
                                href="#"
                                className="badge badge-primary font-size-11 m-1"
                              >
                                {tag}
                              </span>
                            )) : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                  ))
                  :

                  <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                    <div className="card" style={{ cursor: "pointer", border: "1px gray dashed", backgroundColor: "transparent", minHeight: "120px" }}>
                      <div className="card-body">
                        <p style={{ fontSize: "1.5vw" }}>NO EVENTS</p>
                      </div>
                    </div>
                  </div>

                }
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div >
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contests: state.contestData.contests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContests: (userId) => dispatch(fetchAllContests(userId)),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContestList);
