import React, { Component } from "react";
import BadgeHorizontal from "./BadgeHorizontal";
import { getTechnologyIcon } from "../utils/utils";


class UserTechnologiesList extends Component {


  render() {
    return (
      <div className="card pl-4 pr-3">
        {/* <!-- Technologies --> */}
        <div className="card-body">
          <h4 className="card-title text-uppercase" style={{ color: "#000" }}>
            Technologies
          </h4>
        </div>

        <div className="row mb-4">
          {this.props.userTechnologies.map((item, index) => (
            <BadgeHorizontal
              icon={getTechnologyIcon(item.img ? item.img : "-")}
              data={item.title ? item.title : "-"}
              // key={index}
            />
          ))}
        </div>

        <div
          className="modal fade technologies-modal bs-example-modal-center"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
        ></div>
      </div>
    );
  }
}

export default UserTechnologiesList;
