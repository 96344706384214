import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import { Helmet } from "react-helmet";
import { fireNotification } from "../../store/actions/notificationsActions";
import { connect } from "react-redux";


class LandingPage2 extends Component {

  state = {
    skills: []
  }

  componentDidMount() {
    this.props.callback()
      .then(() => this.handleSkills(this.props.data))
      .catch(err => this.props.dispatchNotification(err))

  }

  handleSkills(users) {

    var data = [];
    users.forEach(user => {
      data.push(...user.programmingLanguages)
      data.push(...user.technologies)
    })

    var compressed = [];
    // make a copy of the input array
    var copy = data.slice(0);

    // first loop goes over every element
    for (var i = 0; i < data.length; i++) {

      var myCount = 0;
      // loop over every element in the copy and see if it's the same
      for (var w = 0; w < copy.length; w++) {
        if (data[i] == copy[w]) {
          // increase amount of times duplicate is found
          myCount++;
          // sets item to undefined
          delete copy[w];
        }
      }

      if (myCount > 0) {
        var a = {};
        a.value = data[i];
        a.count = myCount;
        compressed.push({ 'id': data[i], 'label': data[i], 'value': myCount, });
      }
    }

    this.setState({ skills: compressed })


  }


  render() {

    return (
      <div>
        <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css" />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        {/* <!-- Landing Page JS --> */}
        <script
          src="/assets/landing-page/js/vendor/modernizr-3.5.0.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/vendor/jquery-1.12.4.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/popper.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/one-page-nav-min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/slick.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/paroller.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/wow.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/parallax.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/jquery.waypoints.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/jquery.countdown.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/jquery.counterup.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/jquery.scrollUp.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/jquery.magnific-popup.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/element-in-view.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/isotope.pkgd.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/imagesloaded.pkgd.min.js"
          type="text/javascript"
        ></script>
        <script
          src="/assets/landing-page/js/main.js"
          type="text/javascript"
        ></script>
        {/* <Helmet>
                  <meta name="description" content=""/>
                  <meta name="viewport" content="width=device-width, initial-scale=1"/>
                  <link rel="shortcut icon" type="image/x-icon" href="img/favicon.ico"/>
                  <link href="https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap"
                        rel="stylesheet"/>
                  <link
                      href="https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"
                      rel="stylesheet"/>
                  <link rel="stylesheet" href="/assets/css/bootstrap.min.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/magnific-popup.css"/>
                  <link rel="stylesheet" href="assets/fontawesome/css/all.min.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/slick.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/default.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/style.css"/>
                  <link rel="stylesheet" href="/assets/landing-page/css/responsive.css"/>
  
                  <script src="/assets/landing-page/js/vendor/modernizr-3.5.0.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/vendor/jquery-1.12.4.min.js" type="text/javascript"></script> 
                  <script src="/assets/landing-page/js/popper.min.js"></script>
                   <script src="/assets/landing-page//assets/landing-page/js/bootstrap.min.js"></script> 
                  <script src="/assets/landing-page/js/one-page-nav-min.js"></script>
                  <script src="/assets/landing-page/js/slick.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/ajax-form.js"></script>
                  <script src="/assets/landing-page/js/paroller.js"></script>
                  <script src="/assets/landing-page/js/wow.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/parallax.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.waypoints.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.countdown.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.counterup.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.scrollUp.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/jquery.magnific-popup.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/element-in-view.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/isotope.pkgd.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/imagesloaded.pkgd.min.js" type="text/javascript"></script>
                  <script src="/assets/landing-page/js/main.js" type="text/javascript"></script>
  
              </Helmet>*/}
        <header id="home" className="header-area">
          <div id="header-sticky" className="menu-area">
            <div className="container">
              <div className="second-menu">
                <div className="row align-items-center">
                  <div className="col-xl-3 col-lg-3">
                    <div className="logo">
                      <a href="#">
                        <img
                          src="/assets/landing-page/images/logo/logo.png"
                          className="imglogo"
                          alt="logo"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-9">
                    <div className="responsive">
                      <i className="icon dripicons-align-right" />
                    </div>
                    <div className="main-menu text-right text-xl-center">
                      <nav id="mobile-menu">
                        <ul>
                          <li className="active has-sub">
                            <a href="/">Home </a>
                          </li>
                          <li className="has-sub">
                            <a href="#how-it-works">How it works</a>
                          </li>
                          <li className="has-sub">
                            <a href="#about">About</a>
                          </li>

                          <li>
                            <a href="#footer">Contacts</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-xl-3 text-right d-none d-xl-block">
                    <div className="header-btn second-header-btn">
                      <a href="/login" className="btn">
                        <i className="fa fa-user-alt" /> Login
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main>
          <section
            id="parallax"
            className="parallax slider-area slider-bg second-slider-bg d-flex align-items-center justify-content-center fix"
            style={{ backgroundColor: "#0424D9" }}
          >
            <div className="slider-shape ss-one layer" data-depth="0.10">
              <img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />
            </div>
            <div className="slider-shape ss-two layer" data-depth="0.30">
              <img
                src="/assets/landing-page/images/shape/slider_shape02.png"
                alt="shape"
              />
            </div>
            <div className="slider-shape ss-three layer" data-depth="0.40">
              <img
                src="/assets/landing-page/images/shape/html.png"
                style={{ width: "10%" }}
                alt="shape"
              />
            </div>
            <div className="slider-shape ss-four layer" data-depth="0.60">
              <img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />
            </div>
            <div className="slider-shape ss-five layer" data-depth="0.20">
              <img
                src="/assets/landing-page/images/shape/python.png"
                style={{ width: "10%" }}
                alt="shape"
              />
            </div>
            <div className="slider-shape ss-six layer" data-depth="0.15">
              <img
                src="/assets/landing-page/images/shape/java.png"
                style={{ width: "10%" }}
                alt="shape"
              />
            </div>
            <div className="slider-shape ss-eight layer" data-depth="0.50">
              <img
                src="/assets/landing-page/images/man_header-1.png"
                alt="shape"
              />
            </div>
            <div className="slider-active">
              <div className="single-slider">
                <div className="container">
                  <div className="row">
                    <div className="col-8">
                      <div className="slider-content second-slider-content">
                        <h2 data-animation="fadeInUp animated" data-delay=".4s">
                          Let the world know <span>you</span>
                        </h2>
                        <br />
                        <br />
                        <a className="btn " href={"/register"}>
                          {" "}
                          + Create an account for FREE
                        </a>
                        <br />
                        <br />
                        <ul data-animation="fadeInUp animated" data-delay=".2s">
                          <li>Show off your work and skills to the industry</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="about" className="about-area about-p pt-5 pb-5 p-relative">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <img
                    src="/assets/images/rankbadge.png"
                    style={{
                      position: "absolute",
                      width: "10rem",
                      top: "-8rem",
                      right: "5rem"
                    }}
                  />
                  <img
                    src="/assets/images/isometric-page2.png"
                    style={{ position: "absolute", width: "18rem" }}
                  />
                </div>

                <div className="col-lg-6">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"
                      >
                        {/* <span>Skills</span> */}
                      </div>
                      {/* <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                      >
                        How Skills Ranker Works
                      </span> */}
                      <h2
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s"
                      >
                        Create your developer profile
                      </h2>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    >
                      <p>
                        Using our AI powered ranking algorithm, see how you rank
                        locally as a developer in Botswana. Get recommendations on
                        new skills to learn to improve your ranking
                      </p>
                      <a href="#" className="btn mt-20">
                        + &nbsp; Join Skills Ranker
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="about-area about-p pt-5 pb-5 p-relative">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <img
                    src="/assets/images/512px-Bootstrap_logo.svg.png"
                    style={{
                      position: "absolute",
                      width: "40px",
                      top: "-6rem",
                      right: "25%"
                    }}
                  />
                  <img
                    src="/assets/images/1280px-React-icon.svg.png"
                    style={{
                      position: "absolute",
                      width: "70px",
                      top: "-10rem",
                      right: "45%"
                    }}
                  />
                  <img
                    src="/assets/images/306px-ISO_C++_Logo.svg.png"
                    style={{
                      position: "absolute",
                      width: "40px",
                      top: "-6rem",
                      right: "70%"
                    }}
                  />
                  <img
                    src="/assets/images/155-1550132_css-3-logo-png-css-logo-transparent-png.png"
                    style={{
                      position: "absolute",
                      width: "30px",
                      top: "-4rem",
                      right: "90%"
                    }}
                  />
                  <img
                    src="/assets/images/5847f5bdcef1014c0b5e489c.png"
                    style={{
                      position: "absolute",
                      width: "50px",
                      top: "13rem",
                      right: "50%"
                    }}
                  />
                  <img
                    src="/assets/images/d1ab7e975ec75d5df04d5106c5d1c729.jpg"
                    style={{
                      position: "absolute",
                      width: "30px",
                      top: "13rem",
                      right: "90%"
                    }}
                  />
                  <img
                    src="/assets/images/pandas-logo-300.png"
                    style={{
                      position: "absolute",
                      width: "50px",
                      top: "15rem",
                      right: "70%"
                    }}
                  />
                  <img
                    src="/assets/images/unnamed.png"
                    style={{
                      position: "absolute",
                      width: "40px",
                      top: "-7rem",
                      right: "95%"
                    }}
                  />
                  <img
                    src="/assets/images/assessmentbadgeresult.png"
                    style={{
                      position: "absolute",
                      width: "90%",
                      height: "10rem",
                      left: "-15%"
                    }}
                  />
                </div>

                <div className="col-lg-6">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"
                      >
                        {/* <span>Skills</span> */}
                      </div>
                      {/* <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                      >
                        How Skills Ranker Works
                      </span> */}
                      <h2
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s"
                      >
                        Sharpen your skills
                      </h2>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    >
                      <h5
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s"
                      >
                        See how you rank locally
                      </h5>
                      <p>
                        Stackup your skills against, local developers in Botswana.
                        Get recommendations on new skills to learn to improve your
                        ranking.
                      </p>
                      {/* <a href="#" className="btn mt-20">
                        + &nbsp; Join Skills Ranker
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            id="how-it-works"
            className="pt-128 counter-area pt-120 pb-120"
            style={{ backgroundColor: "#0528F2" }}
          >
            <div className="container">
              <div className="row" style={{ height: "100vh" }}>
                <div className="col-lg-6 col-sm-6">
                  <div className="about-title second-atitle">
                    <span
                      className="wow fadeInUp animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    >
                      Skills Ranker (SR)
                    </span>
                    <h2
                      className="wow fadeInUp animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    >
                      Be seen by potential employers
                    </h2>
                    <h5
                      className="wow fadeInUp animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    />
                  </div>
                  <ul
                    className="wow fadeInDown animated"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    <li>
                      <img
                        src="/assets/landing-page/images/work.png"
                        style={{ width: "60px" }}
                        alt="img"
                      />
                      <span>
                        Skills Ranker Platform helps developers to get identified
                        & hired by companies/ government{" "}
                      </span>
                    </li>
                    <li>
                      <img
                        src="/assets/landing-page/images/code.png"
                        style={{ width: "60px" }}
                        alt="img"
                      />
                      <span>
                        Skills Ranker helps developers to practice their coding
                        skills and to prepare for interviews
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="single-counter text-center mb-30 cr1">
                    <div className="counter p-relative">
                      <span className="count">20</span>
                      <small>+</small>
                    </div>
                    <p>JAVA Developers</p>
                  </div>
                  <div className="single-counter text-center mb-30 cr2">
                    <div className="counter p-relative">
                      <span className="count">100</span>
                      <small>+</small>
                    </div>
                    <p>Registered Developers</p>
                  </div>
                  <div className="single-counter text-center mb-30 cr3">
                    <div className="counter p-relative">
                      <span className="count">5</span>
                      <small>+</small>
                    </div>
                    <p>Data Scientists</p>
                  </div>

                  <div className=" cr4"></div>

                  <div className="single-counter text-center mb-30 cr5">
                    <div className="counter p-relative">
                      <span className="count">50</span>
                      <small>+</small>
                    </div>
                    <p>React Developers</p>
                  </div>
                  <div className="cr6" />
                </div>
              </div>
            </div>
          </div>
        </main>

        <footer
          id="footer"
          className="footer-bg footer-p"
          style={{ backgroundColor: "#0424D9" }}
        >
          <div className="footer-top">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
                  <div className="footer-widget pt-120 mb-30">
                    <div className="logo mb-35">
                      <a href="#">
                        <img
                          src="/assets/landing-page/images/icon.png"
                          alt="logo"
                          style={{
                            width: "7%",
                            opacity: "100%",
                            marginRight: "50px"
                          }}
                        />
                      </a>
                      <a href="#">
                        <img
                          src="/assets/landing-page/images/white_logo.png"
                          alt="logo"
                          style={{ width: "13%", opacity: "100%" }}
                        />
                      </a>
                    </div>

                    <div className="footer-social">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-wrap pb-120">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="copyright-text text-center">
                    <p>© {new Date().getFullYear()} Botswana Innovation Hub.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );

  }

}

const mapStateToProps = (state) => {
  return {
    users: state.admin.adminUsersList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LandingPage2));
