import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import HttpsRedirect from "react-https-redirect";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
// import '../public/assets/css/app.min.css';
const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);
if (process.env.NODE_ENV !== "development")
  console.log = () => { };
if (window.location.host.startsWith("www")) {
  window.location =
    window.location.protocol +
    "//" +
    window.location.host.replace("www.", "") +
    window.location.pathname;
}

export default store

ReactDOM.render(
  // <HttpsRedirect>
  <Provider store={store}>
    <App />
  </Provider>,
  // </HttpsRedirect>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
