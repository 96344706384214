import React, { Component } from "react";
import { fetchAssesment, pushAssessment, deleteAssessment } from "../../store/actions/assesmentsActions";
import { connect } from "react-redux";
import Select from "react-select";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import Data404 from "./Data404";
import { fetchAllTechnologies } from "../../store/actions/technologiesActions"
import { fetchAllLanguages } from "../../store/actions/languagesActions"



class AdminAssesmentNew extends Component {
    state = {
        id: '',
        title: '',
        level: '',
        questions: [
            {
                key: '',
                text: '',
                answers: [
                    {
                        id: '1',
                        text: '',
                        isCorrect: false,
                    },
                    {
                        id: '2',
                        text: '',
                        isCorrect: false,
                    },
                    {
                        id: '3',
                        text: '',
                        isCorrect: false,
                    },
                    {
                        id: '4',
                        text: '',
                        isCorrect: false,
                    },
                ]
            }
        ],
        duration: 0,
        description: '',
        isEditing: false,
        isLoaded: false,
        caughtErr: false,
        img: "",
        fileName: "Choose file",
        changeIcon: false,
        skills: []
    };



    componentDidMount() {

        this.getSkills()
        //   check if creating a new assessment or updating an old one
        const { history } = this.props;
        let aid = history.location.pathname.split("/")[3];

        if (aid === 'new') {
            this.setState({ isEditing: false, isLoaded: true, caughtErr: false })
        } else {

            //change component state to editing
            this.setState({ isEditing: true })

            //get target assessment to edit
            this.props.getAssessment(aid)

                //update state with assessment data
                .then(() => this.setState({
                    title: this.props.assessment.title,
                    level: { value: this.props.assessment.level, label: this.props.assessment.level },
                    questions: this.props.assessment.questions,
                    duration: Number(this.props.assessment.duration),
                    description: this.props.assessment.description,
                    focus: { value: this.props.assessment.focus, label: this.props.assessment.focus },
                    id: this.props.assessment._id,
                    isLoaded: true,
                }))

        }
    }

    //Assessment difficulty levels
    difficultyLevels = [
        {
            value: 'Beginner',
            label: 'Beginner'
        },
        {
            value: 'Intermediate',
            label: 'Intermediate'
        },
        {
            value: 'Advanced',
            label: 'Advanced'
        },

    ]

    getSkills() {
        this.props.getLangs()
            .then(() => { return this.props.languages.map(lang => ({ id: lang._id, value: lang.title, label: lang.title })) })
            .then((langs) => this.setState({ skills: this.state.skills.concat(langs) }))

        this.props.getTech()
            .then(() => { return this.props.technologies.map(tech => ({ id: tech._id, value: tech.title, label: tech.title })) })
            .then((tech) => this.setState({ skills: this.state.skills.concat(tech) }))
    }


    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(",")[1]);

        // separate out the mime component
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    }


    handleImage(e) {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                img: reader.result,
                fileName: file.name,
            });
        };
        if (file) {
            reader.readAsDataURL(file);
            this.setState({
                img: reader.result,
                fileName: file.name,
            });
        } else {
            this.setState({
                img: "",
            });
        }

    }

    /**
     * update state with edited info from input fields 
     * 
     * @param  {} doc dictionary with key => state variable to edit, and value => new value 
     */
    handleInputChange(doc) {
        this.setState(doc)
    }


    /**
     * remove question from questions list using question id 
     * 
     * @param {} q_id id of question to remove
     */
    removeQuestion = q_id => () => {
        let questions = this.state.questions
        let newQuestions = questions.filter((q, idx) => idx !== q_id)

        this.setState({
            questions: [...newQuestions]
        });
    }


    /**
     * Add new question field to form
     */
    addQuestion() {
        this.setState({
            questions: this.state.questions.concat(
                [{
                    key: '',
                    text: '',
                    answers: [
                        {
                            id: '1',
                            text: '',
                            isCorrect: false,
                        },
                        {
                            id: '2',
                            text: '',
                            isCorrect: false,
                        },
                        {
                            id: '3',
                            text: '',
                            isCorrect: false,
                        },
                        {
                            id: '4',
                            text: '',
                            isCorrect: false,
                        },
                    ]
                }]
            )
        })
    }


    /**
     * update state with new answer value
     * 
     * @param  {} q_id target question id
     * @param  {} aidx target answer index
     * @param event     fired event from input field containing new text values
     */
    handleAnswerTextChange = (q_id, aidx) => event => {

        const newQuestions = this.state.questions.map((question, qidx) => {
            //search for target question
            if (q_id !== qidx) {
                return question;
            } else {
                //search for target answer
                let newAnswers = question.answers.map((ans, idx) => {
                    if (aidx !== idx) return ans;
                    return { ...ans, text: event.target.value }
                })
                question.answers = newAnswers;
            }

            return { ...question };
        });
        this.setState({ questions: newQuestions });
    }


    /**
     * update answer boolean state
     * 
     * @param  {} q_id target question
     * @param  {} aidx answer index
     * @param event   fired event from input field containing true or false strings
     */
    handleAnswerIsCorrectChange = (q_id, aidx) => event => {
        const newQuestions = this.state.questions.map((question, qidx) => {
            //search for target question
            if (q_id !== qidx) {
                return question;
            } else {
                //search for target answer
                let newAnswers = question.answers.map((ans, idx) => {
                    if (aidx !== idx) return ans;
                    let status = event.target.value === 'false' ? true : false

                    return { ...ans, isCorrect: status }
                })
                question.answers = newAnswers;
            }

            return { ...question };
        });
        this.setState({ questions: newQuestions });
    }



    handleQuestionTextChange = idx => event => {
        const newQuestions = this.state.questions.map((question, qidx) => {
            if (idx !== qidx) return question;
            return { ...question, text: event.target.value };
        });

        this.setState({ questions: newQuestions });
    }

    /**
     * pull assessment data from component state and submit data to backend
     * prevent page from refreshing
     *  
     * @param  {} event submit event
     */
    handleSubmit(event) {
        {
            event.preventDefault();
            const formData = new FormData();


            const body = this.state
            body.focus = body.focus.value;
            body.level = body.level.value;

            if (this.state.isEditing) {

                if (this.state.changeIcon && this.state.img.includes('data:image')) {

                    //update all fields

                    formData.append("img", this.dataURItoBlob(this.state.img));

                    this.props.updateAssessment(this.state.id, { ...body, ...formData })

                } else {
                    this.props.updateAssessment(this.state.id, body)
                }
            }

            else {
                //creating new assessment
                if (this.state.img != null || this.state.img != "") {

                    formData.append("img", this.dataURItoBlob(this.state.img));
                    this.props.updateAssessment(null, { ...formData, ...body })
                }

            }

        }
    }


    /**
     * remove assessment and redirect to assessments list page
     */
    removeAssessment() {
        this.props.removeAssessment(this.state.id)
            .then(() => (window.location = "/admin/assessments"))
    }

    render() {
        if (this.state.isLoaded) {
            if (this.state.caughtErr) {
                return (<Data404 />);
            }
            else {

                return (
                    <div className="main-content">

                        <div className="page-content">
                            <div className="container-fluid">

                                {/* <!-- start page title --> */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0 font-size-18">Assesments</h4>

                                            <div className="page-title-right">
                                                <ol className="breadcrumb m-0">
                                                    <li className="breadcrumb-item">Creating</li>
                                                    <li className="breadcrumb-item active"> modifying assessments </li>
                                                </ol>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end page title --> */}

                                <div className="row">
                                    <div className="col-xl-12 col-sm-12">
                                        <div className="card" >
                                            <div className="card-body">

                                                <form onSubmit={e => this.handleSubmit(e)} className=" needs-validation" encType="multipart/form-data">
                                                    {/* <div className="form-group">
                                                    <label >icon</label>
                                                    <input type="file" className="form-control-file" id="icon"/>
                                                </div> */}



                                                    {this.state.isEditing ?
                                                        this.state.changeIcon ?
                                                            <>
                                                                <div className="ml-3 mt-2 mb-2">
                                                                    <label style={{ cursor: 'pointer' }} onClick={() => this.setState({ changeIcon: false })}>{'>> I dont want to change the icon <<'}</label>
                                                                </div>
                                                                <div className="custom-file mb-4 mt-2">
                                                                    <input
                                                                        onChange={(e) => this.handleImage(e)}
                                                                        required
                                                                        type="file"
                                                                        className="custom-file-input"
                                                                        id="customFile"
                                                                        // value = {this.state.fileName}
                                                                        style={{ position: 'relative' }}
                                                                    />
                                                                    <label
                                                                        className="custom-file-label"
                                                                        htmlFor="customFile"
                                                                    >
                                                                        {this.state.fileName}
                                                                    </label>
                                                                </div>
                                                            </>
                                                            :
                                                            <div className="ml-3 mt-2 mb-2">
                                                                <label style={{ cursor: 'pointer' }} onClick={() => this.setState({ changeIcon: true })}>{'>> Click to change icon <<'} </label>
                                                            </div>
                                                        :
                                                        <div className="custom-file mb-4 mt-2">

                                                            <input
                                                                onChange={(e) => this.handleImage(e)}
                                                                required
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                // value = {this.state.fileName}
                                                                style={{ position: 'relative' }}
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="customFile"
                                                            >
                                                                {this.state.fileName}
                                                            </label>
                                                        </div>
                                                    }



                                                    <div className="form-group mb-0">
                                                        <label className="control-label">Title</label>
                                                        <br />
                                                        <input onChange={(e) => this.handleInputChange({ title: e.target.value })} value={this.state.title} className="form-control" required name="title" type="text" placeholder="Title" />
                                                        <br />
                                                        <div className="valid-feedback">
                                                            Looks good!
                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xl-3 form-group mb-0">
                                                            <label className="control-label">Skill</label>
                                                            <br />
                                                            <Select
                                                                options={this.state.skills}
                                                                required
                                                                onChange={(e) => this.handleInputChange({ focus: e })}
                                                                value={this.state.focus}
                                                                className="basic-select"
                                                                classNamePrefix="select"
                                                                style={{ width: "-webkit-fill-available" }}
                                                            />
                                                            <div className="valid-feedback">
                                                                Looks good!
                                                        </div>
                                                            <br />
                                                        </div>
                                                        <div className="col-xl-3 form-group mb-0">
                                                            <label className="control-label">Difficulty level</label>
                                                            <br />
                                                            <Select
                                                                options={this.difficultyLevels}

                                                                onChange={(e) => this.handleInputChange({ level: e })}
                                                                value={this.state.level}
                                                                required
                                                                className="basic-select"
                                                                classNamePrefix="select"
                                                                style={{ width: "-webkit-fill-available" }}
                                                            />
                                                            <div className="valid-feedback">
                                                                Looks good!
                                                        </div>
                                                            <br />
                                                        </div>
                                                        <div className=" col-xl-3 form-group mb-0">
                                                            <label className="control-label">Duration</label>
                                                            <br />
                                                            <input onChange={(e) => this.handleInputChange({ duration: e.target.value })} className="form-control" value={this.state.duration} required name="duration" type="number" placeholder="Duration in minutes" />
                                                            <br />
                                                        </div>
                                                    </div>

                                                    {/* <div className="form-group mb-0">
                                                    <label className="control-label">Tags</label>
                                                    <br />
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        rows="8"
                                                        maxLength="3000"
                                                        value={this.state.tags}
                                                        onChange={(e) => this.handleTagsOnChange(e)}
                                                        placeholder="Add all tags associated with the assessment separated by a semicolon.
                                                            eg Machine learning;   DevOps;   Mobile App Dev;"
                                                        ></textarea>
                                                    <br />
                                                </div> */}
                                                    <div className="form-group mb-0">
                                                        <label className="control-label">Description</label>
                                                        <br />
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            rows="8"
                                                            maxLength="3000"
                                                            required
                                                            value={this.state.description}
                                                            onChange={(e) => this.handleInputChange({ description: e.target.value })}
                                                            placeholder="Assessment Details..."
                                                        ></textarea>
                                                        <div className="valid-feedback">
                                                            Looks good!
                                                    </div>
                                                        <br />
                                                    </div>
                                                    <label className="control-label">Questions</label>

                                                    {this.state.questions.map((question, q_idx) =>
                                                        <div key={q_idx} className="row mb-4 questions">
                                                            <div className=" col-xl-10 col-10 form-group mb-0">
                                                                {/* <label className="control-label">Question</label> */}
                                                                <br />
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    rows="5"
                                                                    maxLength="2000"
                                                                    required
                                                                    value={question.text}
                                                                    onChange={this.handleQuestionTextChange(q_idx)}
                                                                    placeholder="Add a question..."
                                                                ></textarea>
                                                                <div className="valid-feedback">
                                                                    Looks good!
                                                            </div>
                                                                <br />
                                                            </div>
                                                            <div className="col-xl-2 col-2 mt-4">
                                                                <input onClick={this.removeQuestion(q_idx)} type="button" className="btn btn-danger inner" value="Delete" />

                                                            </div>
                                                            <div className="col-xl-12">
                                                                <label className="control-label">Answers</label>
                                                            </div>
                                                            {question.answers.map((answer, aidx) =>

                                                                <div key={aidx} className="col-xl-12">
                                                                    <div className="row">
                                                                        <div className=" col-xl-10 col-10 pl-5 pr-5 form-group mb-0">

                                                                            <br />
                                                                            <textarea
                                                                                className="form-control"
                                                                                type="text"
                                                                                rows="1"
                                                                                maxLength="1000"
                                                                                required
                                                                                value={answer.text}
                                                                                onChange={this.handleAnswerTextChange(q_idx, aidx)}
                                                                                placeholder="Possible answer..."
                                                                            ></textarea>
                                                                            <div className="valid-feedback">
                                                                                Looks good!
                                                                        </div>
                                                                            <br />

                                                                        </div>
                                                                        <div className="mt-3" style={{ float: 'right' }}>
                                                                            {
                                                                                answer.isCorrect ?
                                                                                    <input type="checkbox" id={"switch" + q_idx + "-" + aidx} switch="bool" value={answer.isCorrect} defaultChecked onChange={this.handleAnswerIsCorrectChange(q_idx, aidx)} />

                                                                                    :
                                                                                    <input type="checkbox" id={"switch" + q_idx + "-" + aidx} switch="bool" value={answer.isCorrect} onChange={this.handleAnswerIsCorrectChange(q_idx, aidx)} />

                                                                            }

                                                                            <label htmlFor={"switch" + q_idx + "-" + aidx} data-on-label="Yes" data-off-label="No"></label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )}


                                                        </div>
                                                    )}
                                                    <div className="page-title-right">
                                                        <button type="submit" style={{ backgroundColor: '#74788d', borderColor: '#74788d', color: 'white' }} className="btn waves-effect waves-light">
                                                            <i className="bx bx-save font-size-16 align-middle mr-2" ></i> Save
                                                    </button>
                                                        {this.state.isEditing ?
                                                            <button type="button" onClick={() => this.removeAssessment()} style={{ backgroundColor: '#74788d', borderColor: '#74788d', color: 'white' }} className="ml-3 btn waves-effect waves-light">
                                                                <i className="bx bx-x font-size-16 align-middle mr-2" ></i> Delete
                                                        </button>
                                                            :
                                                            ''
                                                        }
                                                        <button onClick={() => this.addQuestion()} type="button" style={{ backgroundColor: '#74788d', borderColor: '#74788d', color: 'white', }} className="btn waves-effect waves- ml-3">
                                                            Add Question
                                                    </button>
                                                    </div>
                                                </form>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end row --> */}


                            </div> {/**<!-- container-fluid -->**/}
                        </div>
                        {/* <!-- End Page-content --> */}


                        <footer className="footer">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-6">
                                        {new Date().getFullYear()} © Botswana Innovation Hub
                            </div>
                                    <div className="col-sm-6">
                                        <div className="text-sm-right d-none d-sm-block">
                                            Skills Ranker
                                </div>
                                    </div>
                                </div>
                            </div>
                        </footer>


                    </div>
                );

            }


        } else {
            return (

                <AssesmentsSkeleton />

            );

        }

    }
}

const mapStateToProps = (state) => {
    return {
        assessment: state.assesmentsData.targetAssessment,
        languages: state.languagesData.allLanguages,
        technologies: state.technologiesData.allTechnologiesList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAssessment: (aid) => dispatch(fetchAssesment(aid)),
        updateAssessment: (aid, data) => dispatch(pushAssessment(aid, data)),
        removeAssessment: (aid) => dispatch(deleteAssessment(aid)),
        getLangs: () => dispatch(fetchAllLanguages()),
        getTech: () => dispatch(fetchAllTechnologies()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAssesmentNew);
