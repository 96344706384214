import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";

import { getBusinessProfile, fetchBusinessProfileList, pushBusinessProfileUpdate, pushContactPersonUpdate, pushProfileToStore, pushNewProfile, pushInvite } from "../../store/actions/businessProfileActions";
import globe from "../../../src/globe.svg";
// import dateFormat from "dateformat";
// import moment from "moment";
// import store from "../../index";
import CreateBusinesProfileForm from "../../components/CreateBusinesProfileForm";
import BusinessProfilesList from "../../components/BusinessProfilesList";
import BusinessProfileDetails from "../../components/BusinessProfileDetails";
import { businessComplianceCheck, fetchComplianceElements, fetchBusinessComplianceElements, pushComplianceDoc } from "../../store/actions/complianceActions";

class BusinessProfilePage extends Component {
  data = [];
  state = {
    user: null,
    isProfileSelected: false,
    isLoadingProfiles: true,
    hasBusinessProfiles: false,
    isCreatingProfile: false,
    businessProfile: "",
    profileList: [],
    businessComplianceList: []
  };





  componentDidMount() {

    let user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

    this.setState({ user: user })
    // if(true)
    //   this.props.getComplianceList()

    this.props.getBusinessProfileList(user._id)
      .then(() => {
        this.setState({
          isLoadingProfiles: false,
          isCreatingProfile: this.props.profileList.length == 0,
          profileList: this.props.profileList,
          hasBusinessProfiles: this.props.profileList.length > 0
        });
      })
      .catch((err) => this.props.dispatchNotification(err));

      

  }

  //   componentDidUpdate()
  //   console.log("props", this.props.bus);
  // }
  selectProfile = (profile) => {
    this.props.setBusinessProfile(profile)
      .then(() => {

        this.setState({ businessProfile: profile, isProfileSelected: true })

        
      })
    // this.props.getBusinessCompliance({profileId:profile._id})
    this.props.getComplianceList(profile._id)
      .then(() => {
        this.setState({ businessComplianceList: this.props.businessComplianceList })
      })
      .catch(() => {
        console.log("failed to get compliance info" + profile)
      })
  }

  profileStateCallback = (state) => {
    this.setState(state)

  }

  editProfile = (profile) => {
    return this.props.updateProfile(profile)
      .then((profile) => {
        this.setState({ businessProfile: profile })
        return Promise.resolve(profile)

      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  initProfile = () => {
    this.setState({ isCreatingProfile: true, hasBusinessProfiles: false })
  }
  editContactPerson = (body) => {
    return this.props.updateContactPerson(body)
      .then(() => {
        return Promise.resolve("complete")
        // this.setState({profile:this.props.businessProfile})
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }

  handleFileUpload = (body) => {
    return this.props.pushFile(body)
      .then(() => {
        return Promise.resolve("success")
      })
  }

  createProfile = (body) => {
    return this.props.newProfile(body)
      .then(() => {
        this.selectProfile(this.props.businessProfile)
        this.setState({ hasBusinessProfiles: true, isCreatingProfile: false, isProfileSelected: true })
        return Promise.resolve("successful")
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  inviteMember = (email, profile) => {
    return this.props.initInvite({ email: email, profileId: profile, userId: this.state.user._id })
      .then(() => {
        return Promise.resolve("success")
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  }


  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />

        <div style={{ position: "fixed", width: "100%", height: "100%" }}>
          <div
            style={{
              backgroundImage: "url(" + globe + ")",
              transform: "translate(-50%, -50%) scale(1)",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              transition: "transform .15s ease-out 0s",
              transform: "translate(-50%,-50%)",
              transformOrigin: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: " cover",
              willChange: "transform",
            }}
          ></div>
        </div>

        <main>
          <section
            style={{ alignItems: "center", minHeight: "100vh" }}
            id="how-it-works"
            className=" p-relative"
          >
            <div className="container-fluid" style={{ marginTop: "10vh" }}>
              <div className="row">
                <div className="card-body">
                  {this.state.isLoadingProfiles && this.state.hasBusinessProfiles ?
                    <>
                      loading profiles
                    </>
                    :
                    !this.state.hasBusinessProfiles && !this.state.isCreatingProfile ?
                      <div className="row">
                        <div className="col-12">
                          <div className="card-body text-center" style={{ width: "100vh", height: "50vh", margin: "0 auto", border: "1px dashed gray" }}>
                            <div className="" style={{ backgroundImage: "url('/assets/images/corporate-profile1.png')", width: "100%", height: "100%", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}>
                              <p style={{ fontSize: "34px" }}>Lets set up your Business Profile....</p>
                              <div className="btn btn-primary" style={{ position: "absolute", bottom: "25px", left: "45%", backgroundColor: "#e2b537", color: "black", borderColor: "#e2b537", fontWeight: "bold" }} onClick={() => this.setState({ isCreatingProfile: true })}>
                                Create New profile
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      !this.state.hasBusinessProfiles && this.state.isCreatingProfile ?
                        <>
                          <CreateBusinesProfileForm createProfile={this.createProfile} />
                        </>
                        :
                        this.state.isProfileSelected && this.props.businessProfile !== "" ?
                          <>
                            <BusinessProfileDetails complianceInfo={this.state.businessComplianceList} uploadProgress={this.props.uploadProgress} pushFile={this.handleFileUpload} complianceList={this.props.complianceList} initInvite={this.inviteMember} triggerProfileCreation={this.initProfile} createProfile={this.createProfile} selectedProfile={this.props.businessProfile} events={this.props.businessProfile.events ? this.props.businessProfile.events : []} editProfile={this.editProfile} editContactPerson={this.editContactPerson} profileStateCallback={this.profileStateCallback} />
                          </>
                          :
                          <>
                            <BusinessProfilesList triggerProfileCreation={this.initProfile} createProfile={this.createProfile} selectProfile={this.selectProfile} profileList={this.props.profileList} profileStateCallback={this.profileStateCallback} />
                          </>


                  }

                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessProfile: state.businessProfileData.businessProfile,
    profileList: state.businessProfileData.businessProfileList,
    complianceList: state.complianceData.complianceList,
    uploadProgress: state.contestData.uploadProgress,
    businessComplianceList: state.complianceData.businessComplianceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getBusinessProfile: (user) => dispatch(getBusinessProfile(user)),
    getBusinessProfileList: (user) => dispatch(fetchBusinessProfileList(user)),
    updateProfile: (profile) => dispatch(pushBusinessProfileUpdate(profile)),
    updateContactPerson: (contact) => dispatch(pushContactPersonUpdate(contact)),
    setBusinessProfile: (profile) => dispatch(pushProfileToStore(profile)),
    newProfile: (profile) => dispatch(pushNewProfile(profile)),
    initInvite: (body) => dispatch(pushInvite(body)),
    getComplianceList: (pid) => dispatch(fetchBusinessComplianceElements(pid)),
    pushFile: (payload) => dispatch(pushComplianceDoc(payload)),
    getBusinessCompliance: (body) => dispatch(businessComplianceCheck(body)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BusinessProfilePage));
