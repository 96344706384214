import {
    client
} from "./client";

// import Cookies from "js-cookie";

// let axios2 = axios.create({
//     baseURL: BASE_URL,
//     useCredentials: true,
//     headers: {
//         api_key: "API-R34HEYC3RYGWF3HBDUYSEF",
//         Authorization: `Bearer ${access_token}`
//     }
// });

export function getUser(uid) {
    return client.get("/user?userId=" + uid);
}

export function getUser2(access_token) {
    // delete client.headers.authorization;
    return client.get("/me", {
        api_key: "API-R34HEYC3RYGWF3HBDUYSEF",
        Authorization: `Bearer ${access_token}`
    });
}
export function createAdmin(body) {
    return client.post("/admin_add_user", body);
}

