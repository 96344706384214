import React, { Component } from "react";
import { withRouter } from "react-router"
import { getData } from "../api/googleAnalytics";
import {
    ComposableMap,
    Geographies,
    Geography,
    ZoomableGroup,
    
  } from "react-simple-maps";
  import { PatternLines } from "@vx/pattern";

  
  const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";


class DemographicsBlade extends Component {

    state = {
        data:[]
    
    }
    
    /**
     * construct ga query and call analytics end point 
     * to get pageviews by country over the last 30days
     */
    componentDidMount(){
        //get data from ga
        let requestData = {
            startDate: '30daysAgo',
            endDate: 'today',
            metrics: 'ga:pageviews',
            dimensions:'ga:country'
          }
        // query google analytics data
        getData(requestData)
        .then((res) => this.handleData(res.data))
        .catch((err) => console.log(err))
    }


    /**
     * Format response data to make it compatible with expected graph input data,
     * then update component state.
     * 
     * @param  {} response GA response data
     */
    handleData(response){
        var newData = []
        response.rows.map(dataPoint => newData.push({x:dataPoint[0], y:dataPoint[1]}) )
        var data = [{
          'id':'hits',
          'color': 'blue',
          'data':newData,
        }]
        this.setState({data:data})
    }

    generateCircle(deg) {
        if (!deg) return [[-180, 0], [-90, 0], [0, 0], [90, 0], [180, 0]];
        return new Array(361).fill(1).map((d, i) => {
          return [-180 + i, deg];
        });
      }

   render(){

    const highlighted = [
        "BWA",
      ];

        return (
            
            <div className="card p-0">
                {/* <div className="p-2"> */}
                {/* <h4 className="card-title mb-4 float-sm-left">Locations map</h4> */}
                    {/* <div className="float-sm-right">
                        <ul className="nav nav-pills">
                            <li className="nav-item pl-2" onClick={() => this.setState({targetData:this.state.male, title:'Top 5 Male Users'})}>
                                <a onClick={() => this.props.history.push('/admin/app-users')} style={{color:'white'}} className="btn btn-primary waves-effect waves-light btn-sm">PDF / IMAGE</a>
                            </li>
                        </ul>
                    </div> */}
                {/* </div> */}
                <div className="card-body p-0">
                   
                    <div className="clearfix"></div>
                    <iframe width="100%" height="500" src="https://datastudio.google.com/embed/reporting/045ac445-bdd0-4cbf-a1cf-7222f4ae1455/page/Y83xB" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                    
                </div>
            </div>
                
        
        );
    
    }
}

export default withRouter(DemographicsBlade);
