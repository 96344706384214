import {
    reportContent as _report
} from "../../api/report";

export const reportContent = (body) => {
    return async dispatch => {
        const response = await _report(body);
        const report = response.data;
        dispatch({
            type: "REPORT_CONTENT",
            report
        });
    };
};