import React from "react";
import { Overlay } from "react-bootstrap";
export default function Footer() {
  return (
    <div>
      {/* "rgb(49,49,50)"  */}
      <footer
        id="footer"
        className="page-footer font-small mdb-color pt-4"
      >
        <div className="container text-center text-md-left">
          <div className="row text-center text-md-left mt-5 pb-3 mx-auto">
            <div className="col-xl-12 col-lg-12 col-sm-12 text-center">
              <div className="footer-widget pt-120 mb-30"></div>
            </div>

            <div className="col-md-3">
              <div className="logo mb-35 mt-2">
                <a href="/#">
                  <img
                    src="/assets/landing-page/images/logo_white.png"
                    alt="logo"
                    style={{
                      width: "45%",
                      opacity: "100%",
                      marginRight: "50px",
                    }}
                  />
                </a>
                <br /> <p style={{ color: "white" }}>POWERED BY:</p>
                <a href="http://www.bih.co.bw"
                target="_blank"
                rel="noreferrer">
                  <img
                    src="/assets/landing-page/images/bih_logo_white.png"
                    alt="logo"
                    style={{ width: "48%", opacity: "100%" }}
                  />
                </a>
              </div>
            </div>

            <div className="col-md-4 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold" style={{color:"#FFF"}}>
                Quick links
              </h6>
             
              
              <p>
                <a href="/events">Hackathon Events</a>
              </p>
              <p>
                <a href="/aboutAWS" rel="noreferrer">AWS re/Start Programme</a>
              </p>
            </div>

            <div className="col-md-4 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold" style={{color:"#FFF"}}>
                Useful links
              </h6>
              <p>
                <a href="mailto:skillsranker@bih.co.bw">Give Feedback</a>
              </p>
              <p>
                <a href="/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </p>
              <p>
                <a href="/faqs">FAQs</a>
              </p>
            </div>

            {/*Grid column*/}
            <div className="col-md-4 col-lg-3 col-xl-3 mt-3 d-inline-block">
              <h6 className="text-uppercase mb-4 font-weight-bold d-inline-block" style={{color:"#FFF"}}>
                Contact
              </h6>
              <br />
              <p className="contacts d-inline-block">
                <i className="fa fa-envelope" style={{ color: "white" }}></i>{" "}
                <a href="mailto:skillsranker@bih.co.bw">
                  {" "}
                  skillsranker@bih.co.bw
                </a>
              </p>
              <br />
              <p className="contacts d-inline-block">
                <i className="fa fa-phone" style={{ color: "white" }}></i>
                <a href="tel:+267 391 3328"> Tel +267 391 3328</a>
              </p>
              <br />
              <p className="contacts d-inline-block">
                <i className="fa fa-fax" style={{ color: "white" }}></i>
                <a href="tel:+267 391 3289"> Fax +267 391 3289</a>
              </p>
            </div>
            {/*Grid column*/}
          </div>
          {/*Footer links -->*/}

          <hr style={{width:"100%"}}/>

                  
          {/*// <!-- Grid row -->*/}
          <div className="row d-flex align-items-center pb-4">
            {/*// <!-- Grid column -->*/}
            <div className="col-md-7 col-lg-8">
              {/*// <!--Copyright-->*/}
              <p style={{color:"white"}} className="text-center text-md-left mb-0">
                <a href="http://bih.co.bw/"
                rel="noreferrer"
                target="_blank">
                  &copy; {new Date().getFullYear()} Copyright{" "}
                </a>
                <strong> | </strong>
                <a href="http://bih.co.bw/"
                rel="noreferrer"
                target="_blank">
                
                  <strong> Botswana Digital & Innovation Hub</strong>
                </a>
              </p>
            </div>
            {/*// <!-- Grid column -->*/}

            {/*// <!-- Grid column -->*/}
            <div className="col-md-5 col-lg-4 ml-lg-0">
              {/*// <!-- Social buttons -->*/}
              <div className="text-center text-md-right">

                <ul className="list-unstyled list-inline">

                  <li className="list-inline-item">
                    <a
                      href="//fb.com/yourbdih"
                      rel="noreferrer"
                      target="_blank"
                      className="btn-floating btn-sm rgba-white-slight mx-1"
                    >
                      <i className="fab fa-2x fa-facebook-f"></i>
                    </a>
                  </li>                  
                  <li className="list-inline-item">
                    <a
                      href="//twitter.com/YourBDIH"
                      rel="noreferrer"
                      target="_blank"
                      className="btn-floating btn-sm rgba-white-slight mx-1"
                    >
                      <i className="fab fa-2x fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/yourbdih/mycompany/"
                      rel="noreferrer"
                      target="_blank"
                      className="btn-floating btn-sm rgba-white-slight mx-1"
                    >
                      <i className="fab fa-2x fa-linkedin "></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.youtube.com/@botswanadigitalinnovationh5329"
                      rel="noreferrer"
                      target="_blank"
                      className="btn-floating btn-sm rgba-white-slight mx-1"
                    >
                      <i className="fab fa-2x fa-youtube "></i>
                    </a>
                  </li>

                </ul>

              </div>
            </div>
            {/*// <!-- Grid column -->*/}
          </div>
          {/*// <!-- Grid row -->*/}
        </div>
      </footer>
      {/*// <!-- Footer -->*/}
    </div>
  );
}
