import {
    client
} from "./client";

export function getLanguages(languageIDs) {
    return client.get("/languages?languages="+ languageIDs)
}

export function updateUserLanguages(body) {
    return client.put("/languages", body,
        //  {
        //     headers: {
        //         'content-type': 'multipart/form-data'
        //     }
        // }
    );
}

export function getAllLanguages() {
    return client.get("/languages");
}

export function createLang(body) {
    return client.post("/languages", body); //error 400
}

export function deleteLang(id) {
    return client.delete("/languages/" + id); // error 404
}

export function updateLang(body) {
    return client.put("/languages/" + body.id, body.formData, {
        // headers: {
        //     'content-type': 'multipart/form-data'
        // }
    }); // error 404
}