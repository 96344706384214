import React, { useState } from "react";

function FAQs(props) {

  return (
    <>
      <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i" rel="stylesheet" />
      <link rel="stylesheet" href="/assets/landing-page/css/style.css" />

      <div className="jumbotron" style={{ overflow: "none", width: "100%", backgroundImage: "url('./assets/images/header-overla.png')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        <h1 className="display-4" style={{ color: "white", paddingTop: "30px", paddingLeft: "30px", paddinRight: "30px" }}>FREQUENTLY ASKED QUESTIONS</h1>
        <p className="lead" style={{ fontSize: "medium", color: "white", paddingTop: "30px", paddingLeft: "30px", paddingRight: "30px" }}>Welcome to the Skills Ranker Frequently Asked Questions.</p>
        <hr className="my-4" />

        <div className="rowed" >
          <a className="btn btn-outline-dark" href="/" role="button" style={{ textTransform: "none" }} >Go Back</a>
        </div>
      </div>

      <div className="cover-legal">

        <div className="accordion" id="accordionExample">
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingOne">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  What is Skills Ranker?
                </button>
              </h2>
            </div>

            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body">
                This is a platform aimed to register all local developers based on their skills. Its will be a
                skills database of Batswana developer. The platform validates your skills by allowing you to
                take assessments on the technologies that you have registered for and any technology
                available on the platform.
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingTwo">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Why register for Skills Ranker?
                </button>
              </h2>
            </div>
            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div className="card-body">
                <p>1. To show case your validated skills to your fellow developers and the potential stakeholders
                  (both private and public) who might be interested in using your skills.
                </p>

                <p>2. To be able to participate on developer activities (workshops, trainings, hackathons etc) that
                  will be facilitated through the platform.</p>
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingThree">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Who can register on Skills Ranker?
                </button>
              </h2>
            </div>
            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div className="card-body">
                Botswana citizen developers/programmers and software engineers only.
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingFour">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Do I have to be a BIH member to register on the platform?
                </button>
              </h2>
            </div>

            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
              <div className="card-body">
                No, you don’t need to be affiliated to BIH, anyone can register on the platform
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingFive">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Does registration on the skills ranker qualify me to be a BIH member?
                </button>
              </h2>
            </div>

            <div id="collapseFive" className="collapse " aria-labelledby="headingFive" data-parent="#accordionExample">
              <div className="card-body">
                No, it is not a BIH membership application, if you want to be a BIH member apply through
                the BIH website, <a href="www.birh.co.bw">www.bih.co.bw</a>
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingSix">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  Why do I need to be ranked?
                </button>
              </h2>
            </div>

            <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
              <div className="card-body">
                <p style={{ fontSize: "medium" }}>1. To get exposure to development freelancing opportunities</p>
                <p style={{ fontSize: "medium" }}>2. To validate and see how you rank against fellow developers</p>
                <p style={{ fontSize: "medium" }}>3. To identify and address the skills gap in the market</p>
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingSeven">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  How long does it take to get ranked?
                </button>
              </h2>
            </div>

            <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
              <div className="card-body">
                Immediately after creating a profile the platform will rank you and rankings improve with the number of projects from Github and assessments taken.
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingEight">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  Now that I have been ranked what is next?
                </button>
              </h2>
            </div>

            <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
              <div className="card-body">
                <p>1. Keep taking more assessment to maintain your position on the platform as you wait opportunities that will be posted on the platform</p>
                <p>2. Participate in developer activities</p>
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingNine">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                  How will I be ranked?
                </button>
              </h2>
            </div>

            <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
              <div className="card-body">
                The ranking is based on the individual’s projects from Github and assessments taken from the Skills Ranker Platform
              </div>
            </div>
          </div>
          <div className="mb-1 card">
            <div className="card-header p-0" id="headingTen">
              <h2 className="mb-0">
                <button style={{ borderRadius: "unset" }} className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                  Who can view my profile?
                </button>
              </h2>
            </div>

            <div id="collapseTen" className="collapse show" aria-labelledby="headingTen" data-parent="#accordionExample">
              <div className="card-body">
                Fellow developers registered on the platform and the stakeholders accredited by BIH
              </div>
            </div>
          </div>

        </div>

        <p style={{ display: "flex", justifyContent: "center", bottom: 0 }}>© {new Date().getFullYear()} Botswana Innovation Hub.</p>
      </div>


    </>
  );
}

export default FAQs
