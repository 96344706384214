const initState = {
  post: {},
  posts:[],
  skills:[],
  applications:[],
  jobApplication:{},
  postsMeta:0
};

const jobPostReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_SKILLS":
      return {
        ...state,
        skills: action.data.map((i) => ({
      id: i._id,
      value: i._id,
      label: i.title,
      img: i.img,
      isOn:false
    })),
      };
    case "GET_JOB_POSTS":
      return {
        ...state,
        posts: action.data.jobPosts,
        postsMeta: action.data.totalResults
      };
    case "UPDATE_JOB_POST":
      let posts = state.posts
      posts[action.data.idx] = action.data.job
      return {
        ...state,
        post: action.data.job,
        posts:posts
      };
    case "UPDATE_JOB_POST_DATA":
      let jobPosts = state.posts
      jobPosts = jobPosts.filter(post => post._id != action.data.job._id)
      jobPosts.push(action.data.job)
      return {
        ...state,
        post: action.data.job,
        posts:jobPosts
      };
      
    case "FOCUS_JOB_POST":
      return {
        ...state,
        post: action.data,
      };
    case "CREATE_JOB_POST":
      return {
        ...state,
        post: action.data,
        posts: state.posts.push(action.data)
      };
    case "GET_JOB_APPLICATIONS":
      return {
        ...state,
        applications: action.data
      };
    case "GET_JOB_APPLICATION":
      return {
        ...state,
        jobApplication: action.data
      };

    default:
      break;
  }
  return state;
};

export default jobPostReducer;
