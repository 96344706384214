import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import Header from "../partials/Header"
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {fetchAppUsers, fetchPlatformSummary,} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";
import { Overlay } from "react-bootstrap";
import JotformEmbed from 'react-jotform-embed';


class LandingPage extends Component {
  

  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {window.$("#mobile-menu").slideUp();
      }
    };

    return (

      <div>
        <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css"/>
        <link rel="stylesheet" href="/assets/landing-page/css/magnific-popup.css"/>
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        <link rel="stylesheet" href="/myMenu.css" />
   
       <Header />

        <main>
          <section
            id="parallax"
            className=""
            style={{
              height:"75%",
              background: "url(/assets/landing-page/images/25332.jpg)",
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover"}}>
            <div className="slider-shape ss-one layer" data-depth="0.10">
            </div>
            <div className="slider-shape ss-two layer" data-depth="0.30">
            </div>
            <div className="slider-shape ss-three layer" data-depth="0.40">
            </div>
            <div className="slider-shape ss-four layer" data-depth="0.60">
            </div>
            <div className="slider-shape ss-five layer" data-depth="0.20">
            </div>
            <div className="slider-shape ss-six layer" data-depth="0.15">
            </div>
            <div className="slider-shape ss-eight layer" data-depth="">
            </div>
            <div className="slider-active">
              <div className="single-slider">
                <div className="container">
                  <div className="row">
                    <div className="col-8">
                      <div className="slider-content second-slider-content">
                        <h2 style={{marginTop:"100px"}} data-animation="fadeInUp animated" data-delay=".4s">
                          <span>AWS re/Start</span>
                        </h2>
                        <h2 style={{marginTop:"-20px", marginBottom:"100px", fontSize:"45px"}} data-animation="fadeInUp animated" data-delay=".4s">
                          requirements
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
         
<section>


<img src="/assets/landing-page/images/AWS_fFlyer.jpg" alt="FlyerAWS"
width={"600"}
height={"973"} style={{marginTop:"10px", marginBottom:"10px", position:"relative", display:"block", width:"50%", left:"25%"}}>

</img>
</section>
        </main>

        <Footer />
        

        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));
