import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Cookie from "js-cookie";
import { fetchProjects } from "../store/actions/projectsActions";
import ProjectListSkeleton from "./skeletons/ProjectListSkeleton";
import ReactGA from 'react-ga';

class ProjectList extends Component {
  state = {
    isLoading: true,
  };

  
  /**
   * get user projects 
   * update component loading state when projects have returned and loaded
   */
  componentDidMount() {
    this.props.getProjects();
    this.setState({ isLoading: false });
  }

  render() {

    //display loading animations while waiting for projects to load
    if (this.state.isLoading) {
      return (
        <div>
          <ProjectListSkeleton />
          <ProjectListSkeleton />
          <ProjectListSkeleton />
        </div>
      );
    } else {
      return (
        <div style={{ height: "70%", padding: "1rem" }}>
          <div className="page-title-box">
            <h4 className="mb-0 font-size-18" style={{ color: "#000" }}>
              GITHUB PROJECTS{" "}
            </h4>
          </div>

          <div className="row">
            {this.props.projects
              .filter((i, index) => {
                return index < 3 ? true : false;
                // if (index < 3) return;
              })
              .map((project) => (
                <div
                  key={project.node_id}
                  className=" card col-xl-12 mb-2 text-left"
                  style={{ padding: ".5rem", cursor: "pointer" }}
                  onClick={() => (ReactGA.event({category:'View project', action: project.html_url}), window.open(project.html_url))}
                >
                  <div
                    className="card-body"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      padding: "1rem",
                      textAlign: "left",
                    }}
                  >
                    <p className=" card-title font-weight-bold">
                      {project.name}
                    </p>{" "}
                    <p
                      className="mb-4"
                      style={{ maxHeight: "60px", overflow: "hidden" }}
                    >
                      {project.description}
                    </p>
                    {/* {project.language ? <p>Language: {project.language}</p> : ""} */}
                    <div
                      className="text-wrap text-truncate"
                      style={{
                        display: "block",
                        textAlign: "justify",
                        maxHeight: "60px",
                      }}
                    >
                      {project.summary}
                    </div>{" "}
                    <div className="float-sm-left">
                      <div className="btn-group mr-2 mb-2 mb-sm-0">
                        <a
                          href="#"
                          className="btn btn-primary waves-light waves-effect dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bx bxs-star"></i> &nbsp;
                          {project.stargazers_count}
                        </a>
                      </div>
                    </div>
                    <div className="float-sm-right">
                      <span className="badge badge-pill badge-soft-success font-size-12">
                        Size: &nbsp;{project.size}
                      </span>{" "}
                      &nbsp;
                      <span className="badge badge-pill badge-soft-primary font-size-12">
                        {" "}
                        Forks: &nbsp;{project.forks_count}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {JSON.parse(Cookie.get("user")).githubData &&
          JSON.parse(Cookie.get("user")).githubData.html_url ? (
            <a
              href={
                JSON.parse(Cookie.get("user")).githubData.html_url +
                "?tab=repositories"
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "inline-block" }}
            >
              View more projects
            </a>
          ) : (
            ""
          )}

          <div
            className="modal fade projects-modal bs-example-modal-center"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="mySmallModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Add more projects</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="form-horizontal" action="index.html">
                    <div className="form-group mb-0">
                      <label className="control-label">Project Title</label>
                      <br />
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Project Title"
                      ></input>
                      <br />
                      <label className="control-label">Project summary</label>
                      <div className="summernote">Hello</div>
                    </div>
                    <div className="mt-4">
                      <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.projectsData.projectsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjects: (uid) => dispatch(fetchProjects(uid)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectList));
