import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router"

class AdminUsersGridSkeleton extends Component {


    constructor(props) {
        super(props);

    }



    render() {
        return (
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        {/* <!-- start page title --> */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">

                                </div>
                            </div>
                        </div>
                        {/* <!-- end page title --> */}

                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="card text-center" style={{ backgroundColor: '#ffffff73' }}>
                                    <div className="card-body">
                                        <div className="avatar-sm _skeleton rounded-circle mx-auto mb-4">

                                        </div>
                                        <h5 className="font-size-15 _skeleton text-dark" style={{ width: "100%", height: "1rem" }}></h5>
                                        <p className="text-muted _skeleton" style={{ width: "100%", height: "1rem" }}></p>

                                        <div>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                        </div>
                                    </div>
                                    <div className="card-footer bg-transparent border-top">
                                        <div className="contact-links d-flex font-size-20">

                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>

                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="card text-center" style={{ backgroundColor: '#ffffff73' }}>
                                    <div className="card-body">
                                        <div className="avatar-sm _skeleton rounded-circle mx-auto mb-4">

                                        </div>
                                        <h5 className="font-size-15 _skeleton text-dark" style={{ width: "100%", height: "1rem" }}></h5>
                                        <p className="text-muted _skeleton" style={{ width: "100%", height: "1rem" }}></p>

                                        <div>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                        </div>
                                    </div>
                                    <div className="card-footer bg-transparent border-top">
                                        <div className="contact-links d-flex font-size-20">

                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>

                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="card text-center" style={{ backgroundColor: '#ffffff73' }}>
                                    <div className="card-body">
                                        <div className="avatar-sm _skeleton rounded-circle mx-auto mb-4">

                                        </div>
                                        <h5 className="font-size-15 _skeleton text-dark" style={{ width: "100%", height: "1rem" }}></h5>
                                        <p className="text-muted _skeleton" style={{ width: "100%", height: "1rem" }}></p>

                                        <div>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                        </div>
                                    </div>
                                    <div className="card-footer bg-transparent border-top">
                                        <div className="contact-links d-flex font-size-20">

                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>

                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="card text-center" style={{ backgroundColor: '#ffffff73' }}>
                                    <div className="card-body">
                                        <div className="avatar-sm _skeleton rounded-circle mx-auto mb-4">

                                        </div>
                                        <h5 className="font-size-15 _skeleton text-dark" style={{ width: "100%", height: "1rem" }}></h5>
                                        <p className="text-muted _skeleton" style={{ width: "100%", height: "1rem" }}></p>

                                        <div>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                            <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ height: "1rem", padding: '.25rem 2rem' }}>  </span>
                                        </div>
                                    </div>
                                    <div className="card-footer bg-transparent border-top">
                                        <div className="contact-links d-flex font-size-20">

                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>

                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                            <div className="flex-fill">
                                                <span href="#" className="badge font-size-11 m-1 _skeleton" style={{ padding: '.7rem .7rem', borderRadius: '100%' }}>  </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>{" "}
                    {/** <!-- container-fluid --> **/}
                </div>
                {/* <!-- End Page-content --> */}

                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                            <div className="col-sm-6">
                                <div className="text-sm-right d-none d-sm-block">
                                    Skills Ranker
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );

    }
}


export default withRouter(AdminUsersGridSkeleton);
