import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import Header from "../partials/Header"
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg"
import globe from "../../../src/globe.svg"
// import dateFormat from "dateformat";
import empty from "../../../src/empty.gif"
import EventsSkeleton from "../../components/skeletons/EventsSkeleton";
import { formatDate } from "../../utils/utils";

class EventsPage extends Component {

  state = {
    skills: [],
    events: [],
    live: [],
    upcoming: [],
    past: [],
    event: {}
  }

  data = [];
  componentDidMount() {
    this.props.getContests()
      .then(() => (
        this.setState({ events: this.props.contests, live: this.props.live, upcoming: this.props.upcoming, past: this.props.past, isLoaded: true })
      ))
      .catch(err => this.props.dispatchNotification(err))

  }

  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }



  render() {

    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    if (this.state.isLoaded) {
      return (

        <div>
          <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css" />
          <link
            rel="stylesheet"
            href="/assets/landing-page/css/magnific-popup.css"
          />
          <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
          <div style={{ position: "fixed", width: "100%", height: "100%" }}>
            <div style={{
              backgroundImage: "url(" + globe + ")", transform: "translate(-50%, -50%) scale(1)", position: "absolute", top: "50%", left: "50%", width: "100%",
              height: "100%",
              transition: "transform .15s ease-out 0s",
              transform: "translate(-50%,-50%)",
              transformOrigin: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: " cover",
              willChange: "transform"
            }}>
            </div>


          </div>

          <Header />

          <main>
            <section
              id="parallax"
              className="parallax slider-area slider2-bg second-slider2-bg d-flex align-items-center justify-content-left fix">
              {this.state.live.length > 0 ?
                <div style={{ backgroundImage: "url(" + this.state.live[0].eventBanner.data + ")", position: "absolute", width: "100vw", height: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                :
                this.state.upcoming.length > 0 ?
                  <div style={{ backgroundImage: "url(" + this.state.upcoming[0].eventBanner.data + ")", position: "absolute", width: "100vw", height: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                  :
                  this.state.past.length > 0 ?
                    <div style={{ backgroundImage: "url(" + this.state.past[0].eventBanner.data + ")", position: "absolute", width: "100vw", height: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                    :
                    <div style={{ backgroundImage: "url(" + bih + ")", position: "absolute", width: "100vw", height: "100%", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
              }


              {/* <div style={{ background: "#00000063", position: "absolute", width: "100vw", height: "100%" }}></div> */}
              <div className="slider-active" style={{ width: "100%" }}>
                <div className="single-slider">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="slider-content second-slider-content" style={{ padding: "8rem"}}>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />

                          {/* {this.state.live.length > 0 ?
                            <a className="btn" href={"/event/register/" + this.state.live[0]._id}>
                              {" "}
                            Register For Event
                          </a>
                            :
                            this.state.upcoming.length > 0 ?
                              <a className="btn" href={"/event/register/" + this.state.upcoming[0]._id}>
                                {" "}
                              Register For Event
                            </a>
                              :
                              this.state.past.length > 0 ?
                                <a className="btn" href={"/event/register/" + this.state.past[0]._id}>
                                  {" "}
                                Register For Event
                              </a>
                                :
                                ""


                          } */}


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{ minHeight: "80vh", padding: "2rem 0" }}
              id="how-it-works"
              className="about-area about-p p-relative d-flex"
            >

              <div className="container-fluid">
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="card" style={{ paddingTop: "10px" }}>
                      <div className="nav-tabs nav-tabs-custom">
                        <ul className="nav tab-nav" role="tablist" >
                          <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#home1" role="tab" aria-selected="false" style={{ color: "black" }}>
                              <span style={{ position: "absolute", top: "0", right: "0", margin: "0px", backgroundColor: "#FFC40C" }}
                                href="#"
                                className="badge badge-primary font-size-11"
                              >{this.state.live.length}
                              </span>
                              <span className="d-sm-block">Live</span>
                            </a>
                          </li>

                          <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#profile1" role="tab" aria-selected="false" style={{ color: "black" }}>
                              <span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "#FFC40C" }}
                                href="#"
                                className="badge badge-primary font-size-11"
                              >{this.state.upcoming.length}
                              </span>
                              <span className="d-sm-block">Upcoming</span>
                            </a>
                          </li>


                          <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#messages1" role="tab" aria-selected="false" style={{ color: "black" }}>
                              <span style={{ position: "absolute", backgroundColor: "#FFC40C", top: "0px", right: "0px" }}
                                href="#"
                                className="badge badge-primary font-size-11"
                              >{this.state.past.length}
                              </span>
                              <span className="d-sm-block">Past</span>
                            </a>
                          </li>

                        </ul>
                      </div>
                      {/* <!-- Tab panes --> */}
                      <div className="tab-content p-3 text-muted" >
                        <div className="tab-pane active" id="home1" role="tabpanel" >
                          <div className="row">
                            {this.state.live.length > 0 ?
                              this.state.live.map((contest, index) => (

                                <div className="col-md-3 col-xl-3" key={index}>
                                  {/* <!-- Simple card --> */}
                                  <div
                                    onClick={() =>
                                      this.props.history.push("/event/" + contest._id)
                                    }
                                    className="card"
                                    style={{ textAlign: "center", cursor: "pointer", boxShadow: "rgb(136 136 136 / 43%) 3px 5px 11px" }}
                                  >
                                    <div style={{
                                      height: "150px",
                                      width: "100%"
                                      , backgroundImage: "url(" + contest.eventBanner.data + ")",
                                      backgroundOrigin: "center",
                                      backgroundSize: "cover"
                                    }}>
                                    </div>
                                    {/* <img
                                    className="card-img-top img-fluid"
                                    src={contest.banner}
                                    alt="Card image cap"
                                  /> */}


                                    <div className="card-body" style={{ textAlign: "left" }}>

                                      <h4 className="card-title mt-0 font-weight-bolder text-dark" style={{ fontSize: '20px' }}>{contest.title}</h4>
                                      {/* <p className="mb-1">Time line</p> */}
                                      <p className="card-text" style={{ fontSize: "12px" }}>
                                        <b className="text-primary font-weight-bolder">Starts: </b>{formatDate(new Date(contest.startDate),)} <br />
                                        <b className="text-primary font-weight-bolder">Ends: </b>{formatDate(new Date(contest.endDate),)}
                                      </p>

                                      <div style={{ textAlign: "left" }}>
                                        {contest.tags.map((tag, index) => (
                                          <span key={{ index }}
                                            href="#"
                                            className="badge badge-primary font-size-11 m-1"
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                      </div>
                                      {/* <button className="btn btn-primary">View</button> */}
                                    </div>
                                  </div>
                                </div>
                              ))

                              :
                              <>
                                <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                                  <div className="card" style={{ cursor: "pointer", border: "None", minHeight: "20rem" }}>
                                    <div className="card-body" style={{ backgroundImage: "url(" + empty + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                      <p style={{ fontSize: "1.5vw" }}>NO LIVE EVENTS</p>
                                    </div>
                                  </div>
                                </div>
                              </>

                            }

                          </div>
                        </div>
                        <div className="tab-pane" id="profile1" role="tabpanel">
                          <div className="row">
                            {this.state.upcoming.length > 0 ?
                              this.state.upcoming.map((contest, index) => (
                                <div className="col-md-3 col-xl-3" key={index}>
                                  {/* <!-- Simple card --> */}
                                  <div
                                    onClick={() =>
                                      this.props.history.push("/event/" + contest._id)
                                    }
                                    className="card"
                                    style={{ textAlign: "center", cursor: "pointer", boxShadow: "rgb(136 136 136 / 43%) 3px 5px 11px" }}
                                  >
                                    <div style={{
                                      height: "150px",
                                      width: "100%"
                                      , backgroundImage: "url(" + contest.eventBanner.data + ")",
                                      backgroundOrigin: "center",
                                      backgroundSize: "cover"
                                    }}></div>
                                    {/* <img
                                    className="card-img-top img-fluid"
                                    src={contest.banner}
                                    alt="Card image cap"
                                  /> */}


                                    <div className="card-body" style={{ textAlign: "left" }}>

                                      <h4 className="card-title mt-0 font-weight-bolder text-dark" style={{ fontSize: '20px' }}>{contest.title}</h4>
                                      {/* <p className="mb-1">Time line</p> */}
                                      <p className="card-text" style={{ fontSize: "12px" }}>
                                        <b className="text-primary font-weight-bolder">Starts: </b>{formatDate(new Date(contest.startDate),)} <br />
                                        <b className="text-primary font-weight-bolder">Ends: </b> {formatDate(new Date(contest.endDate),)}
                                      </p>

                                      <div style={{ textAlign: "left" }}>
                                        {contest.tags.map((tag, index) => (
                                          <span key={{ index }}
                                            href="#"
                                            className="badge badge-primary font-size-11 m-1"
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              ))

                              :
                              <>
                                <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                                  <div className="card" style={{ cursor: "pointer", border: "None", minHeight: "20rem" }}>
                                    <div className="card-body" style={{ backgroundImage: "url(" + empty + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                      <p style={{ fontSize: "1.5vw" }}>NO UPCOMING EVENTS</p>
                                    </div>
                                  </div>
                                </div>
                              </>

                            }


                          </div>
                        </div>
                        <div className="tab-pane" id="messages1" role="tabpanel">
                          <div className="row">
                            {this.state.past.length > 0 ?
                              this.state.past.map((contest, index) => (
                                <div key={index} className="col-md-3 col-xl-3">
                                  {/* <!-- Simple card --> */}
                                  <div
                                    onClick={() =>
                                      this.props.history.push("/event/" + contest._id)
                                    }
                                    className="card"
                                    style={{ textAlign: "center", cursor: "pointer", boxShadow: "rgb(136 136 136 / 43%) 3px 5px 11px" }}
                                  >
                                    <div style={{
                                      height: "150px",
                                      width: "100%"
                                      , backgroundImage: "url(" + contest.eventBanner.data + ")",
                                      backgroundOrigin: "center",
                                      backgroundSize: "cover"
                                    }}></div>
                                    {/* <img
                                    className="card-img-top img-fluid"
                                    src={contest.banner}
                                    alt="Card image cap"
                                  /> */}


                                    <div className="card-body" style={{ textAlign: "left" }}>

                                      <h4 className="card-title mt-0 font-weight-bolder text-dark" style={{ fontSize: '20px' }}>{contest.title}</h4>
                                      {/* <p className="mb-1">Time line</p> */}
                                      <p className="card-text" style={{ fontSize: "12px" }}>
                                        <b className="text-primary font-weight-bolder">Starts: </b> {formatDate(new Date(contest.startDate),)} <br />
                                        <b className="text-primary font-weight-bolder">Ends: </b> {formatDate(new Date(contest.endDate),)}
                                      </p>

                                      <div style={{ textAlign: "left" }}>
                                        {contest.tags.map((tag, index) => (
                                          <span key={{ index }}
                                            href="#"
                                            className="badge badge-primary font-size-11 m-1"
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              ))

                              :
                              <>
                                <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                                  <div className="card" style={{ cursor: "pointer", border: "None", minHeight: "20rem" }}>
                                    <div className="card-body" style={{ backgroundImage: "url(" + empty + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                      <p style={{ fontSize: "1.5vw" }}>NO PAST EVENTS</p>
                                    </div>
                                  </div>
                                </div>
                              </>

                            }
                          </div>
                        </div>

                      </div>


                    </div>

                  </div>

                </div>
              </div>

            </section>


          </main>

          <Footer />


          {/* <Switch>
            <Route path={"/profile"} component={Profile} exact />
            <Route path={"/profile/leaderboard"} component={Rankings} exact />
            <Route exact path={"/profile/assesments"} component={Profile} />
            <Route exact path={"/profile/project/:id"} component={Project} />
          </Switch> */}
        </div >
      );
    }
    else {
      return (
        <div>
          <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css" />
          <link
            rel="stylesheet"
            href="/assets/landing-page/css/magnific-popup.css"
          />
          <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
          <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
          <div style={{ position: "fixed", width: "100%", height: "100%" }}>
            <div style={{
              backgroundImage: "url(" + globe + ")", transform: "translate(-50%, -50%) scale(1)", position: "absolute", top: "50%", left: "50%", width: "100%",
              height: "100%",
              transition: "transform .15s ease-out 0s",
              transform: "translate(-50%,-50%)",
              transformOrigin: "50% 50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: " cover",
              willChange: "transform"
            }}>
            </div>


          </div>

          < Header />

          <main>
            <section
              id="parallax"
              className="parallax slider-area slider2-bg second-slider2-bg d-flex align-items-center justify-content-left fix"
            // style={{ backgroundColor: "rgb(0,0,0)" }}
            >
              {this.state.live.length > 0 ?
                <div style={{ backgroundImage: "url(" + this.state.live[0].eventBanner.data + ")", position: "absolute", width: "100vw", height: "100%", backgroundPosition: "center", backgroundSize: "cover" }}></div>
                :
                <div style={{ backgroundImage: "url(" + bih + ")", position: "absolute", width: "100vw", height: "100%", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
              }


              <div style={{ background: "#00000063", position: "absolute", width: "100vw", height: "100%" }}></div>
              <div className="slider-active">
                <div className="single-slider">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="slider-content second-slider-content" style={{ padding: "2rem 0rem" }}>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <h3 data-animation="fadeInUp animated" data-delay=".4s" style={{ fontSize: "3vw", color: "white" }}>
                            {this.state.live.length > 0 ? this.state.live[0].title : ""}
                          </h3>
                          <p data-animation="fadeInUp animated" data-delay=".4s">
                            {this.state.live.length > 0 ? formatDate(new Date(this.state.live[0].startDate),) + " - " + formatDate(new Date(this.state.live[0].endDate),) : ""}
                          </p>
                          <p data-animation="fadeInUp animated" data-delay=".4s" style={{ color: "white" }}>
                            {this.state.live > 0 ? this.state.live[0].shortDescription : ""}
                          </p>
                          <br />
                          <a
                            id="loginButton"
                            href={this.user ? "/profile" : "/login"}
                            className="btn mb-3 d-none"
                            style={{ float: "left" }}
                          >
                            <i className="fa fa-user-alt" />{" "}
                            {this.user ? "Profile" : "Login"}
                          </a>
                          <br />
                          {this.state.live.length > 0 ?
                            <a className="btn" href={"/event/register/" + this.state.live[0]._id}>
                              {" "}
                              Register For Event
                            </a>
                            :
                            ""
                          }

                          <br />
                          <br />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              style={{ minHeight: "80vh", padding: "2rem 0" }}
              id="how-it-works"
              className="about-area about-p p-relative d-flex"
            >

              <EventsSkeleton />
            </section>
          </main >
        </div >
      )
    }

  }

}


const mapStateToProps = (state) => {
  return {
    contests: state.contestData.contests,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getContests: () => dispatch(fetchAllContests()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EventsPage));
