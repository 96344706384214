import {
  getUserAssessments,
  getAllAssesments,
  getAssesment,
  getAssessments,
  newAssessment,
  updateAssessment,
  removeAssessment,
} from "../../api/assesments"
import { fireNotification } from "./notificationsActions";


// not in use
export const fetchUserAssesments = (uid) => {
  return dispatch => {
    dispatch({
      type: "PULL_USER_ASSESMENTS",
      data: [{
        key: 0,
        technology: "Reactjs",
        percentage: "100%",
        level: "Expert"
      }]
    })
  };
};



export const fetchAllAssesments = (body) => {
  return dispatch => {
    return getAllAssesments(body)
      .then(response => {
        return dispatch({
          type: "PULL_GLOBAL_ASSESMENTS",
          data: response.data
        });

      })
      .catch(err => Promise.reject(err));
  };
};


export const fetchAssesments = (body) => {
  return dispatch => {
    return getAssessments(body)
      .then(response => {
        // dispatch(fireNotification(response))
        return dispatch({
          type: "PULL_GLOBAL_ASSESMENTS",
          data: response.data
        });

      })
      .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
  };
};


/**
 * Get user completed assessments
 * 
 * @param  {Dict} body details
 */
export const fetchCompletedAssessments = (body) => {
  return dispatch => {
    return getUserAssessments(body)
      .then(response => {
        return dispatch({
          type: "GET_COMPLETED_ASSESSMENTS",
          data: response.data
        });

      })
      .catch(err => Promise.reject(err));
  };
};


/**
 * Get a single assessment
 * 
 * @param  {String} aid assessment ID
 */
export const fetchAssesment = (aid) => {
  return dispatch => {
    return getAssesment(aid)
      .then(response => {
        return dispatch({
          type: "PULL_ASSESMENT",
          data: response.data
        });

      })
      .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
  };
};


/**
 * Delete an assessment 
 * 
 * @param  {} aid assessment id
 */
export const deleteAssessment = (aid) => {
  return dispatch => {
    return removeAssessment(aid)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "DELETE_ASSESMENT",
          data: response.data
        });

      })
      .catch(err => dispatch(fireNotification(err)))
  };
};


/**
 * create a new assessment
 * edit an assessment
 * 
 * @param  {} aId assessment id
 * @param  {} data details
 */
export const pushAssessment = (aId, data) => {
  return dispatch => {
    if (aId == null) {

      //create new assessment
      return newAssessment(data)
        .then(response => {
          const response_info = response.data;
          dispatch({
            type: "ASSESSMENT_RESPONSE_INFO",
            response_info
          });
          return dispatch(fireNotification(response))
        })
        .catch(err => (
          dispatch(fireNotification(err)),Promise.reject(err)
        ));
    } else {

      //update an assessment
      return updateAssessment(aId, data)
        .then(response => {
          const response_info = response.data;
          dispatch({
            type: "ASSESSMENT_RESPONSE_INFO",
            response_info
          });
          return dispatch(fireNotification(response))
        })
        .catch(err => (
          dispatch(fireNotification(err)),Promise.reject(err)
        ));
    }

  };
};