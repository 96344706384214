import React, { useState } from "react";
import { isValidEmail } from "../../utils/utils";
import { Link } from "react-router-dom";
import Error from "../partials/Error";
import Success from "../partials/Success";
import { forgotUserPassword } from "../../store/actions/authActions";
import { connect } from "react-redux";

function ForgotPassword2(props) {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setSuccess("");
    setError("");
    if (isValidEmail(email)) {
      setIsLoading(true);
      props
        .forgotUserPassword(email)
        .then(() =>
          setSuccess(
            "Password recovery email sent. Please check your email for further instructions. "
          )
        )
        .catch(err => {
          console.log(err);

          displayError(
            err.response
              ? err.response.data.message
              : "Failed to request account recovery. Please try again."
          );
        })
        .finally(() => setIsLoading(false));
    } else displayError("Please enter a valid email");
  }

  function displayError(_error) {
    setError(_error);
    setTimeout(() => {
      setError("");
    }, 5000);
  }

  return (

    <div className="login-signin-container">
      <div className=" login-wrapper" >
      
        <div className="col login-signup-form vr" >
          
          {isLoading ? (
                      "Completing request..."
                    ) : (
                      
          <form style={{padding: '8rem 5rem'}} onSubmit={handleSubmit}>
            {success !== "" ? (
                          <div className="mb-3">
                            {" "}
                            <Success message={success} />{" "}
                            <Link to="/login">  LOGIN </Link>{" "}
                          </div>
                        ) : (
                          ""
                        )}{" "}
                        {error !== "" ? <Error error={error} /> : ""}{" "}
            <div className="mb-4">
              <input id="email" className=" login-signin-input form-control" type="email" required placeholder="Email" onChange={event => setEmail(event.target.value)} />
            </div>
            
            <div className="mt-3">
              <button type="submit" className=" login-btn btn btn-rounded waves-effect waves-light" >Recover password</button>
            </div>
            
          </form>
              )
            } 
        </div>
        <div className="signup-message d-none d-md-block" >
          <div className="signup-message-wrapper">
            <div className="mb-4">
              <h1 style={{color:'gray', textTransform: 'capitalize'}}>Are you an innovator</h1>
            </div>
            <div className="mb-4">
              <p style={{color:"gray"}}>Create an account and rank your skills</p>
            </div>
            <div className="mt-3">
              <button  type="button" onClick={() => props.history.push("/register")} className="signup-1-btn btn btn-rounded waves-effect waves-light" >SIGN UP</button>
            </div>
            <div className="mt-5 text-center">
                
                <p style={{color:"gray"}}>
                  {new Date().getFullYear()} © Botswana Innovation Hub.{" "}
                </p>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotUserPassword: email => dispatch(forgotUserPassword(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword2);
