import {
  getAllCourses,
  newCourse,
  getCourse,
  updateCourse,
  removeCourse,
} from "../../api/course";
import Cookies from "js-cookie";

export const fetchAllCourses = () => {
  return dispatch => {
    return getAllCourses()
        .then(response => {
            const courses = response.data;
            dispatch({
                type: "GET_ALL_COURSES",
                courses
            });
        })
  };
};
 
export const fetchCourse = (cid) => {
  return dispatch => {
      return getCourse(cid)
          .then(response => {
              const course = response.data;
              dispatch({
                  type: "GET_COURSE",
                  course
              });
          })
        
  };
};

export const deleteCourse = (cid) => {
    return dispatch => {
        return removeCourse(cid)
            .then(response => {
                
                dispatch({
                    type: "DELETE_COURSE",
                    data: response.data,
                });
            })
          
    };
  };

export const publishCourse = (courseId, data) => {
    return dispatch => {
      if(courseId == null){
          return newCourse(data)
              .then(response => {
                  const response_info = response.data;
                  dispatch({
                      type: "COURSE_RESPONSE_INFO",
                      response_info
                  });
              })
              .catch(err => {
                  Promise.reject(err)
              });
      }else{
          return updateCourse(courseId, data)
              .then(response => { 
                  dispatch({
                      type: "COURSE_RESPONSE_INFO",
                      response_info: response.data,
                  });
              })
              .catch(err => {
                  Promise.reject(err)
              });
      }
      
    };
};