import { client } from "./client";
import store from "../index"

export function createComplianceElement(body) {
  return client.post(`/compliance/check`, body);
}

export function updateComplianceElement(body) {
  return client.put(`/compliance/check`, body);
}

export function reviveComplianceElement(id) {
  return client.put(`/compliance/check/revive`, { id: id });
}

export function getComplianceElements() {
  return client.get(`/compliance/checks`);
}

export function getBusinessComplianceElements(bid) {
  return client.get(`/business/compliance/checks?bId=${bid}`);
}

export function deleteComplianceElement(id) {
  return client.put(`/compliance/check/${id}`, { id: id });
}

export function uploadComplianceDoc(body) {
  let options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      store.dispatch({
        type: "UPLOAD_PROGRESS_EVENT",
        data: {
          "percent": percent,
          "total": total,
          "uploaded": loaded
        }

      });
    }
  }
  return client.post(`compliance/document`, body, options)
}

export function checkCompliance(body) {
  return client.post(`/business/compliance/check`, body);
}
export function changeBusinessCompliance(body) {
  return client.put(`/business/compliance/check`, body);
}
