import React, { Component } from "react";
import { withRouter } from "react-router";
import { client } from "../api/client";
import { getLanguages } from "../api/languages";

class AdminUsersGrid extends Component {

  state = { 
            submitting: [], 
            users: [] 
          };

  constructor(props) {
    super(props);
    this.disableUser = this.disableUser.bind(this);
    this.state.submitting = [];
  }

  /**
   * When component is mounted, update component state
   * with values passed from users property
   */
  componentDidMount() {
    this.setState({ users: this.props.users });
  }



  disableUser(userId, status) {
    this.setState({ submitting: this.state.submitting.concat(userId) });

    client.put("/user/status", { userId, status }).then((res) => {
      this.setState({ users: res.data });
      this.state.submitting.splice(this.state.submitting.indexOf(userId), 1);
    });
  }

  mapUserLangs = (langs) => {
    var newLangs = []
    getLanguages(langs)
    .then(res => (console.log(res.data).newLangs.push(res.data)))

    
    console.log(newLangs);
    return newLangs
    
  }

  render() {
    return (
      <>
        {/* iterate over list of users reandering a user card for each user */}
        {this.state.users.map((user) => (
          <div key={user._id} className="col-xl-3 col-lg-3 col-sm-6">
            <div className="card text-center">
              <div className="card-body">
                <div className="avatar-sm mx-auto mb-4">
                  
                  {/* check id user github data is available,
                   display user avatar if true else display users initials
                   if user name is not available display blank avatar */}

                  {user.githubData ? (
                    <img
                      className="rounded-circle avatar-sm"
                      src={user.githubData.avatar_url}
                      alt=""
                    ></img>
                  ) : typeof user.name !== undefined ? (
                    <span
                      className="avatar-title rounded-circle font-size-16"
                      style={{
                        backgroundColor: "#0a0b0e9e",
                        color: "#ffffffa3!important",
                      }}
                    >
                      {" "}
                      {user.name.firstName[0] +
                        " " +
                        user.name.lastName[0]}{" "}
                    </span>
                  ) : (
                    <span
                      className="avatar-title rounded-circle font-size-16"
                      style={{
                        backgroundColor: "#0a0b0e9e",
                        color: "#ffffffa3!important",
                      }}
                    >
                      {" "}
                    </span>
                  )}
                </div>
                <h5 className="font-size-15 text-dark">
                  {typeof user.name !== undefined
                    ? user.name.firstName + " " + user.name.lastName
                    : ""}
                </h5>
                <p className="text-muted">
                  {user.titles.slice(0, 3).map((item) => (
                    <span className="pl-2">{item}</span>
                  ))}
                </p>

                <div>
                  { 
                  this.mapUserLangs(user.programmingLanguages).slice(0, 2).map((item) => (
                    <span
                      href="#"
                      className="badge badge-primary font-size-11 m-1"
                    >
                      {item.title}
                    </span>
                  ))
                  
                  
                  }
                  
                  {user.programmingLanguages.length > 2 ? (
                    <span
                      href="#"
                      className="badge badge-primary font-size-11 m-1"
                    >
                      {user.programmingLanguages.length - 2 + " + more"}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card-footer bg-transparent border-top">
                <div className="contact-links d-flex font-size-20">
                  <div className="flex-fill">
                    {user.githubData ? (
                      <a
                        href="/#"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Github Linked"
                      >
                        <i className="bx bxl-github"></i>
                      </a>
                    ) : (
                      <a
                        href="/#"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Github Linked"
                      >
                        <i className="bx bx-unlink"></i>
                      </a>
                    )}
                  </div>
                  <div className="flex-fill">
                    <a
                      href="/#"
                      dataToggle="tooltip"
                      dataPlacement="top"
                      title={
                        user.githubData
                          ? user.githubData.public_repos + " public projects"
                          : "0 public projects"
                      }
                    >
                      <i className="bx bx-pie-chart-alt"></i>
                    </a>
                  </div>
                  <div className="flex-fill">
                    {user.accountStatus.status ? (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={this.disableUser.bind(
                          this,
                          `${user._id}`,
                          !user.accountStatus.status
                        )}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Disable account"
                      >
                        <i className="bx bx-user-check"></i>
                      </span>
                    ) : (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={this.disableUser.bind(
                          this,
                          `${user._id}`,
                          !user.accountStatus.status
                        )}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Enable account"
                      >
                        <i className="bx bx-user-x"></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}

export default withRouter(AdminUsersGrid);
