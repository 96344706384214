import React, { Component } from "react";
import { getLanguageIcon } from "../utils/utils";
import BadgeHorizontal from "./BadgeHorizontal";

class UserLanguagesList extends Component {
  constructor(...props) {
    super(...props);
  }

  render() {
    return (
      <div className="card pl-4 pr-3">
        {/* <!-- Languages --> */}
        <div className="card-body">
          <h4 className="card-title text-uppercase" style={{ color: "#000" }}>
            Languages
          </h4>
        </div>
        <div className="row mb-4">
          {this.props.userLanguages.map((item, index) => (
            <BadgeHorizontal
              icon={getLanguageIcon(item.img ? item.img : "")}
              data={item.title ? item.title : "-"}
              // key={index}
            />
           
          ))}
        </div>
      </div>
    );
  }
}

export default UserLanguagesList;
