import {
    client
} from "./client";

export function getAllCourses() {
    return client.get("/courses/", {
        // headers: {
        //     session: session
        // }
    });
}

export function getCourse(cid) {
    return client.get("/courses/"+cid, {
        // headers: {
        //     session: session
        // }
    });
}

export function updateCourse(cid, data) {
    return client.put("/courses/"+cid, data);
}

export function newCourse(body) {
    return client.post("/courses/", body);
}

export function removeCourse(cid) {
    return client.delete("/courses/"+cid);
}