import React from "react";
import { createBrowserHistory as history } from "history";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import PrivateRoute from "./containers/pages/PrivateRoute";
import AdminRoute from "./containers/pages/AdminRoute";
import SponsorRoute from "./containers/pages/SponsorRoute";
import Wrapper from "./components/Wrapper";
import AdminWrapper from "./components/AdminWrapper";
import Cookie from "js-cookie";
import GithubOauth from "./containers/pages/GithubOauth";
import LandingPage from "./containers/pages/LandingPage";
import LandingPage2 from "./containers/pages/LandingPage2";
import Login2 from "./containers/pages/Login2";
import SignUp2 from "./containers/pages/SignUp2";
import ForgotPassword2 from "./containers/pages/ForgotPassword2";
import PasswordRecovery2 from "./containers/pages/PasswordRecovery2";
import Data404 from "./containers/pages/Data404";
import SetupAccount2 from "./containers/pages/SetupAccount2";
import Policy from "./containers/pages/Policy";
import FAQs from "./containers/pages/FAQs";
import EventsPage from "./containers/pages/EventsPage";
import restart_Registration from "./containers/pages/restart_Registration";
import AWSrequirements from "./containers/pages/AWSrequirements";
import aboutAWS from "./containers/pages/AboutAWS";
import plannedHackathons from "./containers/pages/plannedHackathons";
import EventDetailsPage from "./containers/pages/EventDetailsPage";
import HackathonRegistration from "./containers/pages/HackathonRegistration";
// import JudgesWrapper from "./components/JudgesWrapper";
import SponsorWrapper from "./components/SponsorWrapper";
import JudgePage from "./containers/pages/JudgePage";
import Discussion from "./components/DiscussionsTest";
import BusinessWrapper from "./components/BusinessWrapper";
import TeamInvites from "./containers/pages/TeamInvites";

function App() {
  const authenticated =
    typeof Cookie.get("user") !== "undefined" || Cookie.get("user") !== null;
  const isAccountSetup =
    typeof Cookie.get("user") !== "undefined" &&
    JSON.parse(Cookie.get("user")).isSetup;

  const accountType =
    typeof Cookie.get("user") !== "undefined"
      ? JSON.parse(Cookie.get("user")).accountType
      : "";

  if (
    window.screen.width * window.devicePixelRatio < 1920 &&
    window.screen.height * window.devicePixelRatio < 1080
  ) {
    window.$(".App").css("zoom", 0.7);
  }

  const trackingId = "UA-164005018-1";
  ReactGA.initialize(trackingId);
  // ReactGA.pageview('/home');
  // ReactGA.set({
  //   userId: auth.currentUserId(),
  //   // any data that is relevant to the user session
  //   // that you would like to track with google analytics
  // })

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <PrivateRoute
            authenticated={authenticated}
            isAccountSetup={isAccountSetup}
            path="/profile"
            component={Wrapper}
            accountType={accountType}
          />{" "}
          <Route path="/business" component={BusinessWrapper} />{" "}
          {/* <PrivateRoute
            authenticated={authenticated}
            isAccountSetup={isAccountSetup}
            path="/business"
            component={BusinessWrapper}
            accountType={accountType}
          />{" "} */}
          <PrivateRoute
            authenticated={true}
            isAccountSetup={true}
            path="/judge"
            component={JudgePage}
            accountType={accountType}
          />{" "}
          <PrivateRoute
            authenticated={typeof Cookie.get("user") !== "undefined"}
            path="/project"
            component={Wrapper}
            accountType={accountType}
          />{" "}
          <Route exact path="/privacy-policy" component={Policy} />{" "}
          <Route exact path="/faqs" component={FAQs} />{" "}
          <Route exact path="/" component={LandingPage} />{" "}
          <Route exact path="/home" component={LandingPage2} />{" "}
          <Route exact path="/events" component={EventsPage} />{" "}
          <Route exact path="/awsRegistration" component={restart_Registration} />{" "}
          <Route exact path="/awsRequirements" component={AWSrequirements} />{" "}
          <Route exact path="/aboutAWS" component={aboutAWS} />{" "}
          <Route exact path="/event/:id" component={EventDetailsPage} />{" "}
          <Route path="/event/invites/:eventInvite" component={TeamInvites} />{" "}
          <Route path="/event/register/:id" component={HackathonRegistration} />{" "}
          <Route path="/plannedHackathons" component={plannedHackathons} />{" "}
          <Route exact path="/login" component={Login2} />{" "}
          <Route path={"/discussion"} component={Discussion} />
          <Route exact path="/register" component={SignUp2} />{" "}
          <Route exact path="/forgot" component={ForgotPassword2} />{" "}
          <Route exact path="/reset/:token" component={PasswordRecovery2} />{" "}
          <Route exact path="/github/oauth" component={GithubOauth} />{" "}
          <Route exact path="/setup" component={SetupAccount2} />{" "}
          <AdminRoute
            authenticated={authenticated}
            accountType={accountType}
            path="/admin"
            component={AdminWrapper}
          />{" "}
          <SponsorRoute
            authenticated={authenticated}
            accountType={accountType}
            path="/sponsor"
            component={SponsorWrapper}
          />{" "}
          <Route component={Data404} />{" "}
        </Switch>{" "}
      </Router>{" "}
    </div>
  );
}

export default App;
