import React, { Component } from "react";
import { fetchJobApplications } from "../store/actions/businessProfileActions";
import { fireNotification } from "../store/actions/notificationsActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

class JobApplicantList extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    applications:[]
  };

  componentDidMount() {
    this.props.getApplications(this.props.job)
    .then(() => {
      this.setState({applications:this.props.applications})
    })
  }


  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
        <>
          {
            this.state.applications.map(application => (
              <div key={"a"+application._id} className="row">
                <div  className="col-12">
                  <div
                    className="card job-card"
                    
                  >
                    <div
                      className="card-body"
                      style={{ paddingBottom: ".5rem" }}
                    >
                      <div className="media">
                        <div className="avatar-lg mr-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                            <img
                              src={application.applicant.githubData ? application.applicant.githubData.avatar_url : ""}
                              alt=""
                              height="30"
                              className="img-thumbnail rounded-circle"
                            />
                          </span>
                        </div>
                        <div className="media-body overflow-hidden row">
                          <div className="col-sm-7">
                            <p
                             
                              className="job-type mb-1"
                            >
                              {application.applicant.titles[0]}
                            </p>
                            <h5
                              className="text-truncate font-size-18 font-weight-bolder"
                              style={{
                                fontFamily: "monospace",
                                color: "#000",
                              }}
                               onClick={() => this.props.navigateToApplicant(application.applicant._id)}
                            >
                              <a href="#" className="text-dark">
                              {application.applicant.name.firstName+" "+application.applicant.name.lastName}
                              </a>
                            </h5>
                            <p
                              style={{
                                maxHeight: "3.5rem",
                                fontFamily: "monospace",
                                overflow: "hidden",
                              }}
                              className="text-muted mb-0"
                            >
                              {application.applicant.email}
                            </p>

                            <p
                              style={{
                                maxHeight: "3.5rem",
                                fontFamily: "monospace",
                                overflow: "hidden",
                              }}
                              className="text-muted mb-2"
                            >
                              {application.applicant.location}
                            </p>
                          
                          </div>
                          <div className="col-sm-4" style={{display:"flow-root"}}>
                            <div className="row">
                              <div className="col-md-6">
                                {
                                  application.documents ? 
                                  <button className=" btn btn-info mt-4 waves-effect waves-light" >Download CV <i className="bx bx-right-arrow-alt ml-2 font-size-16 align-middle mr-2"></i></button>
                                  :
                                  ""
                                }
                                
                              </div>
                              <div className="col-md-6">
                                <button className=" btn btn-warning mt-4 waves-effect waves-light"onClick={() => this.props.navigateToApplicant(application.applicant._id)} >Messages <i className="bx bx-right-arrow-alt ml-2 font-size-16 align-middle mr-2"></i></button>
                              </div>
                            </div>
                            
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 py-3 border-top">
                      <ul className="list-inline mb-0">
                        
                        <li
                          className="list-inline-item mr-3 mb-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Due Date"
                        >
                          
                          Application Date:  {moment(application.createdAt).format("MMM D, YYYY")}
                          
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
            
            {/* <div className="row">
                      <div className="col-lg-12">
                          <ul className="pagination pagination-rounded justify-content-center mt-4">
                              <li className="page-item disabled">
                                  <a href="#" className="page-link"><i className="mdi mdi-chevron-left"></i></a>
                              </li>
                              <li className="page-item">
                                  <a href="#" className="page-link">1</a>
                              </li>
                              <li className="page-item active">
                                  <a href="#" className="page-link">2</a>
                              </li>
                              <li className="page-item">
                                  <a href="#" className="page-link">3</a>
                              </li>
                              <li className="page-item">
                                  <a href="#" className="page-link">4</a>
                              </li>
                              <li className="page-item">
                                  <a href="#" className="page-link">5</a>
                              </li>
                              <li className="page-item">
                                  <a href="#" className="page-link"><i className="mdi mdi-chevron-right"></i></a>
                              </li>
                          </ul>
                      </div>
                    </div> */}       
        </>     
    );
  }
}

const mapStateToProps = (state) => {
  return {
    applications: state.jobPostData.applications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getApplications: (id) => dispatch(fetchJobApplications(id))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(JobApplicantList));
