import React, { useState } from "react";
import Error from "../partials/Error";

import { isNotEmpty } from "../../utils/utils";
import { updateUser } from "../../store/actions/usersActions";
import { connect } from "react-redux";
import Select from "react-select";
import Cookies from "js-cookie";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { client } from "../../api/client";

function UpdateUserAccount({ history, user, updateUser }) {
  const [location, setLocation] = useState(typeof user.location !== "undefined" ? user.location : "");
  const [firstName, setFirstName] = useState(typeof user.name !== "undefined" ? user.name.firstName : "");
  const [lastName, setLastName] = useState(typeof user.name !== "undefined" ? user.name.lastName : "");
  const [contactNumber, setContactNumber] = useState(typeof user.contactNumber !== "undefined" ? user.contactNumber.startsWith("267+") ? user.contactNumber.replace("267", "").replace("+267", "") : user.contactNumber.replace("+") : "");
  const [gender, setGender] = useState(typeof user.gender !== "undefined" ? { value: user.gender, label: user.gender } : "");
  const [titles, setTitles] = useState(typeof user.titles !== "undefined" ? user.titles.map((t) => ({ value: t, label: t })) : "");
  const [dob, setDob] = useState(typeof user.dob !== "undefined" && user.dob !== "" ? user.dob : "");
  const [error, setError] = useState("");
  const [identification, setIdentification] = useState(typeof user.identification !== "undefined" ? user.identification : "");
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState("text");

  const genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const individualTitles = [
    {
      value: "Developer",
      label: "Developer",
    },
    {
      value: "UI/UX Designer",
      label: "UI/UX Designer",
    },
    {
      value: "Dev Ops",
      label: "Dev Ops",
    },
    {
      value: "Machine Learning",
      label: "Machine Learning",
    },
    {
      value: "Hacker",
      label: "Hacker",
    },
    {
      value: "Hipster",
      label: "Hipster",
    },
    {
      value: "Business Analyst",
      label: "Business Analyst"
    }
  ];

  function validateForm() {
    return (
      isNotEmpty(firstName) &&
      isNotEmpty(lastName) &&
      isNotEmpty(location) &&
      isNotEmpty(dob) &&
      isNotEmpty(gender.value) &&
      isNotEmpty(contactNumber) &&
      (!titles || (titles && titles.length > 0))
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      updateUser({
        firstName,
        lastName,
        userId: JSON.parse(Cookies.get("user"))._id,
        gender: gender.value,
        location,
        dob,
        //identification,
        contactNumber,
        titles: titles.map((i) => i.value),
      })
        .then(({ data }) => {
          console.log(data);
          Cookies.set("user", JSON.stringify(data));
          window.location = "/profile";
        })
        .catch((err) => {
          console.log(err);
          displayError(
            typeof err !== "undefined"
              ? err.response
              : "Failed to update profile. Please try again."
          );
        })
        .finally(setIsLoading(false));
    } else displayError("Please fill out all fields.");
  }

  function disableAccount() {
    setIsLoading(true);
    client.put("profile/disable", {
      userId: JSON.parse(Cookies.get("user"))._id,
    })
      .then(({ data }) => {
        Cookies.remove("user");
        window.location = "/";
      })
      .catch((err) => {
        console.log(err);
        displayError(
          typeof err !== "undefined"
            ? err.response
            : "Failed to disable account. Please try again."
        );
      })
      .finally(setIsLoading(false));
  }

  function displayError(_error) {
    setError(_error);
    setTimeout(() => {
      setError("");
    }, 5000);
  }

  function onDateChange(event) {
    var length = event.currentTarget.value.length;
    setInputType(length === 0 ? 'date' : 'date');
    let date = new Date(event.currentTarget.value);
    let now = new Date();
    console.log(date > now);
    if (date < now)
      setDob(event.currentTarget.value)
  }

  // window.$(document).on("change", "#dob", function (event, ui) {
  //   var now = new Date();
  //   var selectedDate = new Date(window.$(this).val());
  //   console.log(selectedDate > now);

  //   if (selectedDate > now) {
  //     setDob("")
  //     window.$(this).val(null);
  //   }
  //   // else {
  //   //   dateControler.currentDate = window.$(this).val();
  //   // }
  // });

  return (
    <div className="login-signin-container">
      <div className="login-wrapper col-lg-5 col-md-10 col-sm-8 mx-auto">
        <div
          className="col login-signup-form"
          style={{
            padding: "1.5rem 1.5rem",
            minHeight: "25rem"
          }}
        >
          <span>
            <img
              className=""
              src="/assets/images/skillsranker-grey.png"
              onClick={() => history.push("/")}
              alt="skills ranker logo"
              style={{
                cursor: "pointer",
                width: "40px",
                position: "absolute",
                left: "30px",
                top: "40px",
                float: "left",
              }}
            />{" "}
          </span>{" "}
          <h2
          >
            Update Profile
          </h2>{" "}
          <p> Update your profile information below </p>{" "}
          {isLoading ? (
            "Update account..."
          ) : (
            <form className="form-2 text-left " onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12"> {error !== "" ? <Error error={error} /> : ""} </div>{" "}
                <div className="mt-4 col-md-6">
                  <input
                    type="text"
                    className="login-signin-input form-control"
                    id="firstName"
                    onChange={(event) => setFirstName(event.target.value)}
                    required
                    placeholder="First Name"
                    value={firstName}
                  />
                </div>
                <div className="mt-4 col-md-6">
                  <input
                    onChange={(event) => setLastName(event.target.value)}
                    type="text"
                    className="login-signin-input form-control"
                    id="lastName"
                    required
                    placeholder="Last Name"
                    value={lastName}
                  />
                </div>
                {/* <div className="mt-4 col-md-6">
                  <input
                    type="number"
                    onChange={identification}
                    value={identification}
                    className="login-signin-input form-control"
                    id="identification"
                    alt="Please enter your identification"
                    required
                    placeholder="Omang/Passport"
                  />
                </div> */}

                <div className="mt-4 col-md-6">
                  <p className="form-control"
                      id="identification"
                      style={{ padding: "10px", backgroundColor: "transparent", border: "0px" }}>
                      {identification}</p>

                </div>


                <div className="mt-4 col-md-6">
                  <Select
                    style={{ padding: "0 !important", display: "contents !important" }}
                    options={genders}
                    onChange={setGender}
                    className="basic-multi-select b-0 p-0 form-control"
                    classNamePrefix="gender"
                    value={gender}
                    placeholder="Gender"
                  />
                </div>{" "}
                <div className="mt-4 col-md-6">
                  <input
                    type="date"
                    onFocus={onDateChange}
                    onChange={onDateChange}
                    value={dob}
                    className="login-signin-input form-control"
                    id="dob"
                    alt="Please enter your date of birth"
                    required
                    placeholder="Date of Birth"
                  />
                </div>
                <div className="mt-4 col-md-6">
                  <Select
                    options={individualTitles}
                    defaultValue={titles}
                    isMulti
                    onChange={setTitles}
                    className="basic-multi-select b-0 p-0 form-control"
                    styles={{ container: () => ({ padding: "0 !important", zIndex: 10000, display: "contents !important" }) }}
                    classNamePrefix="select"
                    placeholder="Title"
                  />
                </div>{" "}
                <div className="mt-4 col-md-6">
                  <input
                    type="location"
                    onChange={(event) => setLocation(event.target.value)}
                    className="login-signin-input form-control"
                    id="location"
                    required
                    placeholder="City, Town or Village"
                    value={location}
                  />
                </div>
                <div className="mt-4 col-md-6">
                  <IntlTelInput
                    preferredCountries={['bw']}
                    defaultCountry="267"
                    containerClassName="intl-tel-input"
                    inputClassName="login-signin-input form-control"
                    value={contactNumber}
                    onPhoneNumberChange={(isValid, newNumber, countryData, fullNumber, extension) => setContactNumber(fullNumber)}
                    style={{ zIndex: 0 }}
                    type="number"
                    placeholder="Mobile number"
                  />
                </div>
              </div>

              <div className="mt-4  text-center">
                <button
                  onClick={disableAccount}
                  className="btn btn-danger btn-rounded"
                  style={{ padding: "0.47rem 2.75rem !important" }}
                  type="submit"
                >
                  Delete Account{" "}
                </button>{" "}
                <button
                  className="btn login-btn btn-rounded waves-effect waves-light"
                  type="submit"
                >
                  Update Account{" "}
                </button>{" "}
              </div>{" "}
            </form>
          )}{" "}
        </div>{" "}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: JSON.parse(Cookies.get("user")),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userDetails) => dispatch(updateUser(userDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserAccount);
