import React, { Component } from "react";
import UserProjectList from "../../components/UserProjectList";
import UserTechnologiesList from "../../components/UserTechnologiesList";
import UserLanguagesList from "../../components/UserLanguagesList";
import { createBrowserHistory as history } from "history";
import { connect } from "react-redux";
import { getUserProfile } from "../../store/actions/usersActions";
import RankBadge from "../../components/RankBadge";
import { NavLink } from "react-router-dom";

import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";

class UserProfile extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentWillMount() {
    const userId = this.props.match.params.id;
    this.props.getUserProfile(userId).then((response) => {
      // this.state.userProfile = response;
      this.setState({
        userProfile: response,
        userBio: response.githubData
          ? response.githubData.bio
          : "No profile summary"
      });
      getLanguages(response.programmingLanguages)
        .then((resp) => {
          this.setState({ programmingLanguages: resp.data })
        })
      getTechnologies(response.technologies)
        .then((resp) => {
          this.setState({ technologies: resp.data })
        })
    });
  }

  render() {
    return (
      <div className="main-content">
        <div
          className="page-content"
          style={{
            marginTop: "50px",
          }}
        >
          <div className="container-fluid">
            {/* <!-- Top header --> */}
            <div className="row">
              <div className="col-xl-3">
                {/* avater */}
                <div className="card overflow-hidden">
                  <div className="bg-soft-secondary">
                    <div className="row">
                      <div className="col-10">
                        <div className="text-primary p-3"> </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0 pb-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="avatar-lg profile-user-wid mb-4">
                              <img
                                src={
                                  typeof this.state.userProfile !==
                                    "undefined" &&
                                    this.state.userProfile.githubData
                                    ? this.state.userProfile.githubData
                                      .avatar_url
                                    : "/assets/images/users/avatar.png"
                                }
                                alt=""
                                className="img-thumbnail rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            {this.state.userProfile ? (
                              <RankBadge userId={this.state.userProfile._id} />
                            ) : (
                                ""
                              )}
                          </div>
                        </div>
                        <h5 className="font-size-15 text-truncate text-dark">
                          {(typeof this.state.userProfile !== "undefined"
                            ? this.state.userProfile.name.firstName
                            : "") +
                            " " +
                            (typeof this.state.userProfile !== "undefined"
                              ? this.state.userProfile.name.lastName
                              : "")}
                        </h5>
                        {typeof this.state.userProfile !== "undefined"
                          ? this.state.userProfile.titles.map(title => (
                            <p
                              className="text-muted mb-0 text-truncate text-primary"
                              style={{ textTransform: "capitalize" }}
                            >
                              {title}
                            </p>))
                          : ""}



                        <p
                          className="text-muted text-truncate"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >

                        </p>
                      </div>

                    </div>
                  </div>
                </div>
                {/* PROJECTS */} {/* pass project data objects as a prop */}
                {this.state.userProfile ? (
                  <UserProjectList
                    user={this.state.userProfile}
                    history={history}
                  />
                ) : (
                    ""
                  )}
              </div>
              <div className="col-xl-9">
                <div className="card pl-3 pr-1">
                  <div className="card-body">
                    <div className="invoice-title">
                      <h4
                        className="card-title"
                        style={{
                          color: "#000",
                        }}
                      >
                        SUMMARY
                      </h4>
                    </div>
                    <div className="row pl-3 pr-5 pt-3">
                      <p
                        className="card-text pr-2"
                        style={{
                          color: "#000",
                        }}
                      >
                        {this.state.userBio
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <UserLanguagesList
                  userLanguages={
                    this.state.programmingLanguages
                      ? this.state.programmingLanguages
                      : []
                  }
                />
                <UserTechnologiesList
                  userTechnologies={
                    this.state.technologies
                      ? this.state.technologies
                      : []
                  }
                />
                {/* <AssesmentsList /> */}
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                {new Date().getFullYear()}© Botswana Innovation Hub
              </div>
              <div
                className="col-sm-6"
                style={{
                  marginTop: "-7px",
                }}
              >
                <div className="text-sm-right d-none d-sm-block" >
                  Skills Ranker
                  <NavLink
                    to="/profile/leaderboard"
                    className="btn btn-primary ml-5"
                    style={{
                      position: 'fixed', bottom: '70px', right: '40px', background: '#FFC40C', borderColor: '#FFC40C',
                      color: 'black', fontWeight: '600'
                    }}
                  >
                    <span className="fa fa-arrow-back"> </span> Back to Leaderboard
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.admin.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: (userId) => dispatch(getUserProfile(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
