import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import Header from "../partials/Header"
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchAppUsers,
  fetchPlatformSummary,
} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";
import { Overlay } from "react-bootstrap";




class LandingPage extends Component {
  

  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css"/>
        <link rel="stylesheet" href="/assets/landing-page/css/magnific-popup.css"/>
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        <link rel="stylesheet" href="/myMenu.css" />

   
        <Header />

        <main>
          <section
            id="parallax"
            className=""
            style={{
              height:"75%",
              background: "url(/assets/landing-page/images/25332.jpg)",
              backgroundRepeat:"no-repeat",
              backgroundSize:"cover"}}>
            <div className="slider-shape ss-one layer" data-depth="0.10">
            </div>
            <div className="slider-shape ss-two layer" data-depth="0.30">
            </div>
            <div className="slider-shape ss-three layer" data-depth="0.40">
            </div>
            <div className="slider-shape ss-four layer" data-depth="0.60">
            </div>
            <div className="slider-shape ss-five layer" data-depth="0.20">
            </div>
            <div className="slider-shape ss-six layer" data-depth="0.15">
            </div>
            <div className="slider-shape ss-eight layer" data-depth="">
            </div>
            <div className="slider-active">
              <div className="single-slider">
                <div className="container">
                  <div className="row">
                    <div className="col-8">
                      <div className="slider-content second-slider-content">
                        <h2 style={{marginTop:"100px"}} data-animation="fadeInUp animated" data-delay=".4s">
                          <span>AWS re/Start</span>
                        </h2>
                        <h2 style={{marginTop:"-20px", marginBottom:"100px", fontSize:"45px"}} data-animation="fadeInUp animated" data-delay=".4s">
                          Everything you need to know
                        </h2> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            style={{ alignItems: "center", minHeight: "80vh" }}
            id="how-it-works"
            className="about-area about-p p-relative d-flex">
            <div className="container d-flex">
              <div className="row align-items-center">
                <div className="">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s">
                      </div>
                      <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                        style={{ color: "rgb(140,142,94)" }}>
                        <br/>Preparing individuals for cloud careers with free to the learner cohort-based training
                      </span>
                      <h2 
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s"
                      >
                       <br/> AWS re/Start
                      </h2>
                      <h5
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s"
                      >
                        Helping people launch careers in the cloud
                      </h5>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s">
                      <p>
                      AWS re/Start is a cohort-based workforce development training program that prepares individuals for careers in the cloud and connects them to potential employers. A technology background is not required to apply. The program is free to the learner and focused on helping unemployed or underemployed individuals launching a new career. AWS re/Start’s collaborating organizations support underrepresented groups, minorities, displaced individuals, young people, and more.

                      <br/> <br/>Through coursework and hands-on labs, using real world scenarios, learners can gain the technical skills they need for entry to mid-level cloud roles. AWS re/Start also provides learners with professional skills training like resume and interview coaching to prepare them for employer meetings and job interviews.
                      </p>
                      <h5
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s">
                        About the curriculum
                      </h5>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s">
                      <p>
                      AWS re/Start curriculum is adapted to meet learners where they are in their learning journey, desired objectives, and learning modalities. Through scenario-based exercises, instructor-led sessions, learners build Linux, Python, networking, security, database, automation, and core AWS Cloud skills.
 
                      <br/><br/>Learners receive job-specific training for roles such as technical support specialist, systems administrator, cloud automation lead, up to infrastructure engineer and more. AWS re/Start also provides learners with professional skills including effective communication, collaboration, resume writing, and interview coaching to prepare them for employer meetings and interviews.
 
                      </p>
                      <h5
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s">
                        Learner characteristics:
                      </h5>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s">
                      <p>
                        Note that learner qualifications can differ depending on location and collaborating organization. For more information about learner qualifications and applications, see the How to apply for AWS re/Start section to click through to the learner application sites organized by country. The program is offered at no cost to learners.
                        <br/><br/>Availability to attend training sessions as and when required
                        <br/>Ability to apply for and pursue a new job after course completion
                        <br/>High school O-Level
                      </p> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
    <script>
    let _isLoaded = false;
  </script>
  }
  

}


const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));
