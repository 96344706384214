import React from "react";
import LandingPage from "../pages/LandingPage";
export default function Header() {
  return (
    <header id="home" className="header-area">
    <div id="header-sticky" className="menu-area">
      <div className="container">
        <div className="second-menu">
          <div className="row align-items-center">
            <div className="col-xl-3 col-lg-2">
              <div className="logo">                
                <a href="/#">
                  <img src="/assets/landing-page/images/logo_white.png"
                    alt="logo"
                    style={{
                      width: "33%",
                      opacity: "100%",
                      padding: "10px",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="col-xl-6 col-lg-2">
              <div
                className="responsive"
                onClick={() => this.toggleMenu()}
              >
                <i className="icon dripicons-align-right" />
              </div>
              <div className="main-menu text-right text-xl-center">
                
                
                
                <nav id="mobile-menu">
                  <ul>
                    <li className="active has-sub ml-sm-4">
                      <a href="/#">Home </a>
                    </li>
                    {/* <li className="has-sub font-weight-bolder ml-sm-4">
                      <a href="#about">About</a>
                    </li> */}
                    <li className="has-sub font-weight-bolder ml-sm-4">
                      <a href="/#how-it-works">Developer</a>
                    </li>
                    <li className=" ml-sm-4">
                      <a href="/#how-it-works-business">Business</a>
                    </li>
                   
                    <li className="ml-sm-4 has-sub">
                      <a href="/events">Hackathons</a>
                      {/* <ul className="dropdown">
                        <li className="ml-sm-4"><a href="/plannedHackathons">Planned Hackathons</a></li>
                      </ul> */}
                    </li>
                      {/* <li className=" ml-sm-4">
                      <a href="/awsRegistration"></a>
                     </li> */}


                    {/* <li className="ml-sm-4 has-sub"><a href="#">Learnership</a>
                    <ul className="dropdown">
                      <li className="ml-sm-4"><a href="/aboutAWS">AWS-re/Start</a></li>
                    </ul>
                    </li> */}

                    <li className="ml-sm-4 has-sub"><a href="/#">Learnership</a>
                     <ul className="dropdown">
                      <li className="ml-sm-4 has-sub"><a href="/aboutAWS">AWS-re/Start</a>
                        {/* <ul className="dropdown">
                        <li className="ml-sm-4"><a href="/aboutAWS">About AWS-re/Start</a></li>
                        <li className="ml-sm-4"><a href="/awsRequirements">Requirements</a></li>
                        <li className="ml-sm-4"><a href="/awsRegistration">AWS-re/Start Registration</a></li>
                        </ul> */}
                      </li>
                     </ul>
                  </li>

                    <li className=" ml-sm-4">
                      <a href="#footer">Contacts</a>
                    </li>

                    </ul>                        
                </nav>




              </div>
            </div>
            <div className="col-xl-3 text-right d-none d-xl-block">
              <div className="header-btn second-header-btn">
                <a
                  href={LandingPage.user ? "/profile" : "/login"}
                  className="btn"
                >
                  <i className="fa fa-user-alt" />{" "}
                  {LandingPage.user ? "Profile" : "Login"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  )}

