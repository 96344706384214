import React, { Component } from "react";
import socketClusterClient from "socketcluster-client";
import { Howl, Howler } from "howler";

class DiscussionTest extends Component {
  state = {
    messages: [{ message: "", likes: 0, dislikes: 0 }],
    message: "",
    socketId: null,
    lastNotified: null,
  };
  socket = null;
  audio = null;

  debug(msg) {
    console.log(msg);
  }

  warning(msg) {
    console.log(msg);
  }

  constructor(props) {
    super(props);
    this.audio = new Howl({ src: "/assets/sounds/Notification 1.m4a" });
    this.socket = socketClusterClient.create({
      hostname: "192.168.1.14",
      port: 8081,
    });
    this.handleClick = this.handleClick.bind(this);

    (async () => {
      for await (let event of this.socket.listener("connect")) {
        console.log("Socket is connected");
        this.setState({ socketId: event.id });
      }
      for await (let event of this.socket.listener("error")) {
        console.log("Error");
      }
    })();
  }

  async componentDidMount() {
    // this.setState({
    //     messages: this.props.messages,
    // });
    // Subscribe to a channel.
    let discussionChannel = this.socket.subscribe("event");
    for await (let data of discussionChannel) {
      if (this.state.socketId !== data.socketId) {
        // notify once in 3 minutes
        if (
          this.state.lastNotified == null ||
          new Date().getTime() - this.state.lastNotified.getTime() > 60 * 60 * 3
        ) {
          this.audio.play();
          this.setState({
            lastNotified: new Date(),
          });
        }
        this.setState({
          messages: [
            ...this.state.messages,
            { message: data.message, likes: 0, dislikes: 0 },
          ],
        });
      }
    }
  }

  componentWillUnmount() {
    this.socket.unsubscribe("event", (x) => {
      console.log(x);
    });
  }

  handleClick(event) {
    event.preventDefault();
    this.socket.transmitPublish("event", {
      socketId: this.state.socketId,
      message: this.state.message,
    });

    this.setState({
      message: "",
      messages: [
        ...this.state.messages,
        { message: this.state.message, likes: 0, dislikes: 0 },
      ],
    });
  }

  render() {
    return (
      <div className="container">
        <div className="d-flex" style={{ height: "10vh" }}>
          <h1 style={{ alignSelf: "center" }}>Discussion Board</h1>
        </div>
        <div>
          <div
            style={{
              bottom: "10vh",
              top: "5vh",
              left: "0",
              right: "0",
              height: "80vh",
            }}
          >
            {this.state.messages ? (
              this.state.messages.map((message, index) => (
                <div key={index}>{message.message}</div>
              ))
            ) : (
              <p className="text-center">No messages yet</p>
            )}
          </div>
          <div
            className="d-flex"
            style={{ height: "10vh", alignItems: "center" }}
          >
            <input
              className="form-control"
              onChange={(event) =>
                this.setState({ message: event.target.value })
              }
              style={{ height: "2.3rem" }}
              value={this.state.message}
              placeholder="Type message here..."
            />
            <div
              className="btn btn-primary"
              style={{ height: "2.3rem" }}
              onClick={this.handleClick}
            >
              Send
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DiscussionTest;
