const initState = {
  languagesList: [],
  allLanguages: [],
};

const languagesReducer = (state = initState, action) => {
  switch (action.type) {
    case "PULL_LANGUAGES":
      return {
        ...state,
        languagesList: Array.isArray(action.data) ? action.data : state.languagesList
      };

    case "PULL_ALL_LANGUAGES":
      return {
        ...state,
        allLanguages: action.data
      };

    case "UPDATE_LANGUAGES":
      return {
        ...state,
        allLanguages: state.allLanguages.concat([action.data])
      };
    case "CREATE_LANGUAGE":
      return {
        ...state,
        allLanguages: state.allLanguages.concat([action.data])
      };
    
    default:
      break;
  }
  return state;
};

export default languagesReducer;
