import React, { useState, useEffect } from "react";
import { isValidEmail } from "../../utils/utils";
import { resetUserPassword } from "../../store/actions/authActions";
import { connect } from "react-redux";

function PasswordRecovery2(props) {
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var token = props.history.location.pathname.split("/")[2];
    setToken(token)
    if (token) {

    } else {
      props.history.push("/")
    }
  });

  function validateForm() {
    return password === confirmPassword;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      props
        .resetUserPassword({ 'token': token, 'password': password, 'confirmPassword': confirmPassword })
        .then(() => {
          setSuccess(
            "Password successfully reset. "
          );
          props.history.push("/login")
        }
        )
        .catch(err => {
          console.log(err);

          displayError(
            err.response
              ? err.response.data.message
              : "Failed to reset password"
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      displayError("Passwords don't match");
    }
  }

  function displayError(_error) {
    setError(_error);
    setTimeout(() => {
      setError("");
    }, 5000);
  }

  return (

    <div className="login-signin-container">
      <div className=" login-wrapper" >
        {isLoading ? <p className="col login-signup-form">Loading...</p> : <div className="col login-signup-form" >
          <h2 style={{ marginTop: '2rem' }}>Reset Password</h2>
          <form style={{ padding: '3rem 5rem' }} onSubmit={handleSubmit}>
            <div className="mb-4">
              <input id="userpassword" className="login-signin-input form-control" type="password" required placeholder="Enter password" onChange={(event) => setPassword(event.target.value)} />
            </div>
            <div className="mb-4">
              <input className=" login-signin-input form-control" type="password" required placeholder="Confirm Password" onChange={(event) => setConfirmPassword(event.target.value)} />
            </div>

            <div className="mt-3">
              <button type="submit" className=" login-btn btn btn-rounded waves-effect waves-light" >Reset password</button>
            </div>

          </form>

        </div>}
        <div className="signup-message d-none d-md-block" >
          <div className="signup-message-wrapper">
            <div className="mb-4">
              <h1 style={{ color: 'white', textTransform: 'capitalize' }}>Are you an innovator</h1>
            </div>
            <div className="mb-4">
              <p>Create an account and rank your skills</p>
            </div>
            <div className="mt-3">
              <button type="button" onClick={() => props.history.push("/register")} className="signup-1-btn btn btn-rounded waves-effect waves-light" >SIGN UP</button>
            </div>
            <div className="mt-5 text-center">

              <p>
                {new Date().getFullYear()} © Botswana Innovation Hub.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetUserPassword: info => dispatch(resetUserPassword(info))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery2);
