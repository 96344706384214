import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

import {
  validateBusiness
} from "../store/actions/businessProfileActions";
import Cookie from "js-cookie";
import ReactHtmlParser from "react-html-parser";
import { BASE_URL } from "../api/client";
import { formatDate } from "../utils/utils";
import BusinessCreateJobPost from "./BusinessCreateJobPost";
import JobApplicantList from "./JobApplicantList";
import BusinessJobPosts from "./BusinessJobPosts";
import BusinessApplicantDetails from "./BusinessApplicantDetails";
import Error from "../containers/partials/Error";
import { Document } from '@react-pdf/renderer';


class BusinessProfileDetails extends Component {
  constructor(...props) {
    super(...props);
  }

  state = {
    isEditingProfile: false,
    profile: {},
    isUpdatingProfile: false,
    isEditingContactPerson: false,
    isUpdatingContact: false,
    isSendingEmailInvite: false,
    isCreatingNewProfile: false,
    fileToUpdate: null,
    showPreview: false,
    uploadTag: "",
    inviteEmail: "",
    complianceFile: {},
    complianceTag: "",
    isUploading: false,
    isUploadComplete: false,
    isUploadFailed: false,
    jobState: "listings",
    jobId: "",
    job: {},
    applicant: "",
    user: {},
    nameMsg: "",
    error: "",
    isNameValid: false
  };

  componentDidMount() {
    this.setState({ user: JSON.parse(Cookies.get("user")) });
  }

  navigateToCreation = (intent, job = {}) => {
    this.setState({ jobState: "creation", intent: intent, job: job })
  }

  navigateToApplicants = (job) => {
    this.setState({ jobState: "applicants", jobId: job })
  }

  navigateToApplicant = (applicant) => {
    // console.log(applicant)
    this.setState({ jobState: "applicant", applicant: applicant })
  }
  previewFile = () => { }

  navigateToJobListings = () => {
    this.setState({ jobState: "listings" })
  }
  toggleEdit(state) {
    this.setState({ ...state, profile: this.props.selectedProfile });
  }
  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }

  handleIndustryChange(e) {
    let profile = this.state.profile;
    profile.industry = e;
    this.setState({ profile: profile });
  }

  handleNameChange(e) {
    let profile = this.state.profile;
    profile.businessName = e;

    this.setState({ profile: profile });
    validateBusiness(e)
      .then(info => {
        this.setState({ isNameValid: !info.exists, nameMsg: info.message })
        if (info.exists || profile.businessName.length < 1) this.displayError("Please provide a unique business name");
      })
  }

  updateDoc(fileToUpdate) {
    console.log(fileToUpdate);
    this.setState({
      fileToUpdate
    })
    window.$("#file-upload-modal").modal("show");
  }

  handleRegNumberChange(e) {
    let profile = this.state.profile;
    profile.registrationNumber = e;
    this.setState({ profile: profile });
  }

  handleDescriptionChange(e) {
    let profile = this.state.profile;
    profile.businessDescription = e;
    this.setState({ profile: profile });
  }

  handleNumberChange(e) {
    let profile = this.state.profile;
    profile.number = e;
    this.setState({ profile: profile });
  }

  handleLocationChange(e) {
    let profile = this.state.profile;
    profile.location.description = e;
    this.setState({ profile: profile });
  }

  handleProfileUpdate() {
    this.setState({ isUpdatingProfile: true });
    this.props
      .editProfile(this.state.profile)
      .then(() => {
        this.setState({
          profile: this.props.selectedProfile,
          isUpdatingProfile: false,
          isEditingProfile: false,
        });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({ isUpdatingProfile: false });
      });
  }
  handleAccountInvite = (e) => {
    e.preventDefault();
    // trigger invite Animation
    this.props
      .initInvite(this.state.inviteEmail, this.props.selectedProfile._id)
      .then(() => {
        //do something
        // stop invite anim
      })
      .finally(() => {
        window.$("#member-invite-modal").modal("hide");
      });
  };

  uploadFile = () => {
    this.setState({ isUploading: true });

    this.props
      .pushFile({
        profileId: this.props.selectedProfile._id,
        compliance: this.state.complianceTag,
        file: this.state.complianceFile.file,
        uploadedBy: JSON.parse(Cookie.get("user"))._id,
        fileName: this.state.complianceFile.fileName,
        fileToUpdate: this.state.fileToUpdate
      })
      .then(() => {
        this.setState({
          isUploadFailed: false,
          isUploading: false,
          isUploadComplete: true,
        });

        // window.$("#file-upload-modal").modal("hide");
      })
      .catch((error) => {
        this.setState({
          isUploading: false,
          isUploadFailed: true,
          isUploadComplete: false,
        });
      })
      .finally(() => {
        setTimeout(() => {
          window.$("#file-upload-modal").modal("hide");
          this.setState({
            isUploadComplete: false,
            isUploadFailed: false,
            isUploading: false,
          });
        }, 3000);
        //
      });
  };

  getBase64(file, cb) {
    if (!file) return;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log("Error: ", error);
    };
  }

  processFile(event, ctx) {
    let file = event.target.files[0];
    console.log(JSON.stringify(file));

    ctx.getBase64(file, (result) => {
      ctx.setState({
        complianceFile: {
          file: result,
          fileName: file.name,
          path: file.path,
        },
      });
    });
  }

  bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  handleContactUpdate(data) {
    this.setState({ isUpdatingContact: true });
    data = { ...data, userId: data._id };
    delete data._id;
    delete data.email;
    this.props
      .editContactPerson(data)
      .then(() => {
        this.setState({
          profile: this.props.selectedProfile,
          isUpdatingContact: false,
          isEditingContactPerson: false,
        });
      })
      .catch((err) => {
        this.setState({ isUpdatingContact: false });
        // console.log(err);
      });
  }

  handleCFNameChange(e) {
    let profile = this.state.profile;
    profile.createdBy.name.firstName = e;
    this.setState({ profile: profile });
  }

  handleCLNameChange(e) {
    let profile = this.state.profile;
    profile.createdBy.name.lastName = e;
    this.setState({ profile: profile });
  }

  handleCNumberChange(e) {
    let profile = this.state.profile;
    profile.createdBy.contactNumber = e;
    this.setState({ profile: profile });
  }

  handleComplianceTagChanges(tag) {
    this.setState({ complianceTag: tag });
  }
  handleCEmailChange(e) {
    let profile = this.state.profile;
    profile.createdBy.email = e;
    this.setState({ profile: profile });
  }

  displayError = (_error) => {
    this.setState({ error: _error });
    setTimeout(() => {
      this.setState({ error: "" });
    }, 5000);
  }

  render() {
    window.onresize = function () {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div className="row">
        <div className="col-sm-12 mb-3">
          <div style={{ display: "flex", float: "right" }}>
            <div className="text-sm-right">
              <button
                type="button"
                onClick={() => {
                  this.props.triggerProfileCreation();
                }}
                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                style={{
                  backgroundColor: "#e2b537",
                  color: "black",
                  borderColor: "#e2b537",
                  fontWeight: "bold",
                }}
              >
                <i className="mdi mdi-plus mr-1"></i> New Profile
              </button>
            </div>
            <div className="text-sm-right">
              <button
                type="button"
                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                style={{
                  backgroundColor: "#e2b537",
                  color: "black",
                  borderColor: "#e2b537",
                  fontWeight: "bold",
                }}
                onClick={() =>
                  this.props.profileStateCallback({ isProfileSelected: false })
                }
              >
                Other Profiles
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-2">
          <div
            className="nav flex-column nav-pills"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <a
              className="nav-link mb-2 active"
              style={{
                borderBottom: "solid 1px rgb(172 175 177)",
                borderRadius: "0px",
              }}
              id="v-pills-description-tab"
              data-toggle="pill"
              href="#v-pills-profile"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="true"
            >
              Business Profile
            </a>
            <a
              className="nav-link mb-2"
              style={{
                borderBottom: "solid 1px rgb(172 175 177)",
                borderRadius: "0px",
              }}
              id="v-pills-members-tab"
              data-toggle="pill"
              href="#v-pills-members"
              role="tab"
              aria-controls="v-pills-members"
              aria-selected="false"
            >
              Business members
            </a>
            <a
              className="nav-link mb-2"
              style={{
                borderBottom: "solid 1px rgb(172 175 177)",
                borderRadius: "0px",
              }}
              id="v-pills-events-tab"
              data-toggle="pill"
              href="#v-pills-events"
              role="tab"
              aria-controls="v-pills-events"
              aria-selected="false"
            >
              My Events
            </a>
            {/* <a
              className="nav-link mb-2"
              style={{
                borderBottom: "solid 1px rgb(172 175 177)",
                borderRadius: "0px",
              }}
              onClick={() => this.setState({ jobState: "listings" })}
              id="v-pills-jobs-tab"
              data-toggle="pill"
              href="#v-pills-jobs"
              role="tab"
              aria-controls="v-pills-jobs"
              aria-selected="false"
            >
              Posted Jobs
            </a> */}
          </div>
        </div>
        <div className="col-md-9">
          <div
            className="tab-content text-muted  mt-md-0"
            id="v-pills-tabContent"
            style={{
              borderLeft: "solid 1px rgb(172 175 177)",
              padding: "0rem 1rem 1rem 1rem",
              minWidth: "70vw",
            }}
          >
            <div
              className="tab-pane active show fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <div className="row">
                <div className="col-lg-8">
                  {this.state.isEditingProfile ? (
                    <div className="card">
                      <div className="card-body">
                        {this.state.isUpdatingProfile ? (
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              left: 0,
                              top: 0,
                              zIndex: 3,
                              height: "100%",
                              background: "#fffbfb3d",
                              textAlign: "center",
                              backdropFilter: "blur(3px)",
                            }}
                          >
                            <p style={{ top: "50%", fontWeight: "bold" }}>
                              {" "}
                              updating your profile ...{" "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="row">
                          <div className="col-sm-9">
                            <div className="media  form-vertical">
                              <div className="media-body overflow-hidden form-group">
                                {this.state.error !== "" ? <Error error={this.state.error} /> : ""}{" "}
                                <label>Business Name : </label>
                                <input
                                  className="form-control mb-2"
                                  onChange={(e) =>
                                    this.handleNameChange(e.target.value)
                                  }
                                  type="text"
                                  placeholder="Business name"
                                  value={this.state.profile.businessName}
                                />

                                <label>Industry : </label>
                                <input
                                  className="form-control"
                                  onChange={(e) =>
                                    this.handleIndustryChange(e.target.value)
                                  }
                                  type="text"
                                  value={this.state.profile.industry}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="text-sm-right">
                              <button
                                type="button"
                                style={{
                                  backgroundColor: "#e2b537",
                                  color: "black",
                                  borderColor: "#e2b537",
                                  fontWeight: "bold",
                                }}
                                onClick={() => this.handleProfileUpdate()}
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                              >
                                {" "}
                                Save Changes
                              </button>
                              <button
                                type="button"
                                style={{
                                  backgroundColor: "#e2b537",
                                  color: "black",
                                  borderColor: "#e2b537",
                                  fontWeight: "bold",
                                }}
                                onClick={() =>
                                  this.toggleEdit({
                                    isEditingProfile:
                                      !this.state.isEditingProfile,
                                  })
                                }
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                              >
                                Cancel{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* <label>Company Registration Number : </label>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) =>
                            this.handleRegNumberChange(e.target.value)
                          }
                          value={this.state.profile.registrationNumber}
                        /> */}
                        <label>Business description : </label>
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) =>
                            this.handleDescriptionChange(e.target.value)
                          }
                          value={this.state.profile.businessDescription}
                        />

                        <div className="table-responsive mt-4">
                          <table className="table table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">
                                  {" "}
                                  <label>Phone :</label>
                                </th>
                                <td>
                                  {" "}
                                  <input
                                    className="form-control"
                                    type="number"
                                    onChange={(e) =>
                                      this.handleNumberChange(e.target.value)
                                    }
                                    value={this.state.profile.phoneNumber}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  {" "}
                                  <label>Location : </label>
                                </th>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) =>
                                      this.handleLocationChange(e.target.value)
                                    }
                                    value={
                                      this.state.profile.location.description
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-9">
                            <div className="media">
                              <div className="media-body overflow-hidden">
                                <h5
                                  className="text-truncate font-size-15"
                                  style={{ color: "#495057" }}
                                >
                                  {this.props.selectedProfile.businessName
                                    ? this.props.selectedProfile.businessName
                                    : "-"}
                                </h5>
                                <p className="text-muted">
                                  {this.props.selectedProfile.industry
                                    ? this.props.selectedProfile.industry
                                    : "-"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="text-sm-right">
                              <button
                                style={{
                                  backgroundColor: "#e2b537",
                                  color: "black",
                                  borderColor: "#e2b537",
                                  fontWeight: "bold",
                                }}
                                type="button"
                                onClick={() =>
                                  this.toggleEdit({
                                    isEditingProfile:
                                      !this.state.isEditingProfile,
                                  })
                                }
                                className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                              >
                                <i className="mdi mdi-plus mr-1"></i> Edit
                                Profile
                              </button>
                            </div>
                          </div>
                        </div>

                        <h5
                          className="font-size-15 mt-4"
                          style={{ color: "#495057" }}
                        >
                          Business description :
                        </h5>

                        <p className="text-muted">
                          {this.props.selectedProfile.businessDescription
                            ? this.props.selectedProfile.businessDescription
                            : "-"}
                        </p>

                        <div className="table-responsive mt-4">
                          <table className="table table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Registration Number :</th>
                                <td>
                                  {this.props.selectedProfile.registrationNumber
                                    ? this.props.selectedProfile
                                      .registrationNumber
                                    : "not provided"}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Phone :</th>
                                <td>
                                  {this.props.selectedProfile.phoneNumber
                                    ? this.props.selectedProfile.phoneNumber
                                    : "not provided"}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Location :</th>
                                <td>
                                  {this.props.selectedProfile.location
                                    .description
                                    ? this.props.selectedProfile.location
                                      .description
                                    : "not provided"}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Registration Date : </th>
                                <td>
                                  {formatDate(
                                    this.props.selectedProfile.createdAt
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-sm-4">
                          <div className="search-box mr-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <h4
                                className="card-title mb-4"
                                style={{ color: "#495057" }}
                              >
                                Document library
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-8">
                          <div className="text-sm-right">
                            <button
                              type="button"
                              data-toggle="modal"
                              data-target="#file-upload-modal"
                              onClick={() => this.setState({ fileToUpdate: null })}
                              style={{
                                backgroundColor: "#e2b537",
                                color: "black",
                                borderColor: "#e2b537",
                                fontWeight: "bold",
                              }}
                              className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                            >
                              <i className="mdi mdi-plus mr-1"></i> Upload New
                              File
                            </button>
                          </div>
                          <div
                            className="modal fade bs-example-modal-center"
                            id="file-upload-modal"
                            style={{ backgroundColor: "#00000080" }}
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="mySmallModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content  modal-lg">
                                {this.state.isUploading ? (
                                  <div className="">
                                    <p className="text-muted">Uploading file</p>
                                    <p className="text-muted text-center mb-3">
                                      {this.bytesToSize(
                                        this.props.uploadProgress.uploaded
                                      ) +
                                        " of " +
                                        this.bytesToSize(
                                          this.props.uploadProgress.total
                                        )}
                                    </p>
                                    <div
                                      className="progress"
                                      style={{ width: "90%" }}
                                    >
                                      <div
                                        className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                                        role="progressbar"
                                        aria-valuenow={
                                          this.props.uploadProgress.percent
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width:
                                            this.props.uploadProgress.percent +
                                            "%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                          }}
                                        >
                                          {this.props.uploadProgress.percent +
                                            "%"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : this.state.isUploadComplete ? (
                                  <div className="">
                                    <div
                                      style={{
                                        width: "80px",
                                        height: "80px",
                                        borderWidth: " 4px",
                                        borderStyle: "solid",
                                        borderColor: "rgb(76, 174, 76)",
                                        borderRadius: "50%",
                                        margin: "20px auto",
                                        position: "relative",
                                        boxSizing: "content-box",
                                      }}
                                    >
                                      <div
                                        style={{
                                          borderRadius: "120px 0px 0px 120px",
                                          position: "absolute",
                                          width: "60px",
                                          height: "100px",
                                          background: "white",
                                          transform: "rotate(-45deg)",
                                          top: "-7px",
                                          left: "-33px",
                                          transformOrigin: "60px 60px",
                                        }}
                                      ></div>
                                      <span
                                        style={{
                                          height: "5px",
                                          backgroundColor: "rgb(92, 184, 92)",
                                          display: "block",
                                          borderRadius: "2px",
                                          position: "absolute",
                                          zIndex: 2,
                                          width: "25px",
                                          left: "14px",
                                          top: "46px",
                                          transform: "rotate(45deg)",
                                          animation:
                                            "0.75s ease 0s 1 normal none running animateSuccessTip",
                                        }}
                                      ></span>
                                      <span
                                        style={{
                                          height: "5px",
                                          backgroundColor: "rgb(92, 184, 92)",
                                          display: "block",
                                          borderRadius: "2px",
                                          position: "absolute",
                                          zIndex: 2,
                                          width: "47px",
                                          right: "8px",
                                          top: "38px",
                                          transform: "rotate(-45deg)",
                                          animation:
                                            "0.75s ease 0s 1 normal none running animateSuccessLong",
                                        }}
                                      ></span>
                                      <div
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                          border:
                                            "4px solid rgba(92, 184, 92, 0.2)",
                                          borderRadius: "50%",
                                          boxSizing: "content-box",
                                          position: "absolute",
                                          left: "-4px",
                                          top: "-4px",
                                          zIndex: 2,
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          width: "5px",
                                          height: "90px",
                                          backgroundColor: "rgb(255, 255, 255)",
                                          position: "absolute",
                                          left: "28px",
                                          top: "8px",
                                          zIndex: 1,
                                          transform: "rotate(-45deg)",
                                        }}
                                      ></div>
                                      <div
                                        style={{
                                          bordeRadius: "0px 120px 120px 0px",
                                          position: "absolute",
                                          width: "60px",
                                          height: "120px",
                                          background: "white",
                                          transform: "rotate(-45deg)",
                                          top: "-11px",
                                          left: "30px",
                                          transformOrigin: "0px 60px",
                                          animation:
                                            "4.25s ease-in 0s 1 normal none running rotatePlaceholder",
                                        }}
                                      ></div>
                                    </div>
                                    <h4
                                      style={{
                                        position: "relative",
                                        color: "#495057",
                                        maxWidth: "100%",
                                        margin: "0px 0px 0.4em",
                                        padding: "0px",
                                        fontWeight: "600",
                                        textAlign: "center",
                                        textTransform: "none",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      Upload completed
                                    </h4>
                                    <p className="text-muted text-center mb-3">
                                      {this.bytesToSize(
                                        this.props.uploadProgress.uploaded
                                      ) +
                                        " of " +
                                        this.bytesToSize(
                                          this.props.uploadProgress.total
                                        )}
                                    </p>
                                    <div
                                      className="progress"
                                      style={{ width: "90%" }}
                                    >
                                      <div
                                        className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                                        role="progressbar"
                                        aria-valuenow={
                                          this.props.uploadProgress.percent
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width:
                                            this.props.uploadProgress.percent +
                                            "%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                          }}
                                        >
                                          {this.props.uploadProgress.percent +
                                            "%"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : this.state.isUploadFailed ? (
                                  <>
                                    <div
                                      style={{
                                        width: "80px",
                                        height: "80px",
                                        borderWidth: "4px",
                                        borderStyle: "solid",
                                        borderColor: "rgb(238, 162, 54)",
                                        borderRadius: "50%",
                                        margin: "20px auto",
                                        position: "relative",
                                        boxSizing: "content-box",
                                        animation:
                                          "0.75s ease 0s infinite alternate none running pulseWarning",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          width: "5px",
                                          height: "47px",
                                          left: "50%",
                                          top: "10px",
                                          borderRadius: "2px",
                                          marginLeft: "-2px",
                                          backgroundColor: "rgb(240, 173, 78)",
                                          animation:
                                            "0.75s ease 0s infinite alternate none running pulseWarningIns",
                                        }}
                                      ></span>
                                      <span
                                        style={{
                                          position: "absolute",
                                          width: "7px",
                                          height: "7px",
                                          borderRadius: "50%",
                                          marginLeft: "-3px",
                                          left: "50%",
                                          bottom: "10px",
                                          backgroundColor: "rgb(240, 173, 78)",
                                          animation:
                                            "0.75s ease 0s infinite alternate none running pulseWarningIns",
                                        }}
                                      ></span>
                                    </div>
                                    <h4
                                      style={{
                                        position: "relative",
                                        maxWidth: "100%",
                                        textAlign: "center",
                                        textTransform: "none",
                                        overflowWrap: "break-word",
                                      }}
                                    >
                                      File upload failed
                                    </h4>
                                    <p className="text-muted text-center mb-3">
                                      {this.bytesToSize(
                                        this.props.uploadProgress.uploaded
                                      ) +
                                        " of " +
                                        this.bytesToSize(
                                          this.props.uploadProgress.total
                                        )}
                                    </p>

                                    <div
                                      className="progress"
                                      style={{ width: "90%" }}
                                    >
                                      <div
                                        className="progress-bar progress-bar-animated bg-primary progress-bar-striped"
                                        role="progressbar"
                                        aria-valuenow={
                                          this.props.uploadProgress.percent
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width:
                                            this.props.uploadProgress.percent +
                                            "%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                          }}
                                        >
                                          {this.props.uploadProgress.percent +
                                            "%"}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0">
                                        {this.state.fileToUpdate !== null ? "Update File" : "Upload New File"}
                                      </h5>
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                          this.uploadFile(e);
                                        }}
                                      >
                                        <div className="form-group">
                                          <p className="text-muted">
                                            Upload File. Maximum file size of
                                            150MB.
                                          </p>

                                          {this.state.complianceFile.file ?
                                            <Document file={this.state.complianceFile.file}>
                                              {/* <Page pageNumber={1} /> */}
                                            </Document> : <></>}

                                          <input
                                            type="file"
                                            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,application/pdf"
                                            id="customFile"
                                            onChange={(event) =>
                                              this.processFile(event, this)
                                            }
                                          />
                                        </div>
                                        <div className="row form-group">
                                          <label className="col-md-12 col-form-label">
                                            Compliance Tag
                                          </label>
                                          <div className="col-md-12">
                                            {this.state.fileToUpdate !== null ? <input className="form-control" readOnly value={this.state.fileToUpdate.complianceModel.title} /> :
                                              <select
                                                value={this.state.complianceTag}
                                                className="form-control"
                                                onChange={(e) =>
                                                  this.handleComplianceTagChanges(
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                {this.props.complianceList.map(
                                                  (item) => {
                                                    console.log(this.props.complianceList);
                                                    return (
                                                      <option
                                                        key={item.complianceCheckId._id}
                                                        value={item.complianceCheckId._id}
                                                      >
                                                        {item.complianceCheckId.title}
                                                      </option>
                                                    )
                                                  }
                                                )}
                                              </select>}
                                          </div>
                                        </div>

                                        {/* <div className="row form-group" >
                                                  <div className="col-12">
                                                    <label>Tag</label>
                                                    <input onChange={(e) => this.setState({uploadTag:e.target.value})} value={this.state.uploadTag} className="form-control" type="text" placeholder="compliance item name" required/>
                                                  </div>
                                                </div> */}

                                        <div className="row form-group">
                                          {/* <div className="col-6">
                                            <button
                                              className="form-control btn btn-info"
                                              style={{
                                                backgroundColor: "#e2b537",
                                                color: "black",
                                                borderColor: "#e2b537",
                                                fontWeight: "bold",
                                              }}
                                              onClick={this.previewFile}
                                            >
                                              Preview File
                                            </button>
                                          </div> */}
                                          <div className="col-6 mx-auto">
                                            <button
                                              className="form-control btn btn-primary"
                                              style={{
                                                backgroundColor: "#e2b537",
                                                color: "black",
                                                borderColor: "#e2b537",
                                                fontWeight: "bold",
                                              }}
                                              type="submit"
                                            >
                                              Upload
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Date modified</th>
                              <th>Size</th>
                              <th>Tag</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.selectedProfile.complianceDocuments.map(
                              (doc, idx) => (
                                <tr key={idx}>
                                  <td>{doc.file.fileName}</td>
                                  <td>
                                    {formatDate(doc.file.createdAt)}
                                  </td>
                                  <td>{this.bytesToSize(doc.file.fileSize)}</td>
                                  <td>{doc.compliance ? doc.compliance.title : "Compliance"}</td>
                                  <td>
                                    <div className="dropdown">
                                      <a href="/#" className="dropdown-toggle card-drop" data-toggle="dropdown" aria-expanded="false">
                                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                      </a>
                                      <ul className="dropdown-menu dropdown-menu-center pl-0" style={{ "marginLeft": "-141px" }}>
                                        <li>
                                          <a href={`${BASE_URL}compliance/document/download?fileId=${doc.file._id}`} className="dropdown-item">
                                            <i className="bx bx-download  text-success"></i> Download</a>
                                        </li>
                                        <li>
                                          <a onClick={() => {
                                            this.updateDoc({
                                              compliance: doc._id,
                                              complianceModel: doc.compliance
                                            })
                                          }} className="dropdown-item">
                                            <i className="bx bx-repeat  text-success"></i> Update </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end col --> */}

                <div className="col-lg-4">
                  {this.state.isEditingContactPerson ? (
                    <div className="card">
                      <div className="card-body">
                        {this.state.isUpdatingContact ? (
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              left: 0,
                              top: 0,
                              zIndex: 3,
                              height: "100%",
                              background: "#fffbfb3d",
                              textAlign: "center",
                              backdropFilter: "blur(3px)",
                            }}
                          >
                            <p style={{ top: "50%", fontWeight: "bold" }}>
                              {" "}
                              updating your contact ...{" "}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div>
                          <div className="row">
                            <div
                              className="col-sm-12"
                              style={{ display: "flex" }}
                            >
                              <h4
                                className="col-sm-10 card-title mb-0"
                                style={{
                                  color: "#495057",
                                  paddingTop: "0.4rem",
                                  alignSelf: "start",
                                }}
                              >
                                Primary Contact Person
                              </h4>
                              <div
                                className="text-sm-right"
                                style={{ float: "right" }}
                              >
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#e2b537",
                                    color: "black",
                                    borderColor: "#e2b537",
                                    fontWeight: "bold",
                                    padding: "2px 10px",
                                    marginBottom: "1rem",
                                  }}
                                  onClick={() =>
                                    this.handleContactUpdate({
                                      profile: this.state.profile._id,
                                      ...this.state.profile.createdBy,
                                    })
                                  }
                                  className="btn btn-success btn-rounded waves-effect waves-light mr-2"

                                >
                                  {" "}
                                  Save
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#e2b537",
                                    color: "black",
                                    borderColor: "#e2b537",
                                    fontWeight: "bold",
                                    padding: "2px 10px",
                                    marginBottom: "1rem",
                                  }}
                                  onClick={() =>
                                    this.toggleEdit({
                                      isEditingContactPerson: false,
                                    })
                                  }
                                  className="btn btn-success btn-rounded waves-effect waves-light mr-2"

                                >
                                  {" "}
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                          <label>First name : </label>
                          <input
                            onChange={(e) =>
                              this.handleCFNameChange(e.target.value)
                            }
                            className="form-control mb-2"
                            type="text"
                            required={true}
                            placeholder="Contact first name"
                            value={this.state.profile.createdBy.name.firstName}
                          />

                          <label>Last name : </label>
                          <input
                            onChange={(e) =>
                              this.handleCLNameChange(e.target.value)
                            }
                            className="form-control mb-2"
                            type="text"
                            required={true}
                            placeholder="Contact last name"
                            value={this.state.profile.createdBy.name.lastName}
                          />

                          <label>Mobile : </label>
                          <input
                            onChange={(e) =>
                              this.handleCNumberChange(e.target.value)
                            }
                            className="form-control mb-2"
                            type="number"
                            placeholder="26777777777"
                            value={this.state.profile.createdBy.contactNumber}
                          />

                          {/* <label>Email : </label>
                                <input onChange={(e) => this.handleCEmailChange(e.target.value)} className="form-control mb-2" type="email" required={true} placeholder="Contact email" value={this.state.profile.createdBy.email}/> */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div
                            className="col-sm-12"
                            style={{ display: "flex" }}
                          >
                            <h4
                              className="col-sm-10 card-title mb-0"
                              style={{
                                color: "#495057",
                                paddingTop: "0.4rem",
                                alignSelf: "start",
                              }}
                            >
                              Primary Contact Person
                            </h4>
                            {this.state.user._id === this.props.selectedProfile.createdBy._id ?
                              <div
                                className="text-sm-right"
                                style={{ float: "right" }}
                              >
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.toggleEdit({
                                      isEditingContactPerson:
                                        !this.state.isEditingContactPerson,
                                    })
                                  }
                                  className="btn btn-success btn-rounded waves-effect waves-light mr-2"
                                  style={{
                                    padding: "2px 10px",
                                    marginBottom: "1rem",
                                    backgroundColor: "#e2b537",
                                    color: "black",
                                    borderColor: "#e2b537",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {" "}
                                  Edit
                                </button>
                              </div>
                              :
                              ""
                            }

                          </div>
                        </div>
                        <table className="table table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>
                                {this.props.selectedProfile.createdBy
                                  ? this.props.selectedProfile.createdBy.name
                                    .firstName +
                                  " " +
                                  this.props.selectedProfile.createdBy.name
                                    .lastName
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Mobile :</th>
                              <td>
                                {this.props.selectedProfile.createdBy
                                  ? this.props.selectedProfile.createdBy
                                    .contactNumber
                                  : "not provided"}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">E-mail :</th>
                              <td>
                                {this.props.selectedProfile.createdBy
                                  ? this.props.selectedProfile.createdBy.email
                                  : ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12" style={{ display: "flex" }}>
                          <h4
                            className=" col-sm-8 card-title mb-5"
                            style={{ color: "#495057" }}
                          >
                            Compliance Checks
                          </h4>
                          {/* <div
                            className="text-sm-right"
                            style={{ float: "right" }}
                          >
                            <button
                              type="button"
                              className="btn btn-success btn-rounded waves-effect waves-light mr-2"
                              style={{
                                padding: "2px 10px",
                                marginBottom: "1rem",
                                backgroundColor: "#e2b537",
                                color: "black",
                                borderColor: "#e2b537",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              Request Review
                            </button>
                          </div> */}
                        </div>
                      </div>
                      <div>
                        <ul className="verti-timeline list-unstyled p-0">
                          {
                            this.props.complianceList && this.props.complianceList.length > 0 ?
                              this.props.complianceList.map((compliance) =>
                                compliance.isCompliant ? (
                                  <li key={compliance._id} className="event-list">
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle"></i>
                                    </div>
                                    <div className="media">
                                      <div className="mr-3">
                                        <i className="bx bx-check-shield h4 text-primary"></i>
                                      </div>
                                      <div className="media-body">
                                        <div>
                                          <h5 className="font-size-15">
                                            <a href="/#" className="text-dark">
                                              {compliance.complianceCheckId.title}
                                            </a>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                ) : (
                                  <li
                                    key={compliance._id}
                                    className="event-list active"
                                  >
                                    <div
                                      className="event-timeline-dot"
                                      style={{ color: "red" }}
                                    >
                                      <i className="bx bx-right-arrow-circle bx-fade-right"></i>
                                    </div>
                                    <div className="media">
                                      <div className="mr-3">
                                        <i
                                          className="bx bx-x"
                                          style={{ color: "red" }}
                                        ></i>
                                      </div>
                                      <div className="media-body">
                                        <div>
                                          <h5 className="font-size-15">
                                            <a href="/#" className="text-dark">
                                              {compliance.complianceCheckId.title}
                                            </a>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                )
                              ) : <div className="text-center">
                                <i className="far fa-folder-open fa-7x text-info"></i>
                                <p className="small px-2 pb-4 text-muted">checks have not been confirmed yet</p>
                              </div>}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- end col --> */}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-events"
              role="tabpanel"
              aria-labelledby="v-pills-events-tab"
            >
              <div className="pl-4">
                <div className="row mb-2">
                  <h2
                    style={{
                      fontSize: "29px",
                      textTransform: "uppercase",
                    }}
                    className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                  >
                    My events
                  </h2>
                </div>
                <p className="row mb-4">
                  This section shows all hackathon events this business has
                  participated in
                </p>
                <div className="row">
                  {this.props.events.map((contest, index) => (
                    <div key={index} className="col-md-3 col-xl-3">
                      {/* <!-- Simple card --> */}
                      <div
                        onClick={() =>
                          this.props.history.push("/event/" + contest._id)
                        }
                        className="card"
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          boxShadow: "rgb(136 136 136 / 43%) 3px 5px 11px",
                        }}
                      >
                        <div
                          style={{
                            height: "150px",
                            width: "100%",
                            backgroundImage:
                              "url(" + contest.eventBanner.data + ")",
                            backgroundOrigin: "center",
                            backgroundSize: "cover",
                          }}
                        ></div>
                        {/* <img
                          className="card-img-top img-fluid"
                          src={contest.banner}
                          alt="Card image cap"
                        /> */}

                        <div
                          className="card-body"
                          style={{ textAlign: "left" }}
                        >
                          <h4
                            className="card-title mt-0 text-dark"
                            style={{ fontSize: "15px" }}
                          >
                            {contest.title}
                          </h4>
                          {/* <p className="mb-1">Time line</p> */}
                          <p
                            className="card-text text-muted"
                            style={{ fontSize: "12px" }}
                          >
                            <b className="text-primary font-weight-bolder">
                              Starts:{" "}
                            </b>
                            {formatDate(
                              new Date(contest.startDate),

                            )}{" "}
                            <br />
                            <b className="text-primary font-weight-bolder">
                              Ends:{" "}
                            </b>
                            {formatDate(
                              new Date(contest.endDate),

                            )}
                          </p>
                          <p className="text-dark">
                            {ReactHtmlParser(
                              contest.description
                                .replace(/<\/?[^>]+(>|$)/g, "")
                                .substring(0, 40)
                                .trim()
                                .padEnd(3, ".")
                            )}
                          </p>
                          <div style={{ textAlign: "left" }}>
                            {contest.tags.map((tag, index) => (
                              <span
                                key={{ index }}
                                href="#"
                                className="badge badge-primary font-size-11 m-1"
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-members"
              role="tabpanel"
              aria-labelledby="v-pills-members-tab"
            >
              <div className="pl-4">
                <div className="row mb-2">
                  <h2
                    style={{
                      fontSize: "29px",
                      textTransform: "uppercase",
                    }}
                    className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                  >
                    Business members
                  </h2>
                </div>
                <p className="row mb-4">
                  Users with access to {this.props.selectedProfile.businessName}{" "}
                  business profile
                </p>
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-sm-4">
                        <div className="search-box mr-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <h4
                              className="card-title mb-4"
                              style={{ color: "#495057" }}
                            >
                              Members
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="text-sm-right">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#member-invite-modal"
                            style={{
                              backgroundColor: "#e2b537",
                              color: "black",
                              borderColor: "#e2b537",
                              fontWeight: "bold",
                            }}
                            className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                          >
                            <i className="mdi mdi-plus mr-1"></i>Add new member
                          </button>
                        </div>
                        <div
                          className="modal fade bs-example-modal-center"
                          id="member-invite-modal"
                          style={{ backgroundColor: "#00000080" }}
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="mySmallModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title mt-0">
                                  Invite new Business member
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <form
                                  className="form-horizontal"
                                  onSubmit={(e) => this.handleAccountInvite(e)}
                                >
                                  <div className="row form-group">
                                    <div className="col-12">
                                      <label>Business email</label>
                                      <input
                                        onChange={(e) =>
                                          this.setState({
                                            inviteEmail: e.target.value,
                                          })
                                        }
                                        value={this.state.inviteEmail}
                                        className="form-control"
                                        type="email"
                                        placeholder="Business Email"
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="row form-group">
                                    <div className="col-12">
                                      <button
                                        className="form-control btn btn-primary"
                                        style={{
                                          backgroundColor: "#e2b537",
                                          color: "black",
                                          borderColor: "#e2b537",
                                          fontWeight: "bold",
                                        }}
                                        type="submit"
                                      >
                                        Send Invitation
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- /.modal --> */}
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Number</th>
                            <th>Email</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.selectedProfile.members ? (
                            this.props.selectedProfile.members.map((member) => (
                              <tr key={member._id}>
                                <td>
                                  {member.name.firstName +
                                    " " +
                                    member.name.lastName}
                                </td>
                                <td>
                                  {member.contactNumber
                                    ? member.contactNumber
                                    : "not provided"}
                                </td>
                                <td>{member.email}</td>

                                <td>
                                  {
                                    this.props.selectedProfile.members.length > 1 ?
                                      <div className="dropdown">
                                        <a
                                          href="/#"
                                          className="dropdown-toggle card-drop"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-center pl-0">
                                          <li>
                                            <a href="/#" className="dropdown-item">
                                              <i className="bx bx-download  text-success"></i>{" "}
                                              Remove member
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/#" className="dropdown-item">
                                              <i className="bx bx-download  text-success"></i>{" "}
                                              Make business owner
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                      :
                                      ""
                                  }

                                </td>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-sm-4">
                        <div className="search-box mr-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <h4
                              className="card-title"
                              style={{ color: "#495057" }}
                            >
                              Invitations
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-centered table-nowrap">
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Creation Date</th>
                            <th>Status</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.selectedProfile.invitations ? (
                            this.props.selectedProfile.invitations.map(
                              (invitation) => (
                                <tr key={invitation._id}>
                                  <td>{invitation.email}</td>
                                  <td>
                                    {formatDate(
                                      invitation.createdAt
                                    )}
                                  </td>
                                  <td>{invitation.status}</td>
                                  {/* <td>
                                    <div className="dropdown">
                                      <a
                                        href="#"
                                        className="dropdown-toggle card-drop"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                      </a>
                                      <ul className="dropdown-menu dropdown-menu-center pl-0">
                                        <li>
                                          <a href="#" className="dropdown-item">
                                            <i className="bx bx-download  text-success"></i>{" "}
                                            Cancel
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td> */}
                                </tr>
                              )
                            )
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-jobs"
              role="tabpanel"
              aria-labelledby="v-pills-jobs-tab"
            >
              <div className="pl-4">
                <div className="row mb-4">
                  <button type="button" className="btn btn-warning btn-rounded waves-effect waves-light text-dark  font-weight-bold" onClick={() => this.setState({ jobState: "creation", intent: "new" })}>New job</button>
                </div>
                <div className="col-12">
                  {
                    this.state.jobState === "listings" ?
                      <BusinessJobPosts navigateToCreation={this.navigateToCreation} navigateToApplicants={this.navigateToApplicants} business={this.props.selectedProfile} />
                      :
                      this.state.jobState === "applicants" ?
                        <JobApplicantList navigateToApplicant={this.navigateToApplicant} job={this.state.jobId} />
                        :
                        this.state.jobState === "creation" ?
                          <BusinessCreateJobPost intent={this.state.intent} job={this.state.job} business={this.props.selectedProfile} navigateToJobListings={this.navigateToJobListings} />
                          :
                          this.state.jobState === "applicant" ?
                            <BusinessApplicantDetails applicant={this.state.applicant} />
                            :
                            ""
                  }



                </div>
                {/* <ContentSkeleton /> */}
              </div>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

export default withRouter(BusinessProfileDetails);
