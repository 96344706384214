import moment from "moment-timezone";

import { IMAGE_BASE_URL } from "../api/client";

export function isValidEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isNotEmpty(str) {
  return str ? str.trim() !== "" : false;
}

export function getLanguageIcon(language) {
  language = language === undefined || language === null ? "-" : language;

  //return `/assets/images/languages/${language.replace("#", "_SHARP")}.png`;
  // return window.location.origin + ":8080/uploads/" + language
  return IMAGE_BASE_URL + "uploads/" + language;
}

//redundent function the getLanguageIcon function does the same thing
export function getTechnologyIcon(technology) {
  technology = technology === undefined ? "-" : technology;
  return IMAGE_BASE_URL + "uploads/" + technology;
}

export function getIcon(item) {
  item = item === undefined ? "-" : item;
  return `/assets/images/languages/${item.replace("#", "_SHARP")}.png`;
}


export function timeZoneHelper(date) {
  return moment(date).tz(moment.tz.guess(), true) || date;
}

/**
 * Basic compare function
 *
 * @param  {} a ith element in list
 * @param  {} b i+1 element in list
 */
function compare(a, b) {
  const bandA = a.value;
  const bandB = b.value;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = -1;
  } else if (bandA < bandB) {
    comparison = 1;
  }
  return comparison;
}

/**
 * Get frequency of all user skills
 *
 * @param  {Array} data skills
 */
export function handleSkills(data) {
  var titles = data.map((item) => {
    return item.title;
  });

  var skills = [];
  // make a copy of the input array
  var copy = titles.slice(0);

  // first loop goes over every element
  for (var i = 0; i < titles.length; i++) {
    var myCount = 0;
    // loop over every element in the copy and see if it's the same
    for (var w = 0; w < copy.length; w++) {
      if (titles[i] == copy[w]) {
        // increase amount of times duplicate is found
        myCount++;
        // sets item to undefined
        delete copy[w];
      }
    }

    if (myCount > 0) {
      var a = {};
      a.value = titles[i];
      a.count = myCount;
      skills.push({
        id: titles[i],
        label: titles[i],
        value: myCount,
      });
    }
  }

  //TODO:: sort skill by count
  skills = skills.slice().sort(compare);

  return skills;
}

export function formatDate(date) {
  return moment(date).tz(moment.tz.guess(), true).format("DD ddd, MM yyyy, hh:mmA") || date;
}

export function arrayPrepend(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

export function handleAlerts(msg) {
  if (typeof msg === "undefined" || (typeof msg !== "undefined" && typeof msg.request === "undefined")) {
    return {
      id: Date.now() + Math.random(),
      isActive: true,
      title: "Error",
      body: msg.message || msg.err || msg || "something went wrong",
      time: new Date().toLocaleString(),
      delay: 30000,
    };

    // check if its network error
  } else if (msg.message === "Network Error") {
    return {
      id: Date.now() + Math.random(),
      isActive: true,
      title: msg.message,
      body: "Please check your connection settings",
      time: new Date().toLocaleString(),
      delay: 10000,
    };

    // check for request errors
  } else if (
    msg.request.status === 404 ||
    msg.request.status === 400 ||
    msg.request.status === 500
  ) {
    return {
      id: Date.now() + Math.random(),
      isActive: true,
      title: "Notification ",
      body: "Task failed to complete with error status \n" + msg.request.status,
      time: new Date().toLocaleString(),
      delay: 10000,
    };
  } else if (msg.request.status === 403) {
    return {
      id: Date.now() + Math.random(),
      isActive: true,
      title: "Notification ",
      body: msg || msg.err || "Task failed to complete. \n" + msg.request.statusText,
      time: new Date().toLocaleString(),
      delay: 10000,
    };
  } else if (msg.request.status === 200) {
    return {
      id: Date.now() + Math.random(),
      isActive: true,
      title: "Notification",
      body: "Task Successfully Completed",
      time: new Date().toLocaleString(),
      delay: 10000,
    };
  } else {
    return {
      id: Date.now() + Math.random(),
      isActive: true,
      title: "Notification",
      body: msg || msg.err || "something went wrong",
      time: new Date().toLocaleString(),
      delay: 30000,
    };
  }
}
