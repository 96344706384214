import React, { Component } from "react";
import ProjectList from "../../components/ProjectList";
import TechnologiesList from "../../components/TechnologiesList";
import LanguagesList from "../../components/LanguagesList";
import RankBadge from "../../components/RankBadge";
import { NavLink } from "react-router-dom";
import { createBrowserHistory as history } from "history";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import Toast from "react-bootstrap/Toast";
import { client } from "../../api/client";

class Profile extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRevoking: false,
      notification: {
        isActive: false,
      },
      userBio: JSON.parse(Cookie.get("user")).bio
        ? JSON.parse(Cookie.get("user")).bio
        : JSON.parse(Cookie.get("user")).githubData
          ? JSON.parse(Cookie.get("user")).githubData.bio
          : "",
      bio: JSON.parse(Cookie.get("user")).bio
        ? JSON.parse(Cookie.get("user")).bio
        : JSON.parse(Cookie.get("user")).githubData
          ? JSON.parse(Cookie.get("user")).githubData.bio
          : "",
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.revokeGithub = this.revokeGithub.bind(this);
  }

  componentWillMount() {
    // hacky
    if (
      this.props.location.search.split("=").length > 0 &&
      this.props.location.search
        .split("=")[0]
        .replace("?", "")
        .includes("oauth")
    ) {
      client
        .get("/profile?userId=" + JSON.parse(Cookie.get("user"))._id)
        .then((response) => {
          const user =
            typeof response.data === "string"
              ? JSON.parse(response.data)
              : response.data;
          Cookie.set("user", user);
          window.location = "/profile";
        });
    }
  }

  handleOnChange(event) {
    let bio = event.target.value;
    if (bio === "") {
      bio = " ";
    }
    this.setState({ userBio: bio });
  }

  revokeGithub() {
    if (this.state.isRevoking) return;
    this.setState({ isRevoking: true });

    window.$(".revoke-github-modal").modal("hide");
    client
      .put("/profile/revoke-github", {
        userId: JSON.parse(Cookie.get("user"))._id,
      })
      .then((user) => {
        Cookie.set("user", user.data);
        this.setState({
          bio: this.state.userBio,
          notification: {
            isActive: true,
            title: "Access revoked",
            body: "Successfully revoked Skillsranker's access to Github",
          },
        });
      })
      .catch(() => {
        this.setState({
          notification: {
            isActive: true,
            title: "Failed to revoke access",
            body: "Failed to revoke Skillsranker's access to Github. Please check your internet connection and try again.",
          },
        });
      })
      .finally(() => this.setState({ isRevoking: false }));
    setTimeout(() => {
      this.setState({
        notification: {
          isActive: false,
          title: null,
          body: null,
        },
      });
    }, 7000);
  }

  updateProfile(event) {
    event.preventDefault();

    this.setState({ isLoading: true });

    client
      .put("/profile", {
        bio: this.state.userBio,
        userId: JSON.parse(Cookie.get("user"))._id,
      })
      .then((user) => {
        if (user.data) {
          Cookie.set("user", user.data);
          this.setState({
            bio: this.state.userBio,
            notification: {
              isActive: true,
              title: "Profile updated",
              body: "Profile has successfully updated",
            },
          });
        }
      })
      .catch(() => {
        this.setState({
          notification: {
            isActive: true,
            title: "Failed to update profile",
            body: "Failed to update profile. Please check your internet connection and try again.",
          },
        });
      })
      .finally(() => this.setState({ isLoading: false }));
    setTimeout(() => {
      this.setState({
        notification: {
          isActive: false,
          title: null,
          body: null,
        },
      });
    }, 7000);
    window.$(".bio-modal").modal("hide");
  }

  blank() {
    return (
      <div
        data-toggle="modal"
        data-target=".bio-modal"
        style={{
          cursor: "pointer",
          display: "table",
          textAlign: "center",
          width: "100%",
          minHeight: "8rem",
          border: "dashed 1px gray",
        }}
      >
        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
          NO SUMMARY INFO YET, CLICK EDIT TO ADD DATA
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="main-content">
        <div
          className="page-content"
          style={{
            marginTop: "50px",
          }}
        >
          <div className="container-fluid">
            {/* <!-- Top header --> */}
            <div className="row">
              <div className="col-xl-3">
                {/* avater */}
                <div className="card overflow-hidden">
                  <div className="bg-soft-secondary">
                    <div className="row">
                      <div className="col-10">
                        <div className="text-primary p-3"> </div>
                      </div>
                      {/* <!-- <div className="col-5 align-self-end">
                                                                  <img src="assets/images/profile-img.png" alt="" className="img-fluid"/>
                                                              </div> --> */}
                    </div>
                  </div>
                  <div className="card-body pt-0 pb-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="avatar-lg profile-user-wid mb-4">
                              <img
                                src={
                                  JSON.parse(Cookie.get("user")).githubData
                                    ? JSON.parse(Cookie.get("user")).githubData
                                      .avatar_url
                                    : "/assets/images/users/avatar.png"
                                }
                                alt=""
                                className="img-thumbnail rounded-circle"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            {Cookie.get("user") ? (
                              <RankBadge
                                userId={JSON.parse(Cookie.get("user"))._id}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <h5
                          className="font-size-15 text-truncate"
                          style={{ color: "#000" }}
                        >
                          {JSON.parse(Cookie.get("user")).name.firstName +
                            " " +
                            JSON.parse(Cookie.get("user")).name.lastName}
                        </h5>
                        <p className="font-size-13 text-muted">
                          {JSON.parse(Cookie.get("user")).email}
                        </p>
                        {JSON.parse(Cookie.get("user")).titles.map(
                          (title, index) => (
                            <p
                              key={index}
                              className="text-muted mb-0 text-truncate text-primary"
                              style={{ textTransform: "capitalize" }}
                            >
                              {title}
                            </p>
                          )
                        )}

                        <p
                          className="text-muted mt-3 text-truncate"
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* <span className="bx bx-current-location"></span>&nbsp; */}
                          {JSON.parse(Cookie.get("user")).location}
                        </p>
                        <NavLink
                          to="/profile/update"
                          style={{ color: "white" }}
                        >
                          <button className="btn btn-secondary btn-block rounded-bottom mb-4">
                            <i className="fa fa-edit"></i>
                            &nbsp;&nbsp;Edit Profile Information
                          </button>
                        </NavLink>
                        <hr />
                        <div className="pt-1 pb-4">
                          <h6 className="mb-3 font-weight-bolder text-uppercase text-warning">
                            Share Profile
                          </h6>
                          <p>Show off your skills and share your profile</p>
                          <a
                            href={`https://twitter.com/intent/tweet?url=http%3A%2F%2Fskillsranker.bih.co.bw/profile/${JSON.parse(Cookie.get("user"))._id
                              }&text=Check%20out%20my%20profile%20on%20Skills%20Ranker%20%23skills_ranker%20%F0%9D%99%A8%F0%9D%99%A0%F0%9D%99%9E%F0%9D%99%A1%F0%9D%99%A1%F0%9D%99%A8%F0%9D%99%A7%F0%9D%99%96%F0%9D%99%A3%F0%9D%99%A0%F0%9D%99%9A%F0%9D%99%A7%20%F0%9F%A4%93%20%F0%9F%92%BB`}
                            target="_blank" rel="noreferrer"
                          >
                            <i className="fab fa-lg fa-twitter"></i>
                          </a>
                          <a
                            className="ml-4"
                            href={`https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fskillsranker.bih.co.bw/profile/${JSON.parse(Cookie.get("user"))._id
                              }`}
                            target="_blank" rel="noreferrer"
                          >
                            <i className="fab fa-lg fa-facebook"></i>
                          </a>
                          <a
                            className="ml-4"
                            href={`http://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fskillsranker.bih.co.bw/profile/${JSON.parse(Cookie.get("user"))._id
                              }/p&title=Check%20out%20my%20profile%20on%20Skills%20Ranker%23skills_ranker`}
                            target="_blank" rel="noreferrer"
                          >
                            <i className="fab fa-lg fa-linkedin"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    {!JSON.parse(Cookie.get("user")).isGithubLinked ? (
                      <a
                        style={{ backgroundColor: "#000" }}
                        href={`https://github.com/login/oauth/authorize?client_id=9f0ab3aa9f846d100c55&scope=${JSON.parse(Cookie.get("user"))._id
                          }&redirect_uri=${encodeURIComponent(
                            "https://skillsranker.bih.co.bw/v1/api/github/auth/"
                          )}${JSON.parse(Cookie.get("user"))._id}`}
                        className="btn btn-dark btn-block rounded-bottom mb-4 "
                      >
                        <i
                          style={{ fontSize: "large", verticalAlign: "middle" }}
                          className="bx bxl-github"
                        ></i>
                        &nbsp;&nbsp;Link Github
                      </a>
                    ) : (
                      <div className="d-flex">
                        <button
                          className={
                            "btn btn-danger btn-block rounded-bottom mb-4" +
                            (this.state.isRevoking ? " disabled" : "")
                          }
                          data-toggle="modal"
                          data-target={
                            this.state.isRevoking ? "" : ".revoke-github-modal"
                          }
                        >
                          {this.state.isRevoking ? (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                          ) : (
                            <i
                              style={{
                                fontSize: "large",
                                verticalAlign: "middle",
                              }}
                              className="bx bxl-github"
                            ></i>
                          )}
                          &nbsp;&nbsp;Revoke Github Access
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {/* PROJECTS */}
                {/* pass project data objects as a prop */}
                <ProjectList history={history} />
              </div>
              <div className="col-xl-9">
                <div className="card pl-3 pr-4">
                  <div className="card-body">
                    <div className="invoice-title">
                      {!this.state.isEdited ? (
                        <div className="float-right md-4">
                          <a
                            href="/#"
                            className="btn btn-outline-secondary waves-effect waves-light"
                            data-toggle="modal"
                            data-target=".bio-modal"
                          >
                            <i className="mdi mdi-lead-pencil"></i> &nbsp; Edit
                          </a>
                        </div>
                      ) : (
                        ""
                      )}

                      <h4
                        className="card-title text-uppercase"
                        style={{ color: "#000" }}
                      >
                        Summary
                      </h4>
                    </div>
                    <div className="row pr-5 pl-1 pt-3">
                      {this.state.isLoading ? (
                        <p>Submitting profile update...</p>
                      ) : this.state.bio === " " ? (
                        this.blank()
                      ) : (
                        <p className="card-text pr-2" style={{ color: "#000" }}>
                          {this.state.bio === " "
                            ? this.blank()
                            : this.state.bio}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <LanguagesList />
                <TechnologiesList />
                {/* <AssesmentsList /> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bio-modal bs-example-modal-center"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form onSubmit={this.updateProfile} className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">Edit Summary</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group mb-0">
                    <label className="control-label">Summary</label>
                    <br />
                    <textarea
                      className="form-control"
                      type="text"
                      rows="8"
                      maxLength="1000"
                      value={this.state.userBio}
                      onChange={this.handleOnChange}
                      placeholder="Write something..."
                    ></textarea>
                    <br />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  // onClick={updateProfile({ bio: this.state.userBio })}
                  value={this.state.bio}
                  className="btn btn-secondary waves-effect waves-light"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary waves-effect waves-light"
                  // data-dismiss="modal"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 90,
            right: 30,
            zIndex: 9999,
          }}
        >
          <Toast
            // key={ }
            onClose={() => {
              this.setState({
                notification: {
                  isActive: false,
                  title: null,
                  body: null,
                },
              });
            }}
            show={this.state.notification.isActive}
            autohide
            delay={2000}
          >
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong id="title" className="mr-auto">
                {this.state.notification.title}
              </strong>
              {/* <small id="time">{this.state.notification.time}</small> */}
            </Toast.Header>
            <Toast.Body id="body">{this.state.notification.body}</Toast.Body>
          </Toast>
        </div>
        <div
          className="modal fade revoke-github-modal bs-example-modal-center"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">Revoke Github Access</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group mb-0">
                    <p>
                      By revoking Skillsranker's access to your account you
                      loose the rank the platform has allocated you and will
                      only be ranked according to the assesments you have taken
                      on the platform. Skillsranker's only uses your Github
                      public data to award you a rank for against the platform
                      and does not have actual access to your Github account.
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  // onClick={updateProfile({ bio: this.state.userBio })}
                  value={this.state.bio}
                  className="btn btn-secondary waves-effect waves-light"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-danger waves-effect waves-light"
                  onClick={this.revokeGithub}
                >
                  Revoke
                </button>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                {new Date().getFullYear()} © Botswana Digital and Innovation Hub
              </div>
              <div className="col-sm-6">
                <div className="text-sm-right d-none d-sm-block">
                  Skills Ranker
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getUserProfile: userProfile => dispatch(getUserProfile())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
