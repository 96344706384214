import React, { Component } from "react";




class AssesmentResultSkeleton extends Component {
  state = {
      score:'',
  };

  render() {
        
    
        return(
            
                                                    
                                                    

                                                
                    <div className="row justify-content-md-center justify-content-sm-center" >
                        <div className="col-lg-3 ">
                            <div className="text-center _container_skeleton _skeleton" style={{borderRadius:'100%', height:'9rem', width:'9rem'}} dir="ltr">
                               
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="text-justify justify-content-md-center" dir="ltr">
                                <h4 className=" mb-2 text_skeleton _skeleton"></h4>
                                <h5 className="text_skeleton _skeleton"></h5>
                                <p className="mt-4 text_skeleton _skeleton"></p>
                            </div>
                        </div>
                        <div className="col-lg-3 _skeleton">
                            
                        </div>
                    </div>
                    

                                           
        );
        
    }
}

  export default AssesmentResultSkeleton;


