import React, { Component } from "react";
import {
  Route,
  NavLink,
  Switch,
} from "react-router-dom";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import AdminContest from "../containers/pages/AdminContest";
import AdminContestNew from "../containers/pages/AdminContestNew";
import AdminEventRequestList from "../containers/pages/AdminEventRequestList";
import AdminContestList from "../containers/pages/AdminContestList";

class SponsorWrapper extends Component {

  state = { regions: ["rules", "teams", "prizes", "leaderboard"], region: "", event: "" };

  componentDidMount() {
    const { history } = this.props;
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div id="layout-SponsorWrapper">
        <header id="page-topbar" style={{ backgroundColor: "#2a3042" }}>
          <div className="navbar-header">
            <div className="d-flex">
              {/* <!-- LOGO --> */}
              <div className="navbar-brand-box">
                <NavLink
                  to="#"
                  className="logo logo-dark"
                  style={{ color: "white" }}
                >
                  <span className="logo-sm">
                    <img src="/assets/images/logo.svg" alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="/assets/images/logo-dark.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </NavLink>
                <NavLink
                  to="#"
                  className="logo logo-light"
                  style={{ color: "white" }}
                >
                  <span className="logo-sm">
                    <img
                      src="/assets/images/logo-light.svg"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="/assets/images/logo-light.png"
                      alt=""
                      height="19"
                    />
                  </span>
                </NavLink>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            <div className="d-flex">
              <div className="dropdown d-inline-block">
                {/* <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => this.props.history.push('/profile')}>
                                <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"/>
                                <span className="d-none d-xl-inline-block ml-1">Henry</span>
                                
                            </button> */}
                <NavLink
                  to="/profile"
                  className="btn header-item waves-effect"
                  style={{ verticalAlign: "middle", paddingTop: "16px" }}
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src={
                      JSON.parse(Cookies.get("user")).githubData
                        ? JSON.parse(Cookies.get("user")).githubData.avatar_url
                        : "/assets/images/users/avatar.png"
                    }
                    alt="Header Avatar"
                  />
                  <span className="d-none d-xl-inline-block ml-1">
                    {JSON.parse(Cookies.get("user")).name.firstName +
                      " " +
                      JSON.parse(Cookies.get("user")).name.lastName}
                  </span>
                </NavLink>
              </div>
              <div className="dropdown d-inline-block">
                <button
                  onClick={logout}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-power-off"></i>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
              <div
                className="collapse navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <NavLink
                      to="/sponsor/hackathon"
                      className="nav-link "
                      id="topnav-pages"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className=" bx bx-shekel mr-2"></i>Hackathons{" "}
                      <div className="arrow-down"></div>
                    </NavLink>
                    {/* <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="topnav-layout"
                    >
                      <NavLink to="/sponsor/hackathon" className="dropdown-item">
                        {" "}
                        Hackathons{" "}
                      </NavLink>
                      <NavLink
                        to="/sponsor/hackathon/requests"
                        className="dropdown-item"
                      >
                        {" "}
                        Hackathon Requests{" "}
                      </NavLink>
                    </div> */}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        <Switch>

          <Route path={"/sponsor/hackathon/:id"} component={AdminContest} exact />
          {/* <Route path={"/sponsor/hackathon/requests"} component={AdminEventRequestList} exact /> */}
          <Route path={"/sponsor/hackathon/new"} component={AdminContestNew} exact />
          <Route path={"/sponsor/hackathon"} component={AdminContestList} exact />
        </Switch>
      </div>
    );
  }
}
/**
 * remove cookie with user info and redirect to landing page
 */

function logout() {
  Cookies.remove("user");
  window.location = "/";
}



export default withRouter(SponsorWrapper);
