import React, { Component } from "react";
import { fetchAllContests } from "../../store/actions/contestActions";
import { connect } from "react-redux";
import EventsSkeleton from "../../components/skeletons/EventsSkeleton";
import empty from "../../../src/empty.gif";
import dateFormat from "dateformat";
import ReactHtmlParser, { } from 'react-html-parser';
import { formatDate } from "../../utils/utils";

class UserContestList extends Component {

  state = {
    isLoaded: false,
    live: [],
    upcoming: [],
    past: [],
    days: 10,
    hours: 0,
    min: 0,
    sec: 0,
    events: [
      {
        id: "1",
        title: "Some very awsome event title",
        date: "Sat 3rd Nov 2020 - Sun 4th Nov 2020",
        type: "",
        sponsor: "",
        author: "",
        description: "",
        summary: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
        supportingFiles: [{}],
        banner: "/assets/images/event2.jpeg",
        prizes: "",
        rules: "",
        expectedTurnOut: 0,
        tags: ["Web development", "Machine learning", "DevOps"],
        status: "Ended"
      },
      {
        id: "2",
        title: "Some very awsome event title",
        date: "Sat 3rd Nov 2020 - Sun 4th Nov 2020",
        type: "",
        sponsor: "",
        author: "",
        description: "",
        summary: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,",
        supportingFiles: [{}],
        banner: "/assets/images/isea2020.jpg",
        prizes: "",
        rules: "",
        expectedTurnOut: 0,
        tags: ["Web development", "Machine learning", "DevOps"],
        status: "New"
      }
    ]
  };


  /**
   * get all contests annd toggle componet loaded state when all data is loaded
   */
  componentDidMount() {
    // update every second
    // this.interval = setInterval(() => {
    //   const date = this.calculateCountdown(this.props.date);
    //   date ? this.setState(date) : this.stop();
    // }, 1000);
    this.props.getContests()
      .then(() => (
        this.setState({ events: this.props.contests, live: this.props.live, past: this.props.past, upcoming: this.props.upcoming, isLoaded: true })
      ))

  }


  calculateCountdown(endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

    // clear countdown when date is reached
    if (diff <= 0) return false;

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0
    };

    // calculate time difference between now and expected date
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    clearInterval(this.interval);
  }

  addLeadingZeros(value) {
    value = String(value);
    while (value.length < 2) {
      value = '0' + value;
    }
    return value;
  }



  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Hackathons</h4>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              {/* <div className="row"> */}
              {/* <div className="container-fluid d-flex"> */}
              <div className="row">
                <div className="col-xs-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="card" style={{ paddingTop: "10px" }}>
                    <div className="nav-tabs nav-tabs-custom">
                      <ul className="nav tab-nav" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link" data-toggle="tab" href="#home1" role="tab" aria-selected="false" style={{ color: "black" }}>
                            <span style={{ position: "absolute", top: "0", right: "0", margin: "0px", backgroundColor: "#FFC40C" }}
                              href="#"
                              className="badge badge-primary font-size-11"
                            >{this.state.live.length}
                            </span>
                            <span className="d-sm-block">Live</span>
                          </a>
                        </li>

                        <li className="nav-item">
                          <a className="nav-link" data-toggle="tab" href="#profile1" role="tab" aria-selected="false" style={{ color: "black" }}>
                            <span style={{ position: "absolute", top: "0", right: "0", backgroundColor: "#FFC40C" }}
                              href="#"
                              className="badge badge-primary font-size-11"
                            >{this.state.upcoming.length}
                            </span>
                            <span className="d-sm-block">Upcoming</span>
                          </a>
                        </li>


                        <li className="nav-item">
                          <a className="nav-link" data-toggle="tab" href="#messages1" role="tab" aria-selected="false" style={{ color: "black" }}>
                            <span style={{ position: "absolute", backgroundColor: "#FFC40C", top: "0px", right: "0px" }}
                              href="#"
                              className="badge badge-primary font-size-11"
                            >{this.state.past.length}
                            </span>
                            <span className="d-sm-block">Past</span>
                          </a>
                        </li>

                      </ul>
                    </div>
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content p-3 text-muted" >
                      <div className="tab-pane active" id="home1" role="tabpanel" >
                        <div className="row">
                          {this.state.live.length > 0 ?
                            this.state.live.map((contest, index) => (
                              <>
                                <div key={index} className="col-md-3 col-xl-3">
                                  {/* <!-- Simple card --> */}
                                  <div
                                    onClick={() =>
                                      this.props.history.push("/event/" + contest._id)
                                    }
                                    className="card"
                                    style={{ textAlign: "center", cursor: "pointer", boxShadow: "rgb(136 136 136 / 43%) 3px 5px 11px" }}
                                  >
                                    <div style={{
                                      height: "150px",
                                      width: "100%"
                                      , backgroundImage: "url(" + contest.eventBanner.data + ")",
                                      backgroundOrigin: "center",
                                      backgroundSize: "cover"
                                    }}>
                                    </div>
                                    {/* <img
                                    className="card-img-top img-fluid"
                                    src={contest.banner}
                                    alt="Card image cap"
                                  /> */}


                                    <div className="card-body" style={{ textAlign: "left" }}>

                                      <h4 className="card-title mt-0 text-dark" style={{ fontSize: '15px' }}>{contest.title}</h4>
                                      {/* <p className="mb-1">Time line</p> */}
                                      <p className="card-text text-muted" style={{ fontSize: "12px" }}>
                                        <b className="text-primary font-weight-bolder">Starts: </b>{formatDate(new Date(contest.startDate))} <br />
                                        <b className="text-primary font-weight-bolder">Ends: </b>{formatDate(new Date(contest.endDate))}
                                      </p>
                                      <p className="text-dark">{ReactHtmlParser(contest.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 40).trim().padEnd(3, "."))}</p>
                                      <div style={{ textAlign: "left" }}>
                                        {contest.tags.map((tag, index) => (
                                          <span key={{ index }}
                                            href="#"
                                            className="badge badge-primary font-size-11 m-1"
                                          >
                                            {tag}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </>
                            ))

                            :
                            <>
                              <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                                <div className="card" style={{ cursor: "pointer", border: "None", minHeight: "20rem" }}>
                                  <div className="card-body" style={{ backgroundImage: "url(" + empty + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                    <p style={{ fontSize: "1.5vw" }}>NO LIVE EVENTS</p>
                                  </div>
                                </div>
                              </div>
                            </>

                          }

                        </div>
                      </div>
                      <div className="tab-pane" id="profile1" role="tabpanel">
                        <div className="row">
                          {this.state.upcoming.length > 0 ?
                            this.state.upcoming.map(contest => (
                              <div key={contest._id} className="col-md-3 col-xl-3">
                                {/* <!-- Simple card --> */}
                                <div
                                  onClick={() =>
                                    this.props.history.push("/event/" + contest._id)
                                  }
                                  className="card"
                                  style={{ textAlign: "center", cursor: "pointer", boxShadow: "rgb(136 136 136 / 43%) 3px 5px 11px" }}
                                >
                                  <div style={{
                                    height: "150px",
                                    width: "100%"
                                    , backgroundImage: "url(" + contest.eventBanner.data + ")",
                                    backgroundOrigin: "center",
                                    backgroundSize: "cover"
                                  }}></div>
                                  {/* <img
                                    className="card-img-top img-fluid"
                                    src={contest.banner}
                                    alt="Card image cap"
                                  /> */}


                                  <div className="card-body" style={{ textAlign: "left" }}>

                                    <h4 className="card-title mt-0 text-dark" style={{ fontSize: '15px' }}>{contest.title}</h4>
                                    {/* <p className="mb-1">Time line</p> */}
                                    <p className="card-text text-muted" style={{ fontSize: "12px" }}>
                                      <b className="text-primary font-weight-bolder">Starts: </b>{formatDate(new Date(contest.startDate),)}<br />
                                      <b className="text-primary font-weight-bolder">Ends: </b>{formatDate(new Date(contest.endDate),)}
                                    </p>
                                    <p className="text-dark">{ReactHtmlParser(contest.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 40).trim().padEnd(3, "."))}</p>
                                    <div style={{ textAlign: "left" }}>
                                      {contest.tags.map((tag, index) => (
                                        <span key={{ index }}
                                          href="#"
                                          className="badge badge-primary font-size-11 m-1"
                                        >
                                          {tag}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            ))

                            :
                            <>
                              <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                                <div className="card" style={{ cursor: "pointer", border: "none", minHeight: "20rem" }}>
                                  <div className="card-body" style={{ backgroundImage: "url(" + empty + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                    <p style={{ fontSize: "1.5vw" }}>NO UPCOMING EVENTS</p>
                                  </div>
                                </div>
                              </div>
                            </>

                          }


                        </div>
                      </div>
                      <div className="tab-pane" id="messages1" role="tabpanel">
                        <div className="row">
                          {this.state.past.length > 0 ?
                            this.state.past.map(contest => (
                              <div key={contest._id} className="col-md-3 col-xl-3">
                                {/* <!-- Simple card --> */}
                                <div
                                  onClick={() =>
                                    this.props.history.push("/event/" + contest._id)
                                  }
                                  className="card"
                                  style={{ textAlign: "center", cursor: "pointer", boxShadow: "rgb(136 136 136 / 43%) 3px 5px 11px" }}
                                >
                                  <div style={{
                                    height: "150px",
                                    width: "100%"
                                    , backgroundImage: "url(" + contest.eventBanner.data + ")",
                                    backgroundOrigin: "center",
                                    backgroundSize: "cover"
                                  }}></div>
                                  {/* <img
                                    className="card-img-top img-fluid"
                                    src={contest.banner}
                                    alt="Card image cap"
                                  /> */}


                                  <div className="card-body" style={{ textAlign: "left" }}>

                                    <h4 className="card-title mt-0 text-dark" style={{ fontSize: '15px' }}>{contest.title}</h4>
                                    {/* <p className="mb-1">Time line</p> */}
                                    <p className="card-text text-muted" style={{ fontSize: "12px" }}>
                                      <b className="text-primary font-weight-bolder">Starts: </b>{formatDate(new Date(contest.startDate),)} <br />
                                      <b className="text-primary font-weight-bolder">Ends: </b>{formatDate(new Date(contest.endDate),)}
                                    </p>
                                    <p className="text-dark">{ReactHtmlParser(contest.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 40).trim().padEnd(3, "."))}</p>
                                    <div style={{ textAlign: "left" }}>
                                      {contest.tags.map((tag, index) => (
                                        <span key={{ index }}
                                          href="#"
                                          className="badge badge-primary font-size-11 m-1"
                                        >
                                          {tag}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>

                            ))

                            :
                            <>
                              <div className="col-md-12 col-xl-12 mt-4 text-center align-center">
                                <div className="card" style={{ cursor: "pointer", border: "none", minHeight: "20rem" }}>
                                  <div className="card-body" style={{ backgroundImage: "url(" + empty + ")", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                                    <p style={{ fontSize: "1.5vw" }}>NO PAST EVENTS</p>
                                  </div>
                                </div>
                              </div>
                            </>

                          }
                        </div>
                      </div>

                    </div>


                  </div>

                </div>

              </div>
              {/* </div> */}
              {/* </div> */}
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <EventsSkeleton />;
    }
  }
}



const mapStateToProps = (state) => {
  return {
    contests: state.contestData.contests,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContests: () => dispatch(fetchAllContests()),
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(UserContestList);
