// import { sendEmail } from "../../../../../server/utils/utils";
import {
    sessionCheck,
    gradeSubmission,
    updateJudges,
    pushJudge,
    sendEmail,
    removeJudge
} from "../../api/judge"

import { fireNotification } from "./notificationsActions";


export const checkSession = (data) => {
    return dispatch => {
        return sessionCheck(data)
            .then(response => {
                dispatch(fireNotification(response))
                const session = response.data;
                dispatch({
                    type: "SESSION_CHECK",
                    session
                });
                return response.data
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};

export const judgeSubmission = (body) => {
    return dispatch => {
        return gradeSubmission(body)
            .then(response => {
                // const contests = response.data;
                const rating = response.data
                dispatch({
                    type: "SUBMISSION_RATING",
                    rating
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};


export const createJudge = (event, judge) => {
    return dispatch => {
        return pushJudge({ eventId: event, ...judge })
            .then(response => {
                // const contests = response.data;
                const judges = response.data
                dispatch({
                    type: "CREATE_JUDGE",
                    judges
                });
                return Promise.resolve(judges);
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};

export const updateJudge = (data) => {
    return dispatch => {
        return updateJudges(data)
            .then(response => {
                // const contests = response.data;
                const newJudge = response.data
                dispatch({
                    type: "UPDATE_JUDGE",
                    newJudge
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};

export const sendJudgeEmail = (judge) => {
    return dispatch => {
        return sendEmail({ judgeSession: judge })
            .then(response => {
                dispatch(fireNotification(response))
                dispatch({
                    type: "SEND_EMAIL",

                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};

export const deleteJudge = (judge) => {
    return dispatch => {
        return removeJudge(judge)
            .then(response => {
                dispatch(fireNotification(response))
                const judges = response.data
                dispatch({
                    type: "REMOVE_JUDGE",
                    judges
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};
