import React, { Component } from "react";
import LeaderBoard from "../../components/Leaderboard";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fecthAllRankData } from "../../store/actions/rankActions";
import Data404 from "./Data404";
import LeaderBoardSkeleton from "../../components/skeletons/LeaderboardSkeleton";

class Rankings extends Component {
  state = {
    isValid: false,
    isLoaded: false
  };

  validate() {
    if (this.props.users.length > 0) {
      this.setState({
        isValid: true
      });
    }
  }

  componentDidMount() {

    this.props
      .getUsers()
      .then(() =>
        this.setState({
          isLoaded: true
        })
      )
      .then(() => this.validate());
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content" style={{ paddingTop: "50px" }}>
            <div className="container-fluid">
              {/* <!-- Top header --> */}
              <div className="row">
                <div className="col-xl-12 col-sm-12">
                  {this.state.isValid ? (
                    <LeaderBoard
                      hasData={this.state.isValid}
                      users={this.props.users}
                    />
                  ) : (
                    <Data404 />
                  )}
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return (
        <div className="main-content">
          <div className="page-content" style={{ paddingTop: "50px" }}>
            <div className="container-fluid">
              {/* <!-- Top header --> */}
              <div className="row">
                <div className="col-xl-12 col-sm-12">
                  <LeaderBoardSkeleton />
                </div>
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    users: state.rankData.allRanks
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => dispatch(fecthAllRankData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Rankings));
