import axios from "axios";
//import { request } from "https";
import Cookies from "js-cookie";


export const BASE_URL = "https://skillsranker.bih.co.bw/v1/api/";
  // window.location.origin + ":7000/v1/api/";
   //"https://13.245.11.157:8080/v1/api/";
  //"http://13.244.137.55/v1/api/";
  
  //"http://13.245.19.18/v1/api/";

// "https://adept-fx-271407.appspot.com/v1/api/";
 //"http://localhost:8081/v1/api/";
// "http://45dd-168-167-25-113.ngrok.io/v1/api/";

export const IMAGE_BASE_URL = BASE_URL.replace("v1/api/", "");
//var access_token = Cookies.get("skillsranker");
export const client = axios.create({
  baseURL: BASE_URL,
  useCredentials: true,
  headers: {
    api_key: "API-R34HEYC3RYGWF3HBDUYSEF",
    // Authorization: `Bearer ${access_token}`
  }
});

client.interceptors.response.use(
  (response) => 
  {
    if (response.headers.isrefreshed === "true") 
    {
      Cookies.set("skillsranker", response.headers.token)
    }
    return response
  },
  (error) => {
    if (error.response && error.response.headers.isrefreshed === "true") Cookies.set("skillsranker", error.response.headers.token)

    if (error.response && error.response.status === 403) {
      if (error.response && error.response.headers.isrevoked === "true") {
        // remove cookie
        Cookies.remove("skillsranker")
        window.location.href = "/login"
      }
      return Promise.reject(error.response.data || "Operation unsuccessfull, insufficient permissions to perform task")
    }
    if (error.response && error.response.status === 400) {
      return Promise.reject(error.response.data)
    }
    else {
      return Promise.reject("Something went wrong, Operation unsuccessfull")
    }
  })

client.interceptors.request.use(req => {
  var access_token = Cookies.get("skillsranker");
  req.headers = { ...req.headers, Authorization: `Bearer ${access_token}` }
  // Important: request interceptors **must** return the request.
  return req;
});