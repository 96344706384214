const initState = {
    eventRequests: [],
    contest: {},
    contestInfo: {}
};

const eventRequestReducer = (state = initState, action) => {
    switch (action.type) {
        case "GET_ALL_EVENT_REQUESTS":
            return {
                ...state,
                eventRequests: action.requests
            };

        case "CONTEST_RESPONSE_INFO":
            return {
                ...state,
                contestInfo: { ...action.response_info }
            };

        case "GET_CONTEST":
            return {
                ...state,
                contest: { ...action.contest }
            };
        default:
            break;
    }
    return state;
};

export default eventRequestReducer;