import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
} from "react-router-dom";
import Cookies from "js-cookie";
import Cookie from "js-cookie";
import { withRouter } from "react-router-dom";
import UserProfile from "../containers/pages/UserProfile";
import ReactGA from 'react-ga';
import UserContestList from "../containers/pages/UserContestList";
import UserContest2 from "../containers/pages/UserContest2";
import HackathonRegistration from "../containers/pages/HackathonRegistration";
import UpdateProfile from "../containers/pages/UpdateProfile";
import BusinessProfilePage from "../containers/pages/BusinessProfilePage";
import BusinessInvites from "../containers/pages/BusinessInvites";
import { getBusinessProfileList } from "../api/businessProfile";

class BusinessWrapper extends Component {


  state = {
    businessProfiles: []
  };

  async componentDidMount() {
    let user = Cookies.get("user");
    user = JSON.parse(user)
    const profiles = (await getBusinessProfileList(user._id)).data;
    console.log(profiles);
    this.setState({ user, businessProfiles: profiles })
  }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
      <div id="layout-wrapper">
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              {/* <!-- LOGO --> */}
              <div className="navbar-brand-box" style={{ padding: "0px" }}>
                <NavLink
                  to="/"
                  className="logo logo-dark"
                  style={{ color: "white" }}
                >
                  <span className="logo-sm">
                    <img
                      src="/assets/images/logo-icon.svg"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src="/assets/images/logo-dark.png"
                      alt=""
                      height="17"
                    />
                  </span>
                </NavLink>
                <NavLink
                  to="/"
                  className="logo logo-light"
                  style={{ color: "white" }}
                >
                  <span className="logo-sm">
                    <img
                      src="/assets/images/logo-icon.svg"
                      alt=""
                      height="22"
                    />
                  </span>
                  <span className="logo-lg" style={{ marginLeft: "-20px" }}>
                    <img
                      src="/assets/images/logo-light.svg"
                      alt=""
                      height="150"
                    />
                  </span>
                </NavLink>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            <div className="d-flex" id="menu">
              {/* <div className="dropdown d-inline-block">
                <NavLink
                  className="disabled"
                  onClick={(e) => e.preventDefault()}
                  to="/jobs"
                  style={{ color: "white" }}
                >
                  <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <span className="d-none d-lg-inline-block ml-1">Jobs</span>
                  </button>
                </NavLink>
              </div>
              <div className="dropdown d-inline-block">
                <NavLink
                  className="disabled"
                  onClick={(e) => e.preventDefault()}
                  to="/learning"
                  style={{ color: "white" }}
                >
                  <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <span className="d-none d-lg-inline-block ml-1">
                      Learning
                    </span>
                  </button>
                </NavLink>
              </div> */}
              <div className="dropdown d-inline-block">
                <NavLink to="/business" style={{ color: "white" }}>
                  <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <span className="d-none d-lg-inline-block ml-1">
                      My Business
                    </span>
                  </button>
                </NavLink>
              </div>

              {
                this.state.businessProfiles.length > 0 ?
                  <div className="dropdown d-inline-block">
                    <NavLink to="/business/hackathon" style={{ color: "white" }}>
                      <button
                        type="button"
                        className="btn header-item noti-icon right-bar-toggle waves-effect"
                      >
                        <span className="d-none d-lg-inline-block ml-1">Hackathons</span>
                      </button>
                    </NavLink>
                  </div>
                  :
                  ""
              }

              {/* <div className="dropdown d-inline-block">
                <NavLink
                  className="disabled"
                  onClick={(e) => e.preventDefault()}
                  to="/hackathons"
                  style={{ color: "white" }}
                >
                  <button
                    type="button"
                    className="btn header-item noti-icon right-bar-toggle waves-effect"
                  >
                    <span className="d-none d-lg-inline-block ml-1">
                      Hackathons
                    </span>
                  </button>
                </NavLink>
              </div> */}
              <div className="dropdown d-inline-block">
                {/* <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => this.props.history.push('/profile')}>
                                <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar"/>
                                <span className="d-none d-xl-inline-block ml-1">Henry</span>
                                
                            </button> */}
                <NavLink
                  to="/business"
                  className="btn header-item waves-effect"
                  style={{ verticalAlign: "middle", paddingTop: "16px" }}
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src={
                      JSON.parse(Cookie.get("user")).githubData
                        ? JSON.parse(Cookie.get("user")).githubData.avatar_url
                        : "/assets/images/users/avatar.png"
                    }
                    alt="Header Avatar"
                  />
                  <span className="d-none d-xl-inline-block ml-1">
                    {JSON.parse(Cookie.get("user")).name.firstName +
                      " " +
                      JSON.parse(Cookie.get("user")).name.lastName}
                  </span>
                </NavLink>
              </div>
              <div className="dropdown d-inline-block">
                <button
                  onClick={logout}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-power-off"></i>
                </button>
              </div>
            </div>
          </div>
        </header>
        <div className="topnav">
          <div className="container-fluid">
            <nav className="navbar navbar-light topnav-menu">
              <div
                className="navbar-collapse collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink to="/#" className="nav-link">
                      Home
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink to="/learning" className="nav-link">
                      Learning
                    </NavLink>
                  </li> */}
                  {
                    this.state.businessProfiles.length > 0 ?
                      <li className="nav-item">
                        <NavLink to="/business/hackathon" className="nav-link">
                          Hackathons
                        </NavLink>
                      </li>
                      :
                      ""
                  }

                  <li className="nav-item">
                    <NavLink to="/business" onClick={() => window.location.href = "/business"} className="nav-link">
                      My Business
                    </NavLink>
                  </li>

                  {/* <li className="nav-item">
                    <NavLink to="/hackathons" className="nav-link">
                      Hackathons
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/jobs" className="nav-link">
                      Jobs
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink to="/business" className="nav-link">
                      Profile
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        <Switch>

          <Route
            exact
            path={"/business/update"}
            component={UpdateProfile}

          />
          <Route exact path={"/business/hackathon"} component={UserContestList} />
          <Route exact path={"/business"} component={BusinessProfilePage} />
          <Route path={"/business/invites/:inviteId"} component={BusinessInvites} />
          {/* <Route exact path={"/business"} component={Assesments} /> */}
          <Route path={"/business/hackathon/:id"} component={UserContest2} exact />
          <Route path="/business/hackathon/register/:id" component={HackathonRegistration} />
          <Route path="/business/developers/:id" component={UserProfile} />
        </Switch>
      </div>
    );
  }
}
/**
 * remove cookie with user info and redirect to landing page
 */

function logout() {
  Cookies.remove("user");
  Cookies.remove("skillsranker");
  window.location = "/";
}



export default withRouter(BusinessWrapper);
