import Cookies from "js-cookie";

import {
  login,
  signup,
  forgotPassword,
  resetPassword,
  setupAccount
} from "../../api/authService";
import {
  getGithubProfile
} from "../../api/profile";
import { getUser2 } from "../../api/user";
import moment from "moment";
import queryString from 'query-string';

/**
 * Login user and dispatch action
 * @param loginDetails details
 */
export const loginUser = loginDetails => {
  return dispatch => {
    return login(loginDetails)
      .then(response => {
        try {

          //get profile
          // delete response.data.user.name
          let user = JSON.stringify(response.data.user);

          const weekLater = (new Date(moment().add("1", "week").utc()));

          Cookies.set("skillsranker", response.data.access_token, {
            secure: true,
            expires: weekLater,
            sameSite: true
          });
          // delete user.name
          return getUser2(response.data.access_token)
            .then((response) => {
              // const user =
              //   typeof response.data === "string" ?
              //     JSON.parse(response.data) :
              //     response.data;

              // if (user.email) {
              Cookies.remove("user")
              Cookies.set("user", user, {
                secure: true,
                expires: weekLater,
                sameSite: true
              });

              dispatch({
                type: "LOGIN_USER",
                user
              });

              const redirect = queryString.parse(window.location.search).redirect;

              if (!!redirect) {
                window.location = unescape(redirect);
              } else {
                window.location = "/profile";
              }
              // window.location = "/profile";
              // }
            })
            .catch(err => {
              return Promise.reject(err)
            })
        } catch (error) {
          console.log(error);
        }
      })
      .catch(err => { return Promise.reject(err) });
  };
};

/**
 * Sign up user and dispatch action
 * @param signUpDetails details
 */
export const signUpUser = signUpDetails => {
  return dispatch => {
    return signup(signUpDetails)
      .then(response => {
        let user;
        try {
          user =
            typeof response.data === "string" ?
              JSON.parse(response.data) :
              response.data;
        } catch (error) {
          user = response.data;
        } finally {
          if (user) {
            Cookies.set("user", user);
            window.location = "/profile";
            dispatch({
              type: "SIGNUP_USER",
              user
            })
          } else {
            return Promise.reject("Failed to create account. Please try again.")
          }
        }
      })
      .catch(err => {
        console.log(err);
        return Promise.reject(err);
      });
  };
};

/**
 * Sign up user and dispatch action
 * @param signUpDetails details
 */
export const setupUserAccount = body => {
  return dispatch => {
    return setupAccount(body)
      .then(response => {
        const user = response.data;
        Cookies.set("user", user);
        window.location = "/profile";
        dispatch({
          type: "SET_USER",
          user
        });
      })
      .catch(err => Promise.reject(err));
  };
};


export const setupOrganisationAccount = body => {
  return dispatch => {
    return setupOrganisationAccount(body)
      .then(response => {
        const user = response.data;
        Cookies.set("user", user);
        window.location = "/profile/sponsor";
        dispatch({
          type: "SET_USER",
          user
        });
      })
      .catch(err => Promise.reject(err));
  };
};

/**
 * Forgot password
 * @param email details
 */
export const forgotUserPassword = email => {
  return dispatch => {
    return forgotPassword({
      email
    })
      .then(response => {
        dispatch({
          type: "RECOVER_PASSWORD"
        });
      })
      .catch(err => Promise.reject(err));
  };
};

/**
 * Reset password
 * @param body {token and passwords}
 */
export const resetUserPassword = body => {
  return dispatch => {
    return resetPassword({
      ...body
    })
      .then(response => {
        dispatch({
          type: "RESET_PASSWORD"
        });
      })
      .catch(err => Promise.reject(err));
  };
};

export const githubProfie = user => {
  return dispatch => {
    return getGithubProfile(user)
      .then(response => {
        dispatch({
          type: "GET_GITHUB_USER"
        });
      })
      .catch(err => Promise.reject(err));
  };
};