import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { fetchAdminUsers } from "../store/actions/usersActions";
import { client } from "../api/client";
import ReactDOM from "react-dom";

class AdminUsersList extends Component {

    state = { submitting: [], datatable: null };

  constructor(props) {
    super(props);
    this.disableUser = this.disableUser.bind(this);
    this.createButton = this.createButton.bind(this);
    this.state.submitting = [];
  }

    createButton(id, status) {
        // console.log(typeof status, status);
    
        return this.state.submitting.indexOf(`${id}`) === -1 ? (
          <button
            style={{
              padding: "2px 14px 2px 14px",
              margin: "0px 14px 0px 14px",
            }}
            className="btn btn-secondary"
            onClick={this.disableUser.bind(this, `${id}`, status)}
          >
            {!status ? "Disable" : "Enable"}
          </button>
        ) : (
          "Submitting..."
        );
      }

      disableUser(userId, status) {
        console.log(userId);
        this.setState({submitting:this.state.submitting.concat(userId)});
        client.put("/user/status", { userId, status }).then((res) => {
          this.state.submitting.splice(this.state.submitting.indexOf(userId), 1);
          this.state.datatable
            .clear()
            .rows.add(
              res.data.map((user) => ({
                ...user,
                //   title: user.titles && user.titles.length > 0 ? user.titles[0] : "",
                names: user.name
                  ? user.name.firstName + " " + user.name.lastName
                  : "",
                location: user.location ? user.location : "",
              }))
            )
            .draw();
        });
      }


    componentDidMount() {
        const self = this;
          this.state.datatable = window.$("#datatable-buttons").DataTable({
            buttons:["excel","pdf"],
            data: this.props.users.map((user) => ({
              ...user,
              //   title: user.titles && user.titles.length > 0 ? user.titles[0] : "",
              names: user.name
                ? user.name.firstName + " " + user.name.lastName
                : "",
              location: user.location ? user.location : "",
            })),
            sort: false,
            columns: [
              {
                createdCell: (td) => {
                  window.$(td).css("padding", "2px 0px 0px 14px");
                },
                data: "accountStatus.status",
                render: (_data) =>
                  Boolean(_data)
                    ? "<i style='font-size:x-large' class='mdi mdi-account-check text-success'></i>"
                    : "<i style='font-size:x-large' class='mdi mdi-account text-secondary'></i>",
              },
              { data: "email" },
              { data: "names" },
              // { data: "createdAt" },
              // { data: "titles" },
              // { data: "location" },
              {
                data: "_id",
                createdCell: (td, _data, rowData) =>
                  ReactDOM.render(
                    self.createButton(_data, !rowData.accountStatus.status),
                    td
                  ),
              },
            ],
          }).buttons().container().appendTo("#datatable-buttons_wrapper .col-md-6:eq(0)");
      }

    disable(key){
        console.log(key)
    }

    render() {
        return (
          <div className="col-xl-12">
            <div className="card">
            <div className="card-body">
              <table
                id="datatable-buttons"
                className="table table-striped table-bordered dt-responsive nowrap"
                style={{
                  borderCollapse: "collapse",
                  borderSpacing: 0,
                  width: "100%",
                }}
              >
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Email</th>
                    <th>Names</th>
                    {/* <th>Created On</th> */}
                    <th>Titles</th>
                    <th>Location</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
          </div>
        );
      }
}
const mapStateToprops = (state) =>{
  return{
    users : state.admin.adminUsersList
  }
}

const mapDispatchToprops =(dispatch) => {
  return {
    getUsers:() => dispatch(fetchAdminUsers())
  }
}

export default connect(mapStateToprops, mapDispatchToprops)(withRouter(AdminUsersList));
