import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { ResponsiveLine } from '@nivo/line'
import {getData} from '../api/googleAnalytics'
import ExcelExport from "./ExcelExport"
import PDFExport from "./PDFExport";

class DynamicGraphBlade extends Component {

    state = {
      data:[]
    }


    /**
     * construct GA query and call analytics end point 
     * to get pageviews by date over the last 20days
     */
    componentDidMount(){

        let requestData = {
            startDate: '20daysAgo',
            endDate: 'today',
            metrics: 'ga:pageviews',
            dimensions:'ga:date'
          }
        getData(requestData)
        .then((res) => this.handleData(res.data))
        .catch((err) => console.log(err))
        
    }

    formatDate(date){
        var stringDate = new Date(date.slice(0,4)+'-'+date.slice(4, 6) + '-' +date.slice(6,8)).toDateString()
        
        stringDate = stringDate.split(" ")
        
        return stringDate[2]+ ' ' +stringDate[1]
    }


    /**
     * Format response data to make it compatible with expected graph input data,
     * then update component state.
     * 
     * @param  {} response GA response data
     */
    handleData(response){
        var newData = []
        response.rows.map(dataPoint => newData.push({x:this.formatDate(dataPoint[0]), y:dataPoint[1]}) )
        var data = [{
          'id':'hits',
          'color': 'blue',
          'data':newData,
        }]
        this.setState({data:data})
    }
  
   render(){

    
        return (
            
            <div className="card">
                <div className="card-body" id="Platfrom_Activity">
                    <h4 className="card-title mb-4 float-sm-left">Plaform Activity</h4>
                    <div className="float-sm-right">
                        <ul className="nav nav-pills">
                            <li className="nav-item pl-2" >
                                <PDFExport title="Platfrom_Activity" />
                            </li>
                            <li className="nav-item pl-2" >
                                <ExcelExport fileName="Platfrom Activity" csvData={this.state.data[0]}/>
                            </li>
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                    {
                        this.state.data.length > 0 ?
                            <>
                            {/* <div className="float-sm-right">
                                <ul className="nav nav-pills">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Week</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#">Month</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#">Year</a>
                                    </li>
                                </ul>
                            </div> */}
                            <div className="clearfix"></div>
                            <div style={{height:'25rem'}}>
                            <ResponsiveLine
                                data={this.state.data}
                                margin={{ top: 25, right: 110, bottom: 50, left: 60 }}
                                xScale={{ type: 'point' }}
                                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    orient: 'bottom',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 45,
                                    legend: 'Date',
                                    legendOffset: 60,
                                    legendPosition: 'middle'
                                }}
                                axisLeft={{
                                    orient: 'left',
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'hits',
                                    legendOffset: -40,
                                    legendPosition: 'middle'
                                }}
                                colors={{ scheme: 'set2' }}
                                pointSize={10}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabel="date"
                                pointLabelYOffset={-12}
                                useMesh={true}
                                legends={[
                                    {
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 100,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemDirection: 'left-to-right',
                                        itemWidth: 80,
                                        itemHeight: 20,
                                        itemOpacity: 0.75,
                                        symbolSize: 12,
                                        symbolShape: 'circle',
                                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            />
                            </div>
                            </>
                        :
                            <>
                            <i className="bx bx-cloud-snow" style={{fontSize:'4rem', width:'100%', textAlign:'center'}}></i>
                            <p className="mb-5" style={{fontSize:'.8rem', width:'100%', textAlign:'center', marginTop:'.8rem'}}>no data</p> 
                            </>
                    }
                    
                </div>
            </div>
                
        
        );
    
    }
}

export default withRouter(DynamicGraphBlade);
