import React, { Component } from "react";
import { fetchAppUsers, fetchAppUsersDemographics } from "../../store/actions/usersActions";
import { fecthAllRankData } from "../../store/actions/rankActions";
import { fetchAllContests } from "../../store/actions/contestActions";
import Cookie from "js-cookie";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import SkillsBlade from "../../components/SkillsBlade";
import DashboardUserRankBlade from "../../components/DashboardUserRankBlade";
import DynamicGraphBlade from "../../components/DynamicGraphBlade";
import DemographicsBlade from "../../components/DemographicsBlade";
import UserDeviceMapping from "../../components/UserDeviceMapping";

class DashBoard extends Component {

    state = {
        isLoaded: true,
        femaleUserCount: 0,
        maleUserCount: 0,
        userCount: 0,
        userRanks: [],
        femaleUserRank: [],
        skillsDistributionData: [
            {
                key: '',
                skill: '',
                count: '',
            },
        ],
        hackathons: [
            {
                id: '',
                title: '',
                date: '',
                info: '',
                rules: '',
                prizes: '',
                tags: '',
                type: '',
            },
        ],
        projectsCount: ''
    };




    /**
     * Get users list after component mounts
     */
    componentDidMount() {
        // this.props.getUsers()
        //     .then(() => this.handleUserStats(this.props.users))

        this.props.getAppUsersDemographics()
            .then(() => this.setState(
                {
                    femaleUserCount: this.props.userDemographics.female,
                    maleUserCount: this.props.userDemographics.male,
                    userCount: this.props.userDemographics.total,
                    other: this.props.userDemographics.other
                }
            ))
    }



    /**
     * returns count of all projects by all users
     * 
     * @param  {Array} users
     */
    handleProjects(users) {
        let count = 0
        if (Array.isArray(users) && users.length) {
            users.forEach(user => {
                if (user.githubData)
                    count = count + user.githubData.public_repos
            });
        }
        return count
    }


    /**
     * returns count of users by category
     *  - male
     *  - female
     *  - all (male and female)
     * @param  {} users
     */
    handleUserStats(users) {
        if (Array.isArray(users) && users.length) {


            let femaleUsers = users.filter((user) => user.gender === 'Female')

            // users.map(user => console.log(user, user.gender))
            // some users dont have assigned genders

            if (typeof femaleUsers === undefined) { femaleUsers = 0 } else { femaleUsers = femaleUsers.length }

            let maleUsers = users.filter((user) => user.gender === 'Male')
            if (typeof maleUsers === undefined) { maleUsers = 0 } else { maleUsers = maleUsers.length }

            let tatalUsers = femaleUsers + maleUsers

            this.setState({ userCount: tatalUsers, femaleUserCount: femaleUsers, maleUserCount: maleUsers })
            // return {users: tatalUsers, female: femaleUsers, male:maleUsers}

        } else {
            // return {users: 0, female: 0, male:0}
            // console.log(users);

            this.setState({ userCount: 0, femaleUserCount: 0, maleUserCount: 0 })
        }

    }


    render() {
        if (this.state.isLoaded) {

            return (
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0 font-size-18">Dashboard</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item active">Welcome to Dashboard</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-4">
                                    <div className="card overflow-hidden">
                                        <div className="bg-soft-primary">
                                            <div className="row">
                                                <div className="col-7">
                                                    <div className="text-primary p-3">
                                                        <h5 className="text-primary">Welcome Back !</h5>
                                                        <p>Skills ranker Dashboard</p>
                                                    </div>
                                                </div>
                                                <div className="col-5 align-self-end">
                                                    <img src="/assets/images/profile-img.png" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-sm-5">
                                                    <div className="avatar-md profile-user-wid mb-4">
                                                        <img src={
                                                            JSON.parse(Cookie.get("user")).githubData
                                                                ? JSON.parse(Cookie.get("user")).githubData.avatar_url
                                                                : "/assets/images/users/avatar.png"
                                                        }
                                                            alt=""
                                                            className="avatar-md img-thumbnail rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="font-size-15 text-truncate">
                                                        {JSON.parse(Cookie.get("user")).name.firstName +
                                                            " " +
                                                            JSON.parse(Cookie.get("user")).name.lastName}
                                                    </h5>
                                                    <p className="text-muted mb-0 text-truncate">Admin</p>
                                                </div>

                                                <div className="col-sm-7">
                                                    <div className="pt-4">

                                                        <div className="row">
                                                            <div className="col-6">
                                                                <h5 className="font-size-15">{this.handleProjects(this.props.users)}</h5>
                                                                <p className="text-muted mb-0">Projects</p>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <a onClick={() => this.props.history.push('/admin/app-users')} style={{ color: 'white' }} className="btn btn-primary waves-effect waves-light btn-sm">View Users <i className="mdi mdi-arrow-right ml-1"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <DashboardUserRankBlade title={'Top 5 Users'} callback={this.props.getUsersRankings} data={this.props.usersRankings} />
                                </div>
                                <div className="col-xl-8">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="card mini-stats-wid">
                                                <div className="card-body">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <p className="text-muted font-weight-medium">Users</p>
                                                            <h4 className="mb-0">{this.state.userCount}</h4>
                                                        </div>

                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                            <span className="avatar-title">
                                                                <i className="bx bx-user font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card mini-stats-wid">
                                                <div className="card-body">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <p className="text-muted font-weight-medium">Male</p>
                                                            <h4 className="mb-0">{this.state.maleUserCount}</h4>
                                                        </div>

                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-male-sign font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card mini-stats-wid">
                                                <div className="card-body">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <p className="text-muted font-weight-medium">Female</p>
                                                            <h4 className="mb-0">{this.state.femaleUserCount}</h4>
                                                        </div>

                                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                            <span className="avatar-title rounded-circle bg-primary">
                                                                <i className="bx bx-female-sign font-size-24"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- end row --> */}

                                    <DemographicsBlade />

                                </div>
                            </div>
                            {/* <!-- end row --> */}

                            <div className="row">
                                {/* <div className="col-xl-4">
                            <TimelineList data={this.props.contests} callback={this.props.getContests} title={'Hackathons'} />
  
                        </div> */}

                                <div className="col-xl-12">
                                    <SkillsBlade title={'Skills Distribution'} data={this.props.users} callback={this.props.getUsers} />
                                </div>


                            </div>
                            {/* <!-- end row --> */}

                            <div className="row">
                                <div className="col-lg-4"></div>
                                <div className="col-lg-8">
                                    {/* <DemographicsBlade /> */}

                                    <DynamicGraphBlade />

                                </div>
                            </div>
                            {/* <!-- end row --> */}
                            <div className="row">


                                <div className="col-xl-12">
                                    <UserDeviceMapping />
                                </div>


                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}



                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                                <div className="col-sm-6">
                                    <div className="text-sm-right d-none d-sm-block">
                                        Skills Ranker
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            );

        } else {
            return (
                <AssesmentsSkeleton />
            );
        }

    }
}

const mapStateToProps = (state) => {
    return {
        users: state.admin.appUsersList,
        usersRankings: state.rankData.allRanks,
        contests: state.contestData.contests,
        userDemographics: state.admin.userDemographics
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(fetchAppUsers()),
        getUsersRankings: () => dispatch(fecthAllRankData()),
        getContests: () => dispatch(fetchAllContests()),
        getAppUsersDemographics: () => dispatch(fetchAppUsersDemographics())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
