const initState = {
  userAssesmentsList: [],
  globalAssesmentsList: [],
  targetAssessment: {},
  assessmentInfo:''
};

const assesmentsReducer = (state = initState, action) => {
  switch (action.type) {
    case "PULL_USER_ASSESMENTS":
      return {
        ...state,
        userAssesmentsList: action.data
      };
    case "GET_COMPLETED_ASSESSMENTS":
      return {
        ...state,
        completedAssesments: action.data
      };
    case "PULL_GLOBAL_ASSESMENTS":
      return {
        ...state,
        globalAssesmentsList: action.data
      };
    case "PULL_ASSESMENT":
      return {
        ...state,
        targetAssessment: {
          ...action.data
        }
      };
      case "ASSESSMENT_RESPONSE_INFO":
      return {
        ...state,
        assessmentInfo: {...action.response_info}
      };

    default:
      break;
  }
  return state;
};

export default assesmentsReducer;