import {
    newTeam,
    getEventTeams,
    getEventTeam,
    updateEventTeam,
    removeTeam,
    // changeTeamStatus,
    joinTeam,
    getUserTeam,
    updateMembers,
    updateUserConsent,
    updateTeamElimination,
    changeSuspensionStatus,
    sendInvite,
    validateUserEmail,
    checkInvite,
    pushInviteResponse,
    exitTeam,
    removeTeamMember,
    makeTeamLeader,
    getEventEliminatedTeams,
    changeComplianceStatus
} from "../../api/teams"

import { fireNotification } from "./notificationsActions";


export const publishTeam = (data) => {
    return dispatch => {
        return newTeam(data)
            .then(response => {
                dispatch(fireNotification(response))
                const team = response.data;
                dispatch({
                    type: "PUBLISH_TEAM",
                    team
                });
                return response.data
            })
            .catch(err => {dispatch(fireNotification(err)); Promise.reject(err)});
    };
};

export const fetchAllEventTeams = (eventId) => {
    return dispatch => {
        return getEventTeams(eventId)
            .then(response => {
                // const contests = response.data;
                const teams = response.data
                dispatch({
                    type: "GET_ALL_EVENT_TEAMS",
                    teams
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};
export const fetchAllEliminatedEventTeams = (eventId) => {
    return dispatch => {
        return getEventEliminatedTeams(eventId)
            .then(response => {
                // const contests = response.data;
                const teams = response.data
                dispatch({
                    type: "GET_ALL_EVENT_ELIMINATED_TEAMS",
                    teams
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};

export const fetchTeam = (teamId) => {
    return dispatch => {
        return getEventTeam(teamId)
            .then(response => {
                const team = response.data;
                dispatch({
                    type: "GET_TEAM",
                    team
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};


export const updateTeam = (teamId, data) => {
    return dispatch => {
        return updateEventTeam(teamId, data)
            .then(response => {
                const team = response.data;
                dispatch({
                    type: "UPDATE_TEAM",
                    team
                });
            })
            .catch(err => {
                Promise.reject(err)
            });
    };
};

export const changeTeamSuspensionStatus = (data) => {
    return dispatch => {
        return changeSuspensionStatus(data)
            .then(response => {
                const team = response.data;
                dispatch({
                    type: "UPDATE_TEAM_STATUS",
                    team
                });
            })
            .catch(err => {
                Promise.reject(err)
            });
    };
};


export const changeTeamComplianceStatus = (data) => {
    return dispatch => {
        return changeComplianceStatus(data)
            .then(response => {
                const team = response.data;
                dispatch({
                    type: "UPDATE_TEAM_STATUS",
                    team
                });
            })
            .catch(err => {
                return Promise.reject(err)
            });
    };
};

export const deleteTeam = (teamId) => {
    return dispatch => {
        return removeTeam(teamId)
            .then(response => {
                const team = response.data;
                dispatch({
                    type: "DELETE_TEAM",
                    team
                });
            })
            .catch(err => {
                Promise.reject(err)
            });
    };
};


export const updateEliminationStatus = (data) => {
    return dispatch => {
        return updateTeamElimination(data)
            .then(response => {
                const team = response.data;
                dispatch(fireNotification(response))
                dispatch({
                    type: "UPDATE_TEAM_STATUS",
                    // data: response.data,
                    team
                });
            })
            .catch(err => {
                Promise.reject(err)
            });
    };
};


export const joinExistingTeam = (teamId, user, eventId) => {
    return dispatch => {
        return joinTeam({ teamId: teamId, memberId: user, eventId: eventId })
            .then(response => {
                dispatch(fireNotification(response))
                dispatch({
                    type: "GET_TEAM",
                    data: response.data,
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
};

export const getEventUserTeam = (userId, eventId) => {
    return dispatch => {
        return getUserTeam(userId, eventId)
            .then(response => {
                dispatch(fireNotification(response))
                dispatch({
                    type: "GET_TEAM",
                    team: response.data,
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const updateMember = (team, requestingParty, isApproved) => {
    return dispatch => {
        return updateMembers({ teamId: team, userId: requestingParty, isApproved: isApproved })
            .then(response => {
                dispatch(fireNotification(response))
                dispatch({
                    type: "UPDATE_MEMBERS",
                    team: response.data,
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const updateConsent = (user, event) => {
    return dispatch => {
        return updateUserConsent({ user: user, event: event })
            .then(response => {
                dispatch(fireNotification(response))
                dispatch({
                    type: "UPDATE_USER_CONSENT",
                    team: response.data,
                });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const pushEventInvite = (body) => {
    return dispatch => {
        return sendInvite(body)
            .then(response => {
                dispatch(fireNotification(response))
                return response.data
                // dispatch({
                //     type: "UPDATE_USER_CONSENT",
                //     team: response.data,
                // });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const validateEmail = (body) => {
    return dispatch => {
        return validateUserEmail(body)
            .then(response => {
                dispatch(fireNotification(response))
                return response.data
                // dispatch({
                //     type: "UPDATE_USER_CONSENT",
                //     team: response.data,
                // });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const validateInvite = (body) => {
    return dispatch => {
        return checkInvite(body)
            .then(response => {
                dispatch(fireNotification(response))
                return response.data
                // dispatch({
                //     type: "UPDATE_USER_CONSENT",
                //     team: response.data,
                // });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const pushInviteAcceptence = (body) => {
    return dispatch => {
        return pushInviteResponse(body)
            .then(response => {
                dispatch(fireNotification(response))
                return response.data
                // dispatch({
                //     type: "UPDATE_USER_CONSENT",
                //     team: response.data,
                // });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const leaveTeamWilingly = (body) => {
    return dispatch => {
        return exitTeam(body)
            .then(response => {
                dispatch(fireNotification(response))
                return response.data
                // dispatch({
                //     type: "UPDATE_USER_CONSENT",
                //     team: response.data,
                // });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const eliminateTeamMember = (body) => {
    return dispatch => {
        return removeTeamMember(body)
            .then(response => {
                dispatch(fireNotification(response))
                return response.data
                // dispatch({
                //     type: "UPDATE_USER_CONSENT",
                //     team: response.data,
                // });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}

export const makeLeader = (body) => {
    return dispatch => {
        return makeTeamLeader(body)
            .then(response => {
                dispatch(fireNotification(response))
                return response.data
                // dispatch({
                //     type: "UPDATE_USER_CONSENT",
                //     team: response.data,
                // });
            })
            .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)));
    };
}