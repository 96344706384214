import {
  getLanguages,
  updateUserLanguages,
  getAllLanguages,
  createLang,
  deleteLang,
  updateLang,
} from "../../api/languages";
import Cookies from "js-cookie";
import {
  fireNotification
} from "./notificationsActions";



/**
 * get user languages
 */
export const fetchLanguages = () => {
  return dispatch => {
    const languages = JSON.parse(Cookies.get("user")).programmingLanguages;
    // console.log(...languages)

      // var newLanguages = '';
      // newLanguages = languages.map(i => (
      //   newLanguages+','+i
      // ))
      
      return getLanguages(languages)
      .then(response => {
          return dispatch({
            type: "PULL_LANGUAGES",
            data: response.data
          })
        });
    
  };
};




/**
 * fetch all registered languages
 */
export const fetchAllLanguages = () => {
  return dispatch => {
    return getAllLanguages()
      .then(response => {
        return dispatch({
          type: "PULL_ALL_LANGUAGES",
          data: response.data,
        })
      })
      .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
  };
};


/**
 * update user languages
 * 
 * @param  {Dict} body details
 */
export const updateLanguages = (body) => {
  return dispatch => {
    return updateUserLanguages(body)
      .then(response => {
        return dispatch({
          type: "UPDATE_LANGUAGES",
          data: response.data
        });

      })
      .catch(err => Promise.reject(err));
  };
};


/**
 * create a new language
 * 
 * @param  {Dict} body details
 */
export const pushLangs = (body) => {
  return dispatch => {
    return createLang(body)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "CREATE_LANGUAGE",
          data: response.data 
        });

      })
      .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
  };
};


 
/**
 * delete a language
 * 
 * @param  {} id language id
 */
export const killLangs = (id) => {
  return dispatch => {
    return deleteLang(id)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "DELETE_LANGUAGE",
          data: response.data
        });

      })
      .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
  };
};


/**
 * update a single language
 * 
 * @param  {dict} body details
 */
export const langUpdate = (body) => {
  return dispatch => {
    return updateLang(body)
      .then(response => {
        dispatch(fireNotification(response))
        return dispatch({
          type: "UPDATE_LANGUAGE",
          data: response.data
        });

      })
      .catch(err => (dispatch(fireNotification(err)), Promise.reject(err)))
  };
};