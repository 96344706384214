import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchAppUsers,
  fetchPlatformSummary,
} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";


let biz_number = 0,
dev_numbers = 0,
hack_numbers = 0,
skill_numbers = 0,

cohorts = 0,
graduates = 0, 
enrolled = 0,
progress = 0;



class LandingPage extends Component {
  state = {
    skills: [],
    users: 0,
    upcomingEvent: {},
    hasUpcoming: false,
    past: []
  };

  

  data = [];
  componentDidMount() {
    this.props
      .getPlatformSummary()
      .then(() => {
        this.setState({
          skills: this.props.platformSummary.skills,
          users: this.props.platformSummary.population, 
        });


        biz_number = 463;
        //biz_number = 197;
        //this.state.skills[1].value;
        hack_numbers = 38;
        dev_numbers = this.state.users;
        skill_numbers = this.state.skills[2].value;

        cohorts = 4;
        graduates = 51;
        enrolled = 120;
        progress = 10;

        
      })
      .catch((err) => this.props.dispatchNotification(err));

    this.props
      .getContests()
      .then(() => {
        this.setState({ past: this.props.past, isLoaded: true });
        if (this.props.live.length) {
          this.setState({
            upcomingEvent: this.props.live[0],
            hasUpcoming: true,
          });

          

        } else if (this.props.upcoming.length) {
          this.setState({
            upcomingEvent: this.props.upcoming[0],
            hasUpcoming: true,
          });

          

        }
        // else if(this.props.past.length){
        //   this.setState({ upcomingEvent: this.props.past[0], hasUpcoming:true })
        // }

        // if(formatDate(this.state.upcomingEvent.registrationEndDate) > Date.now())
        //   {
        //     this.state.isRegistrationOn == false;
        //   }
        //   else{
        //     this.state.isRegistrationOn == true;
        //   }

      })
      .catch((err) => this.props.dispatchNotification(err));
    //this.props.getbusinesslist().then(() => this.setState({ isLoaded: true, bizlist:this.props.businesses }));

    
  }


  
  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (


       <div>
        <link rel="stylesheet" href="/assets/landing-page/css/animate.min.css"/>
        <link rel="stylesheet" href="/assets/landing-page/css/magnific-popup.css"/>
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />
        <link rel="stylesheet" href="/myMenu.css" />

        <Header />

        {/* backgroundColor: "rgb(49,49,50)"  */}
        <main>
          <section
            id="parallax" className="parallax slider-area slider-bg second-slider-bg d-flex align-items-center justify-content-center fix"
            // style={{
            //   background: "url(/assets/landing-page/images/header-overla.jpg)", height:"10%"
            // }}
          >
            
            <div className="slider-active">
              <div className="single-slider">
                <div className="container">
                  <div className="row">
                    <div className="col-8">
                      <div className="slider-content second-slider-content">
                        <h2 data-animation="fadeInUp animated" data-delay=".4s">
                          <span>Let the world</span> know you
                        </h2>
                        {/*<br />
                        <br />
                        <a
                          id="loginButton"
                          href={this.user ? "/profile" : "/login"}
                          className="btn mb-4"
                        >
                          <i className="fa fa-user-alt" />{" "}
                          {this.user ? "Profile" : "Login"}
                        </a>*/}
                        <br />
                        <a className="btn" href={"/register"}>
                          {" "}
                          + Join Skills Ranker
                        </a>
                        <br />
                        {/* <br />
                        <a id="glowingButton" type="button" className="btn" href="/plannedHackathons">Planned Hackathons</a>
                        <br /> */}
                        <br />
                        <ul
                          data-animation="fadeInUp animated"
                          data-delay="1s"
                          style={{ marginLeft: "-35px" }}
                        >
                          <li>Show off your work and skills to the industry</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{ alignItems: "normal", backgroundColor: "#00000090", paddingTop:"30px"}}
            id="how-it-works-business"
            className="about-area about-p p-relative d-flex"
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-sm-6">
                  <div
                    className="feature-box wow fadeInDown animated vl"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    {this.state.past.length > 0 ? (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img
                              src="/assets/landing-page/images/HackathonsConducted.png"
                              style={{ width: "55px", margin:"5px" }} alt="Past Hackathons"/>
                             
                            {this.state.past.length}
                           
                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Hackathons Conducted</p>
                      </div>
                    ) : (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img alt="Images"
                              src="/assets/landing-page/images/HackathonsConducted.png"
                              style={{ width: "55px", margin:"5px" }}/>
                           
                           <div style={{display:"inline"}} id="dev_hack">0</div>

                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Hackathons Conducted</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated vl"
                    data-animation="fadeInDown animated"
                    data-delay=".2s">
                    <div className="single-counter text-center">
                      <div className="counter p-relative">
                        <span className="count" style={{ fontSize: "55px" }}>
                          <img alt="Images"
                            src="/assets/landing-page/images/RegisteredDevelopers.png"
                            style={{ width: "55px", margin:"5px"}} />

                          <div style={{display:"inline"}} id="dev_numbers">0</div>

                        </span>
                        <small style={{ fontSize: "55px" }}>+</small>
                      </div>  
                      <p>Registered Developers</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div
                    className="feature-box wow fadeInDown animated vl"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    {this.state.skills.length > 1 ? (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img alt="Images"
                              src="/assets/landing-page/images/RegisteredBusiness.png"
                              style={{ width: "55px", margin:"5px" }}/>

                             <div style={{display:"inline"}} id="biz_counter">0</div>
                            
                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Registered Businesses</p>
                      </div>
                    ) : (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            0
                          </span>
                        </div>
                        <p>Registered Businesses</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated vl vr"
                    data-animation="fadeInDown animated"
                    data-delay=".2s">
                    {this.state.skills.length > 2 ? (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            <img alt="Skills"
                              src="/assets/landing-page/images/Language.png"
                              style={{ width: "55px", margin:"5px" }} />

                            <div style={{display:"inline"}} id="skills_counter">0</div>

                          </span>
                          <small style={{ fontSize: "55px" }}>+</small>
                        </div>
                        <p>Java</p>
                      </div>
                    ) : (
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px" }}>
                            0
                          </span>
                        </div>
                        <p>Java</p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </section>

       

          <section
            style={{ alignItems: "center", minHeight: "Auto", paddingTop:"50px", paddingBottom:"50px"}}
            id="how-it-works"
            className="about-area about-p p-relative d-flex">
            <div className="container d-flex">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"
                      ></div>
                      <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                        style={{ color: "#00000090" }}
                      >
                        How Skills Ranker Works
                      </span>
                      <h3
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s"
                      >
                        Create your developer profile
                      </h3>
                      <h5
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s"
                      >
                        See how you rank locally
                      </h5>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s"
                    >
                      <p>
                        Using our AI powered ranking algorithm, see how you rank
                        locally as a developer in Botswana. Get recommendations
                        on new skills to learn to improve your ranking
                      </p>
                      <NavLink to="/register" className="btn mt-20">
                        + &nbsp; Join Skills Ranker
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mt-lg-4">
                 

                  <img alt="4 Step Process" style={{width:"100%"}}
                  src='/assets/landing-page/images/NewImageProcess1.png'/>

               
                </div>
              </div>
            </div>
          </section>
                  
          <section
            style={{ alignItems: "center", minHeight: "Auto", backgroundColor:"#00000090", paddingTop:"30px", paddingBottom:"30px"}}
            id="how-it-works-business"
            className="about-area about-p p-relative d-flex"
          >
            <div className="container d-flex">
              <div className="row align-items-center">
                <div className="col-lg-6 mt-lg-4">
                  
                  
                  <img src="/assets/landing-page/images/HackImage.png" style={{width:'70%'}} alt="Hack_Image"/>

                </div>

                <div className="col-lg-6">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"
                      ></div>
                      <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                        style={{ color: "#FFF" }}>

                        Hackathons

                      </span>
                      <h2
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s">
                        Participate in public and private hackathons
                      </h2>
                       <p
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s"
                        style={{color:"whitesmoke"}}>

                        {/* In August 2021, Botswana’s Public Procurement and Asset Disposal Board (PPADB), now PPRA,  
                        responsible for the contracting process for government projects, announced that 
                        they had approved a request by the Office of the President to use hackathons as 
                        a method of procurement for projects related to the digitisation of government services.  */}
                      
                      </p>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s">

                      <NavLink to="/events" className="btn mt-20">
                       View Hackathons
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {this.state.hasUpcoming ?(
            
            <main style={{ width: "100vw" }}>
              {typeof this.state.upcomingEvent.eventBanner !== "undefined" ? (

                <div
                  className="modal fade show"
                  id="advertpopup"
                  tabIndex="-1"
                  aria-labelledby="advertpopup"
                  aria-modal="true"
                  role="dialog"
                  style={{ display: "block" }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="close"
                        aria-label="Close"
                        style={{
                          position: "absolute",
                          float: "right",
                          cursor: "pointer",
                          right: "10px",
                          fontSize: "2rem",
                          top: "4px",
                          zIndex: "2",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          window.$("#advertpopup").remove();
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          background:
                            "url(" +
                            this.state.upcomingEvent.eventBanner.data +
                            ")",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                          height: "25vh",
                        }}
                      ></div>
                      <div className="modal-footer">
                        <div>
                          <p
                            className="m-0  text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <font className="">Event Start: </font>
                            <font className="text-dark">
                              {formatDate(this.state.upcomingEvent.startDate)}
                            </font>
                          </p>
                          <p
                            className="m-0  text-muted"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <font className="">Event End: </font>
                            <font className="text-dark">
                              {formatDate(this.state.upcomingEvent.endDate)}
                    
                              {console.log("start date: " + this.state.upcomingEvent.startDate)}
                              {console.log("end Date: " + formatDate(this.state.upcomingEvent.endDate))}
                              {console.log("end of Registration " + formatDate(this.state.upcomingEvent.registrationEndDate))}
                              {console.log("Today Date: " + formatDate(Date.now()))}
                            </font>
                          </p>
                        </div>
                        <span
                          type="button"
                          onClick={() =>
                            (window.location.href =
                              "/event/" + this.state.upcomingEvent._id)
                          }
                          className="btn-close btn ml-auto"
                          data-bs-dismiss="modal"
                          style={{ padding: "0.7em 1em" }}>


                          {formatDate(Date.now()) >= formatDate(this.state.upcomingEvent.registrationEndDate) || formatDate(Date.now()) <= formatDate(this.state.upcomingEvent.startDate) ? 
                          "Go to Event" : "Register for Event"}
                         
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </main>
          ) : (
            <></>
            
          )}
     

          <section
            style={{ alignItems: "center", minHeight: "60vh" }}
            id="how-it-works-business"
            className="about-area about-p p-relative d-flex">
            <div className="container d-flex">
              <div className="row align-items-center">

              <div className="col-lg-6">
                  <div className="about-content s-about-content pl-30">
                    <div className="about-title second-atitle">
                      <div
                        className="text-outline wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".2s"
                      ></div>
                      <span
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".3s"
                        style={{ color: "#00000090" }}
                      >
                        Expand your skillset
                      </span>
                      <h2
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".4s"
                      >
                        Enroll Today
                      </h2>
                      <p
                        className="wow fadeInUp animated"
                        data-animation="fadeInUp animated"
                        data-delay=".5s">
                        
                          Enroll in one of our digital skills programs to broaden your skillsets and unlock new opportunities. 

                          {/* By enhancing your expertise and diversifying your abilities, you position yourself for greater success and distinguish yourself in your field. */}
                      </p>
                    </div>
                    <div
                      className="wow fadeInDown animated"
                      data-animation="fadeInUp animated"
                      data-delay=".2s">
                 
                      <NavLink to="/aboutAWS" className="btn mt-20">
                        Read More...
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 mt-lg-4">


                  <img src="/assets/landing-page/images/Graduation.png" alt="Graduation" style={{width:"100%"}}/>



                </div>


                <div className="container" style={{marginTop:'30px'}}>
              <div className="row align-items-center">
                <div className="col-lg-6 col-sm-6">
                  <div
                    className="feature-box wow fadeInDown animated vl"
                    data-animation="fadeInDown animated"
                    data-delay=".2s"
                  >
                    
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px", color:'gray' }}>
                            <img alt="Images"
                              src="/assets/landing-page/images/Cohorts.png"
                              style={{ width: "55px", margin:"5px", color:'gray' }}/>
                           
                           <div style={{display:"inline"}} id="div_cohorts">0</div>

                          </span>
                          {/* <small style={{ fontSize: "55px", color:'gray' }}>+</small> */}
                        </div>
                        <p style={{color:'gray' }}>Number of Cohorts</p>
                      </div>
                    
                  </div>
                  <div
                    className="feature-box wow fadeInDown animated vl"
                    data-animation="fadeInDown animated"
                    data-delay=".2s">
                    <div className="single-counter text-center">
                      <div className="counter p-relative">
                        <span className="count" style={{ fontSize: "55px", color:'gray' }}>
                          <img alt="Images"
                            src="/assets/landing-page/images/graduate.png"
                            style={{ width: "55px", margin:"5px"}} />

                          <div style={{display:"inline"}} id="div_graduates">0</div>

                        </span>
                        {/* <small style={{ fontSize: "55px", color:'gray' }}>+</small> */}
                      </div>  
                      <p style={{color:'gray'}}>Number of Graduates</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6">
                  <div
                    className="feature-box wow fadeInDown animated vl"
                    data-animation="fadeInDown animated"
                    data-delay=".2s">
                    
                    
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px", color:'gray' }}>
                          <img alt="Images"
                            src="/assets/landing-page/images/Enrolled.png"
                            style={{ width: "55px", margin:"5px"}} />

                          <div style={{display:"inline"}} id="div_enrolled">0</div>
                          </span>
                        </div>
                        <p style={{color:'gray'}}>Individuals Enrolled</p>
                      </div>
                  

                  </div>
                  <div
                    className="feature-box wow fadeInDown animated vl vr"
                    data-animation="fadeInDown animated"
                    data-delay=".2s">
                    
                    
                      <div className="single-counter text-center">
                        <div className="counter p-relative">
                          <span className="count" style={{ fontSize: "55px", color:'gray' }}>
                          <img alt="Images"
                            src="/assets/landing-page/images/progress.png"
                            style={{ width: "55px", margin:"5px"}} />

                          <div style={{display:"inline"}} id="div_progress">0</div>
                          </span>
                        </div>
                        <p style={{color:'gray'}}>Progression</p>
                      </div>
                    

                  </div>
                </div>
              </div>
            </div>


              </div>
            </div>

          </section>
        </main>

        <Footer />


        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));



  document.addEventListener('DOMContentLoaded', () => {
    let devCount = 0;
    let skillCount = 0;
    let bizCount = 0;
    let hackCount = 0;
    let cohortCount = 0;
    let gradCount = 0;
    let enrollCount = 0;
    let progressCount = 0;
  
  setInterval(() => {
      if (devCount < dev_numbers) {
        const display = document.getElementById("dev_numbers");
        if (display) {
          display.innerHTML = ++devCount;
        }
      }
  
    }, 10);
  
    setInterval(() => {
      if (skillCount < skill_numbers) {
        const display = document.getElementById("skills_counter");
        if (display) {
          display.innerHTML = ++skillCount;
        }
      }
    }, 10);
  
    setInterval(() => {
      if (bizCount < biz_number) {
        const display = document.getElementById("biz_counter");
        if (display) {
          display.innerHTML = ++bizCount;
        }
      }
    }, 10);
  
    setInterval(() => {
      if (hackCount < hack_numbers) {
        const display = document.getElementById("dev_hack");
        if (display) {
          display.innerHTML = ++hackCount;
        }
      }
    }, 10);
  
    setInterval(() => {
      if (cohortCount < cohorts) {
        const display = document.getElementById("div_cohorts");
        if (display) {
          display.innerHTML = ++cohortCount;
        }
      }
    }, 10);
  
    setInterval(() => {
      if (gradCount < graduates) {
        const display = document.getElementById("div_graduates");
        if (display) {
          display.innerHTML = ++gradCount;
        }
      }
    }, 10);
  
    setInterval(() => {
      if (enrollCount < enrolled) {
        const display = document.getElementById("div_enrolled");
        if (display) {
          display.innerHTML = ++enrollCount;
        }
      }
    }, 10);
  
    setInterval(() => {
      if (progressCount < progress) {
        const display = document.getElementById("div_progress");
        if (display) {
          display.innerHTML = ++progressCount;
        }
      }
    }, 10);
  });

