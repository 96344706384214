import { client } from "./client";

export function newTeam(data) {
  return client.post("/events/teams", data);
}

export function getEventTeams(eventId) {
  return client.get("/events/teams?eventId=" + eventId);
  // [
  //     {
  //         name: "Team 1",
  //         submissions: [
  //             {
  //                 filename: "Skote Landing.Zip",
  //                 size: "3.25 MB",
  //                 url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
  //                 submissionTime: "12:00 pm",
  //             },
  //             {
  //                 filename: "Skote Landing.Zip",
  //                 size: "3.25 MB",
  //                 url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
  //                 submissionTime: "12:00 pm",
  //             }
  //         ]

  //     },
  //     {
  //         name: "Team 2",
  //         submissions: [
  //             {
  //                 filename: "Skote Logo.Zip",
  //                 size: "3.25 MB",
  //                 url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
  //                 submissionTime: "12:00 pm",
  //             },
  //             {
  //                 filename: "Skote Logo.Zip",
  //                 size: "3.25 MB",
  //                 url: "https://www.bih.co.bw/wp-content/uploads/2019/09/2017-Annual-Report.pdf",
  //                 submissionTime: "12:00 pm",
  //             }
  //         ]

  //     }
  // ]
}

export function getEventEliminatedTeams(eventId){
    return client.get("/events/teams/eliminated?eventId=" + eventId);
}

export function getEventTeam(teamId) {
  return client.get("/events/team?teamId=" + teamId);
}

export function updateEventTeam(teamId, data) {
  return client.put("/events/teams", { teamId: teamId, ...data });
}

export function removeTeam(teamId) {
  return client.delete("/events/team" + teamId);
}

//waiting on backend to fix issues (stalls)
export function joinTeam(data) {
  return client.put("/events/teams", data);
}

export function getUserTeam(userId, eventId) {
  return client.get(`/event/team/user?userId=${userId}&eventId=${eventId}`);
}

export function updateMembers(body) {
  return client.put(`/events/teams/requests`, body);
}
export function updateUserConsent(body) {
  return client.put(`/events/consent`, body);
}
export function changeSuspensionStatus(body) {
  return client.put(`/events/team/status`, body);
}
export function changeComplianceStatus(body) {
  return client.put(`/events/team/compliance`, body);
}


export function updateTeamElimination(body) {
  return client.put("/events/team/elimination", body);
}

export function sendInvite(body) {
  return client.put("/event/invite", body);
}

export function validateUserEmail(email) {
  return client.get(`/event/invite/email?email=${email}`);
}

export function checkInvite(id) {
  return client.get(`/event/invite?inviteId=${id}`);
}

export function pushInviteResponse(body) {
  return client.put("/event/invite/respond", body);
}

export function exitTeam(body) {
  return client.put("/events/teams/leave", body);
}
export function removeTeamMember(body) {
  return client.put("/events/teams/remove", body);
}
export function makeTeamLeader(body) {
  return client.put("/events/teams/appointleader", body);
}
