import {
  getProjects
} from "../../api/projects";
import Cookies from "js-cookie";
import axios from "axios";

export const fetchProjects = (user) => {
  // console.log(user);

  return dispatch => {
    let githubProjects = [];
    return (async () => {
      if (user) {
        if (user.githubData) {
          githubProjects = await axios.get(user.githubData.repos_url + "?sort=created&type=all", {
            headers: {
              "Authorization": " token" + user.githubAccessToken
            }
          });
          githubProjects = githubProjects.data.filter((r) => !r.fork);
        }
      } else {
        if (JSON.parse(Cookies.get("user")).githubData) {
          const githubProjectUrl = JSON.parse(Cookies.get("user")).githubData.repos_url + "?sort=created&type=all";
          githubProjects = await axios.get(githubProjectUrl, {
            headers: {
              "Authorization": " token" + JSON.parse(Cookies.get("user")).githubAccessToken
            }
          });
          githubProjects = githubProjects.data.filter((r) => !r.fork);
        }
      }
      return Promise.resolve(dispatch({
        type: "PULL_PROJECTS",
        data: githubProjects
      }));

    })();

  };
};


// not in use

export const fetchProject = (pid) => {
  return dispatch => {
    dispatch({
      type: "PULL_PROJECT",
      data: {

        title: "project title",
        // stars: "400",
        // commits: "1000",
        technologies: [{
            key: 0,
            technology: "Bootstrap"
          },
          {
            key: 1,
            technology: "HTML5"
          },
          {
            key: 2,
            technology: "C++"
          },
          {
            key: 3,
            technology: "ReactJS"
          }
        ],
        summary: " Lorem Ipsum has Lorem Ipsum has Lorem Ipsum ha Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimens been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimenLorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a"
      },

    })
  };
};