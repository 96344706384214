import { handleAlerts } from "../../utils/utils";

export const fireNotification = (notification) => {
// console.log(notification);

    const info = handleAlerts(notification)
    return dispatch => {
        dispatch({
            type: "ADD_NOTIFICATION",
            data: info
        })
    }

};

export const deleteNotification = (notification) => {
    return dispatch => {
                dispatch({
                    type: "DELETE_NOTIFICATION",
                    data: notification
                });
    };
};


