import { client } from "./client";

export function _getDiscussions(eventId, type) {
  return client.get(`/discussions?id=${eventId}&type=${type}`);
}

export function postDiscussion(body) {
  return client.post(`/discussion`, body);
}

export function postDiscussionComment(body) {
  return client.post(`/discussion/comment`, body);
}

export function getDiscussionComments(discussionId) {
  return client.get(`/discussion/comment?discussionId=${discussionId}`);
}
