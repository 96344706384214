import React, { Component } from "react";
import { createBrowserHistory as history } from "history";
import { fetchAllCourses } from "../../store/actions/courseActions";

import { connect } from "react-redux";
// import BadgeHorizontal from "../../components/BadgeHorizontal";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import Cookies from "js-cookie";
import { getTechnologyIcon, handleAlerts } from "../../utils/utils";
import { getLanguageIcon } from "../../utils/utils";
import { fireNotification } from "../../store/actions/notificationsActions";

class AdminLearnList extends Component {
  state = {
    isLoaded: false,
  };

  componentDidMount(){
      this.props.getCourses()
      .then(() => this.setState({isLoaded:true}))
      .catch(err => this.props.dispatchNotification(err));
  }


  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">Courses</h4>

                    <div className="page-title-right">
                      {/* <ol className="breadcrumb m-0">
                                                  <li className="breadcrumb-item"><a href="javascript: void(0);">Projects</a></li>
                                                  <li className="breadcrumb-item active">Projects Grid</li>
                                              </ol> */}
                      <button
                        type="button"
                        onClick={() =>
                          this.props.history.push("/admin/course/new")
                        }
                        style={{
                          backgroundColor: "#74788d",
                          borderColor: "#74788d",
                          color: "white",
                        }}
                        className="btn waves-effect waves-light"
                      >
                        <i className="bx bx-user-plus font-size-16 align-middle mr-2"></i>{" "}
                        Add course
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              <div className="row">
                {this.props.courses.map((item) => (
                  <div key={item._id} className="col-xl-4 col-sm-6">
                    <div
                      className="card"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this.props.history.push("/admin/course/" + item._id)
                      }
                    >
                      <div className="card-body">
                        <div className="media">
                          <div className="avatar-md mr-4">
                            <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                              <img
                                src="/assets/images/companies/img-1.png"
                                alt=""
                                height="30"
                              />
                            </span>
                          </div>

                          <div className="media-body overflow-hidden">
                            <h5 className="text-truncate font-size-15">
                              <a href="#" className="text-dark">
                                {item.title}
                              </a>
                            </h5>
                            <p className="text-muted mb-4">{item.info}</p>
                            {/* <div className="team">
                                                              <a href="javascript: void(0);" className="team-member d-inline-block" data-toggle="tooltip" data-placement="top" title="" data-original-title="Daniel Canales">
                                                                  <img src="/assets/users/avatar-2.jpg" className="rounded-circle avatar-xs m-1" alt=""/>
                                                              </a>
      
                                                              <a href="javascript: void(0);" className="team-member d-inline-block" data-toggle="tooltip" data-placement="top" title="" data-original-title="Jennifer Walker">
                                                                  <img src="/assets/images/users/avatar-1.jpg" className="rounded-circle avatar-xs m-1" alt=""/>
                                                              </a>
      
                                                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    courses: state.courseData.courses,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      getCourses: () => dispatch(fetchAllCourses()),
      dispatchNotification:(msg) => dispatch(fireNotification(msg)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLearnList);
