import { combineReducers } from "redux";
import userProfileReducer from "./userProfileReducer";
import projectsReducer from "./projectsReducer";
import languagesReducer from "./languagesReducer";
import technologiesReducer from "./technologiesReducer";
import assesmentsReducer from "./assesmentsReducer";
import businessProfileReducer from "./businessProfileReducer";
import complianceReducer from "./complianceReducer";
import rankReducer from "./rankReducer";
import usersReducer from "./usersReducer";
import contestReducer from "./contestReducer";
import eventRequestReducer from "./eventRequestReducer";
import courseReducer from "./courseReducer";
import notificationsReducer from "./notificationsReducer";
import teamsReducer from "./teamReducer";
import judgeReducer from "./judgeReducer";
import discussionsReducer from "./discussionsReducer";
import jobPostReducer from "./jobPostReducer";
import reportReducer from "./reportReducer";

const rootReducer = combineReducers({
  user: userProfileReducer,
  projectsData: projectsReducer,
  languagesData: languagesReducer,
  technologiesData: technologiesReducer,
  userAssesmentsData: assesmentsReducer,
  assesmentsData: assesmentsReducer,
  businessProfileData: businessProfileReducer,
  complianceData: complianceReducer,
  rankData: rankReducer,
  admin: usersReducer,
  contestData: contestReducer,
  courseData: courseReducer,
  notificationsData: notificationsReducer,
  eventRequestData: eventRequestReducer,
  teamsData: teamsReducer,
  judgeData: judgeReducer,
  discussionData: discussionsReducer,
  jobPostData: jobPostReducer,
  reportData: reportReducer
});

export default rootReducer;
