import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserProfile } from "../../store/actions/usersActions";
import Cookie from "js-cookie";
import AppUsersList from "../../components/AppUsersList";

import { client } from "../../api/client";
import RankBadge2 from "../../components/RankBadge2";
import { getTechnologyIcon } from "../../utils/utils";
import { fireNotification } from "../../store/actions/notificationsActions";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";

class IUser extends Component {
  state = {
    userProfile: '',
    isLoaded: false,
  }

  componentWillMount() {
    const userId = this.props.match.params.id;
    this.props.getUserProfile(userId).then((response) => {
      console.log(response)
      this.setState({ userProfile: response, isLoaded: true });
    })
      .catch(err => this.props.dispatchNotification(err))

  }

  componentDidMount() { }


  render() {
    if (this.state.isLoaded) {

      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row mb-4" style={{ backgroundColor: "#2a3042", color: '#ffffff9c', padding: "1rem" }}>
                <div className="col-xl-4" style={{ display: "flex" }}>
                  <div>
                    <img
                      src={
                        typeof this.state.userProfile !==
                          "undefined" &&
                          this.state.userProfile.githubData
                          ? this.state.userProfile.githubData
                            .avatar_url
                          : "/assets/images/users/avatar.png"
                      }
                      alt=""
                      className="avatar-xl rounded-circle"
                    />
                  </div>
                  <div className="pt-2 pl-4 pr-4">
                    <h5 className="font-size-15 text-truncate" style={{ color: 'inherit' }}>
                      {" "}
                      {(typeof this.state.userProfile !== "undefined"
                        ? this.state.userProfile.name.firstName
                        : "") +
                        " " +
                        (typeof this.state.userProfile !== "undefined"
                          ? this.state.userProfile.name.lastName
                          : "")}{" "}
                    </h5>{" "}
                    <p className="font-size-13" style={{ color: "inherit" }}>
                      {typeof this.state.userProfile !== "undefined"
                        ? this.state.userProfile.email
                        : ""}
                    </p>
                    <p
                      className="mb-0 text-truncate"
                      style={{ textTransform: "capitalize", color: "inherit" }}
                    >
                      {typeof this.state.userProfile !== "undefined"
                        ? this.state.userProfile.accountType
                        : ""}
                    </p>{" "}
                    <p
                      className="text-truncate"
                      style={{ textTransform: "capitalize", color: "inherit" }}
                    >
                      {/* <span className="bx bx-current-location"></span>&nbsp; */}
                      {this.state.userProfile
                        ? this.state.userProfile.location
                        : ""}
                    </p>{" "}
                  </div>

                </div>
                <div className="col-xl-5" style={{ padding: '0.5rem', maxHeight: '6.5rem', overflow: 'hidden' }}>
                  <p>
                    {this.state.userProfile
                      ? this.state.userProfile.bio
                      : ""}
                  </p>
                </div>
                <div className="col-xl-3" style={{ padding: '0.5rem' }}>
                  {this.state.userProfile.points ? (
                    <RankBadge2 points={this.state.userProfile.ranking.points} userId={this.state.userProfile._id} />
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
                  <div className="row card mb-2" style={{ color: 'white', borderRadius: '0', padding: "1rem", flexDirection: "row", backgroundColor: '#80808030' }}>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6">
                      <h6 className="mb-0">Subject</h6>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                      <h6 className="pr-3 mb-0">Assessments taken</h6>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                      <div style={{ float: "right" }}>
                        <h6 className="pl-2 mb-0">Points gained</h6>
                      </div>
                    </div>
                  </div>

                  <div className="row card mb-2" style={{ cursor: 'pointer', borderLeft: 'solid 4px orange', padding: "1rem", flexDirection: "row" }}>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6">
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: "50px" }}>
                          <img
                            src={getTechnologyIcon('ITEM')}
                            style={{ height: "100%", backgroundSize: "cover" }}
                          />
                        </div>
                        <h6 className="mb-0">Title</h6>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                      <div style={{ display: 'flex' }}>

                        <h6 className="pr-3 mb-0">Assessments: </h6> 23
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                      <div style={{ display: 'flex', float: "right" }}>
                        230098<h6 className="pl-2 mb-0">XP</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row card mb-2" style={{ cursor: 'pointer', borderLeft: 'solid 4px orange', padding: "1rem", flexDirection: "row" }}>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6">
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: "50px" }}>
                          <img
                            src={getTechnologyIcon('ITEM')}
                            style={{ height: "100%", backgroundSize: "cover" }}
                          />
                        </div>
                        <h6 className="mb-0">Title</h6>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                      <div style={{ display: 'flex' }}>

                        <h6 className="pr-3 mb-0">Assessments: </h6> 23
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                      <div style={{ display: 'flex', float: "right" }}>
                        230098<h6 className="pl-2 mb-0">XP</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row card mb-2" style={{ cursor: 'pointer', borderLeft: 'solid 4px orange', padding: "1rem", flexDirection: "row" }}>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6">
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: "50px" }}>
                          <img
                            src={getTechnologyIcon('ITEM')}
                            style={{ height: "100%", backgroundSize: "cover" }}
                          />
                        </div>
                        <h6 className="mb-0">Title</h6>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                      <div style={{ display: 'flex' }}>

                        <h6 className="pr-3 mb-0">Assessments: </h6> 23
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                      <div style={{ display: 'flex', float: "right" }}>
                        230098<h6 className="pl-2 mb-0">XP</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row card mb-2" style={{ cursor: 'pointer', borderLeft: 'solid 4px orange', padding: "1rem", flexDirection: "row" }}>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6">
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: "50px" }}>
                          <img
                            src={getTechnologyIcon('ITEM')}
                            style={{ height: "100%", backgroundSize: "cover" }}
                          />
                        </div>
                        <h6 className="mb-0">Title</h6>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                      <div style={{ display: 'flex' }}>

                        <h6 className="pr-3 mb-0">Assessments: </h6> 23
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                      <div style={{ display: 'flex', float: "right" }}>
                        230098<h6 className="pl-2 mb-0">XP</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row card mb-2" style={{ cursor: 'pointer', borderLeft: 'solid 4px orange', padding: "1rem", flexDirection: "row" }}>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6">
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: "50px" }}>
                          <img
                            src={getTechnologyIcon('ITEM')}
                            style={{ height: "100%", backgroundSize: "cover" }}
                          />
                        </div>
                        <h6 className="mb-0">Title</h6>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                      <div style={{ display: 'flex' }}>

                        <h6 className="pr-3 mb-0">Assessments: </h6> 23
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                      <div style={{ display: 'flex', float: "right" }}>
                        230098<h6 className="pl-2 mb-0">XP</h6>
                      </div>
                    </div>
                  </div>
                  <div className="row card mb-2" style={{ cursor: 'pointer', borderLeft: 'solid 4px orange', padding: "1rem", flexDirection: "row" }}>
                    <div className="col-xl-6 col-lg-5 col-md-5 col-sm-6">
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: "50px" }}>
                          <img
                            src={getTechnologyIcon('ITEM')}
                            style={{ height: "100%", backgroundSize: "cover" }}
                          />
                        </div>
                        <h6 className="mb-0">Title</h6>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                      <div style={{ display: 'flex' }}>

                        <h6 className="pr-3 mb-0">Assessments: </h6> 23
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                      <div style={{ display: 'flex', float: "right" }}>
                        230098<h6 className="pl-2 mb-0">XP</h6>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <h5 className="mt-4">Hackathons / Contests</h5>
              <div className="row mt-4" >
                {/* <div className="col-md-6 col-xl-3">
                    <div classNmae="card" style={{textAlign:'center'}}>
                      <img className="card-img-top img-fluid" src="/assets/images/small/img-1.jpg" alt="Card image cap"/>
                      <div className="card-body">
                          <h6 className="mt-0">Hackathon Type</h6>
                          <h6 className="card-title mt-0">Hackathon Title</h6>
                          <p className="card-text">Some quick example text to build on the card title and make
                              up the bulk of the card's content.</p>
                          <a href="#" className="btn btn-primary waves-effect waves-light">Button</a>
                      </div>
                  </div>
                </div> */}
                <div className="col-md-3 col-xl-3">
                  {/* <!-- Simple card --> */}
                  <div className="card" style={{ textAlign: 'center' }}>
                    <img className="card-img-top img-fluid" src="/assets/images/small/img-1.jpg" alt="Card image cap" />
                    <div className="card-body">
                      <p className="mt-0 mb-1">Hackathon Type</p>
                      <h4 className="card-title mt-0">Hackathon Title</h4>
                      <p className="mb-1">Date</p>
                      <p className="card-text" style={{ fontSize: '16px' }}>23 : 04 : 2020</p>

                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xl-3">
                  {/* <!-- Simple card --> */}
                  <div className="card" style={{ textAlign: 'center' }}>
                    <img className="card-img-top img-fluid" src="/assets/images/small/img-1.jpg" alt="Card image cap" />
                    <div className="card-body">
                      <p className="mt-0 mb-1">Hackathon Type</p>
                      <h4 className="card-title mt-0">Hackathon Title</h4>
                      <p className="mb-1">Date</p>
                      <p className="card-text" style={{ fontSize: '16px' }}>23 : 04 : 2020</p>

                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xl-3">
                  {/* <!-- Simple card --> */}
                  <div className="card" style={{ textAlign: 'center' }}>
                    <img className="card-img-top img-fluid" src="/assets/images/small/img-1.jpg" alt="Card image cap" />
                    <div className="card-body">
                      <p className="mt-0 mb-1">Hackathon Type</p>
                      <h4 className="card-title mt-0">Hackathon Title</h4>
                      <p className="mb-1">Date</p>
                      <p className="card-text" style={{ fontSize: '16px' }}>23 : 04 : 2020</p>

                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xl-3">
                  {/* <!-- Simple card --> */}
                  <div className="card" style={{ textAlign: 'center' }}>
                    <img className="card-img-top img-fluid" src="/assets/images/small/img-1.jpg" alt="Card image cap" />
                    <div className="card-body">
                      <p className="mt-0 mb-1">Hackathon Type</p>
                      <h4 className="card-title mt-0">Hackathon Title</h4>
                      <p className="mb-1">Date</p>
                      <p className="card-text" style={{ fontSize: '16px' }}>23 : 04 : 2020</p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <h5>Projects</h5>
              </div>
            </div>{" "}

            {/** <!-- container-fluid --> **/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">{new Date().getFullYear()} © Botswana Innovation Hub</div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );

    } else {
      return (
        <AssesmentsSkeleton />
      );
    }

  }
}

const mapStateToProps = (state) => {
  return {
    userProfile: state.admin.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: (userId) => dispatch(getUserProfile(userId)),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IUser);
