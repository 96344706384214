import React, { Component } from "react";
import { Route, Switch, NavLink, withRouter } from "react-router-dom";
import Profile from "./Profile";
import Rankings from "./Rankings";
import Project from "./Project";
import Cookies from "js-cookie";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import { connect } from "react-redux";
import { fireNotification } from "../../store/actions/notificationsActions";
import {
  fetchAppUsers,
  fetchPlatformSummary,
} from "../../store/actions/usersActions";
import { getLanguages } from "../../api/languages";
import { getTechnologies } from "../../api/technologies";
import { formatDate, handleSkills } from "../../utils/utils";
import { fetchAllContests } from "../../store/actions/contestActions";
import bih from "../../../src/bih.jpg";
import dateFormat from "dateformat";
import moment from "moment";
import JotformEmbed from 'react-jotform-embed';


var biz_number = 0,
dev_numbers = 0,
hack_numbers = 0,
skill_numbers = 0;


class LandingPage extends Component {
  state = {
    skills: [],
    users: 0,
    upcomingEvent: {},
    hasUpcoming: false,
    past: []
  };

  

  data = [];
  componentDidMount() {
    this.props
      .getPlatformSummary()
      .then(() => {
        this.setState({
          skills: this.props.platformSummary.skills,
          users: this.props.platformSummary.population, 
        });


        biz_number = 232;
   
        hack_numbers = 25;
        dev_numbers = this.state.users;
        skill_numbers = this.state.skills[2].value;

        
      })
      .catch((err) => this.props.dispatchNotification(err));

    this.props
      .getContests()
      .then(() => {
        this.setState({ past: this.props.past, isLoaded: true });
        if (this.props.live.length) {
          this.setState({
            upcomingEvent: this.props.live[0],
            hasUpcoming: true,
          });

          

        } else if (this.props.upcoming.length) {
          this.setState({
            upcomingEvent: this.props.upcoming[0],
            hasUpcoming: true,
          });
        }
     
      })
      .catch((err) => this.props.dispatchNotification(err));


    
  }


  
  user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  toggleMenu() {
    window.$("#mobile-menu").slideToggle();
  }
  
  render() {
    window.onresize = function (event) {
      if (window.$(window).width() < 990) {
        window.$("#mobile-menu").slideUp();
      }
    };

    return (
      <div>
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/animate.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/landing-page/css/magnific-popup.css"
        />
        <link rel="stylesheet" href="assets/fontawesome/css/all.min.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/dripicons.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/slick.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/default.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/style.css" />
        <link rel="stylesheet" href="/assets/landing-page/css/responsive.css" />

       
        <Header />
        
        <main>
          

          <section style={{height:"120px"}}></section>

         
         <section>
          <div id="iframe-container">
          <JotformEmbed src="https://form.jotformeu.com/223241211683547"/>
          </div>
         </section>
     
   
        </main>

        <Footer />
       
        <Switch>
          <Route path={"/profile"} component={Profile} exact />
          <Route path={"/profile/leaderboard"} component={Rankings} exact />
          <Route exact path={"/profile/assesments"} component={Profile} />
          <Route exact path={"/profile/project/:id"} component={Project} />
        </Switch>
      </div>
    );
    <script>
    let _isLoaded = false;
  </script>
  }
  

}


const mapStateToProps = (state) => {
  return {
    platformSummary: state.admin.platformSummary,
    live: state.contestData.live,
    upcoming: state.contestData.upcoming,
    past: state.contestData.past,
    businesses: state.businessProfileData.businessProfileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlatformSummary: () => dispatch(fetchPlatformSummary()),
    dispatchNotification: (msg) => dispatch(fireNotification(msg)),
    getContests: () => dispatch(fetchAllContests())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LandingPage));


  