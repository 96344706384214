import Cookies from "js-cookie";

import {createComplianceElement, getBusinessComplianceElements ,updateComplianceElement, getComplianceElements, deleteComplianceElement, uploadComplianceDoc, checkCompliance, changeBusinessCompliance, reviveComplianceElement  } from "../../api/compliance";

export const newComplianceElement = (body) => {
  return (dispatch) => {
    return createComplianceElement(body)
      .then((response) => {
        const conplianceElement =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "SET_COMPLIANCE_ELEMENT",
          data: conplianceElement,
        });
        return response
      })
      .catch((err) => Promise.reject(err));
  };
};

export const pushComplianceElementUpdate = (body) => {
  return (dispatch) => {
    return updateComplianceElement(body)
      .then((response) => {
        const conplianceElement =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_COMPLIANCE_ELEMENT",
          data: conplianceElement,
        });
        return response
      })
      .catch((err) => Promise.reject(err));
  };
};

export const bringBackComplianceElement = (body) => {
  return (dispatch) => {
    return reviveComplianceElement(body)
      .then((response) => {
        const complianceElement =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_COMPLIANCE_ELEMENT",
          data: complianceElement,
        });
        return response
      })
      .catch((err) => Promise.reject(err));
  };
};

export const fetchComplianceElements = () => {
  return (dispatch) => {
    return getComplianceElements()
      .then((response) => {
        const conplianceElements =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "SET_COMPLIANCE_LIST",
          data: conplianceElements,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const fetchBusinessComplianceElements = (pid) => {
  return (dispatch) => {
    return getBusinessComplianceElements(pid)
      .then((response) => {
        const conplianceElements =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "SET_COMPLIANCE_LIST",
          data: conplianceElements,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const removeComplianceElement = (id) => {
  return (dispatch) => {
    return deleteComplianceElement(id)
      .then((response) => {
        const conplianceElement =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "REMOVE_COMPLIANCE_ELEMENT",
          data: conplianceElement,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};


export const pushProfileToStore = (body) => {
  return async (dispatch) => {
      return await  dispatch({
          type: "GET_BUSINESS_PROFILE",
          data: body,
        });
      
  };
};

export const pushComplianceDoc = (body) => {
  return (dispatch) => {
    return uploadComplianceDoc(body)
      .then((response) => {
        const conplianceElement =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPLOAD_COMPLIANCE_DOC",
          data: conplianceElement,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const businessComplianceCheck = (body) => {
  return (dispatch) => {
    return checkCompliance(body)
      .then((response) => {
        const conplianceElement =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "CHECK_COMPLIANCE",
          data: conplianceElement,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};

export const updateBusinessCompliance = (body) => {
  return (dispatch) => {
    return changeBusinessCompliance(body)
      .then((response) => {
        const conplianceElement =
          typeof response.data === "string"
            ? JSON.parse(response.data)
            : response.data;
        dispatch({
          type: "UPDATE_BUSINESS_COMPLIANCE",
          data: conplianceElement,
        });
      })
      .catch((err) => Promise.reject(err));
  };
};