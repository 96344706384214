import React from "react";
import { Route, Redirect } from "react-router";

export default function PrivateRoute({
  component: Component,
  authenticated,
  isAccountSetup,
  accountType,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        if (accountType === "admin") return <Redirect
          to={{
            pathname: "/admin",
            state: {
              from: props.location
            }
          }}
        />
        else if (accountType === "sponsor") return <Redirect
          to={{
            pathname: "/sponsor/hackathon",
            state: {
              from: props.location
            }
          }}
        />
        else if (accountType === "business") return <Redirect
          to={{
            pathname: "/business",
            state: {
              from: props.location
            }
          }}
        />
        return authenticated ? (
          isAccountSetup ? (
            <Component {...props} />
          ) : (
              <Redirect
                to={{
                  pathname: "/setup",
                  state: {
                    from: props.location
                  }
                }}
              />
            )
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location
                }
              }}
            />
          );
      }}
    />
  );
}
