const initState = {
  complianceElement: {},
  complianceList:[],
  businessCompliantList:[]
};

const complianceReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_COMPLIANCE_ELEMENT":
      return {
        ...state,
        complianceElement: action.data,
        complianceList: [...state.complianceList, action.data]
      };
    case "UPDATE_COMPLIANCE_ELEMENT":
      let cList = state.complianceList.filter(item => item._id !== action.data._id)
      cList.push(action.data)
      return {
        ...state,
        complianceElement: action.data,
        complianceList: cList
        
      };
    case "SET_COMPLIANCE_LIST":
      return {
        ...state,
        complianceList:action.data
      };
    case "UPDATE_COMPLIANCE_LIST":
      return {
        ...state,
        complianceList: action.data
      };
    case "REMOVE_COMPLIANCE_ELEMENT":
      return {
        ...state,
        complianceList: state.complianceList.filter(item => item._id !== action.data._id)
      };
    case "CHECK_COMPLIANCE":
      return {
        ...state,
        businessComplianceList: action.data
      };
    default:
      break;
  }
  return state;
};

export default complianceReducer;
