import React, { Component } from "react";
import { connect } from "react-redux";
import AssesmentsSkeleton from "../../components/skeletons/AssesmentsSkeleton";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import ContentSkeleton from "../../components/skeletons/ContentSkeleton";

import moment from "moment";
import {
  fetchBusinessProfileById,
  updateBusinessStatus,
} from "../../store/actions/businessProfileActions";
// import { prod_tt_sasportal } from "googleapis/build/src/apis/prod_tt_sasportal";
import { BASE_URL } from "../../api/client";
import {
  businessComplianceCheck,
  updateBusinessCompliance,
} from "../../store/actions/complianceActions";
import { formatDate, timeZoneHelper } from "../../utils/utils";

class AdminBusinessesDetails extends Component {
  state = {
    profile: "",
    isLoaded: false,
    showNotification: false,
    update: false,
    changeIcon: false,
    isEditingCompliance: false,
    isEditingProfile: false,
    businessComplianceList: [],
  };

  componentDidMount() {
    // this.props.getBusinesses().then(() => this.setState({ isLoaded: true, profileList:this.props.businesses }));
    const { history } = this.props;
    let profileId = history.location.pathname.split("/")[3];
    this.props
      .geBusinessProfile(profileId)
      .then(() =>
        this.setState({ isLoaded: true, profile: this.props.profile })
      )
      .catch((err) => {
        console.log(err);
      });

    this.props
      .getBusinessCompliance({ profileId: profileId })
      .then(() => {
        this.setState({
          businessComplianceList: this.props.businessComplianceList,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  toggleCompliance(complianceItem, idx) {
    let body = {
      isCompliant: !complianceItem.isCompliant,
      complianceId: complianceItem._id,
    };

    // update compliance state
    this.setState({ isEditingCompliance: true });

    this.props
      .changeCompliance(body)
      .then(() => {
        let list = this.state.businessComplianceList;
        list[idx].isCompliant = !list[idx].isCompliant;

        // update compliance state
        this.setState({
          isEditingCompliance: false,
          businessComplianceList: list,
        });
      })
      .catch((err) => {
        this.setState({ isEditingCompliance: false });
      });

    // update loading animation
  }

  toggleAccountStatus() {
    this.setState({ isEditingProfile: true });
    let profileId = this.state.profile._id;
    let status = !this.state.profile.isActive;
    this.props
      .toggleProfileStatus({ profileId: profileId, status: status })
      .finally(() => {
        this.setState({ isEditingProfile: false });
      });
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18"></h4>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}

              <div className="row">
                <div className="col-md-2">
                  <div
                    className="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      className="nav-link mb-2 active"
                      style={{
                        borderBottom: "solid 1px rgb(172 175 177)",
                        borderRadius: "0px",
                      }}
                      id="v-pills-description-tab"
                      data-toggle="pill"
                      href="#v-pills-profile"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="true"
                    >
                      Business Profile
                    </a>
                    <a
                      className="nav-link mb-2"
                      style={{
                        borderBottom: "solid 1px rgb(172 175 177)",
                        borderRadius: "0px",
                      }}
                      id="v-pills-members-tab"
                      data-toggle="pill"
                      href="#v-pills-members"
                      role="tab"
                      aria-controls="v-pills-members"
                      aria-selected="false"
                    >
                      Business members
                    </a>
                    <a
                      className="nav-link mb-2"
                      style={{
                        borderBottom: "solid 1px rgb(172 175 177)",
                        borderRadius: "0px",
                      }}
                      id="v-pills-events-tab"
                      data-toggle="pill"
                      href="#v-pills-events"
                      role="tab"
                      aria-controls="v-pills-events"
                      aria-selected="false"
                    >
                      Business Events
                    </a>
                    {/* <a
                      className="nav-link mb-2"
                      style={{
                        borderBottom: "solid 1px rgb(172 175 177)",
                        borderRadius: "0px",
                      }}
                      id="v-pills-jobs-tab"
                      data-toggle="pill"
                      href="#v-pills-jobs"
                      role="tab"
                      aria-controls="v-pills-jobs"
                      aria-selected="false"
                    >
                      Posted Jobs
                    </a> */}
                  </div>
                </div>
                <div className="col-md-9">
                  <div
                    className="tab-content text-muted  mt-md-0"
                    id="v-pills-tabContent"
                    style={{
                      borderLeft: "solid 1px rgb(172 175 177)",
                      padding: "0rem 1rem 1rem 1rem",
                      minWidth: "70vw",
                    }}
                  >
                    <div
                      className="tab-pane active show fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="card">
                            {this.state.isEditingProfile ? (
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  left: 0,
                                  top: 0,
                                  zIndex: 10,
                                  height: "100%",
                                  background: "#fffbfb3d",
                                  textAlign: "center",
                                  backdropFilter: "blur(6px)",
                                }}
                              >
                                <p style={{ top: "50%", fontWeight: "bold" }}>
                                  {" "}
                                  updating Account ...{" "}
                                </p>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-9">
                                  <div className="media">
                                    <div className="media-body overflow-hidden">
                                      <h5
                                        className="text-truncate font-size-15"
                                        style={{ color: "#495057" }}
                                      >
                                        {this.props.profile.businessName
                                          ? this.props.profile.businessName
                                          : "-"}
                                      </h5>
                                      <p className="text-muted">
                                        {this.props.profile.industry
                                          ? this.props.profile.industry
                                          : "-"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="text-sm-right">
                                    {this.props.profile.isActive ? (
                                      <button
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          borderColor: "red",
                                          fontWeight: "bold",
                                        }}
                                        type="button"
                                        onClick={() =>
                                          this.toggleAccountStatus()
                                        }
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                      >
                                        Disable Account
                                      </button>
                                    ) : (
                                      <button
                                        style={{
                                          backgroundColor: "green",
                                          color: "white",
                                          borderColor: "green",
                                          fontWeight: "bold",
                                        }}
                                        type="button"
                                        onClick={() =>
                                          this.toggleAccountStatus()
                                        }
                                        className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"
                                      >
                                        Enable Account
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <h5
                                className="font-size-15 mt-4"
                                style={{ color: "#495057" }}
                              >
                                Business description :
                              </h5>

                              <p className="text-muted">
                                {this.props.profile.businessDescription
                                  ? this.props.profile.businessDescription
                                  : "-"}
                              </p>

                              <div className="table-responsive mt-4">
                                <table className="table table-nowrap mb-0">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Registration Number :</th>
                                      <td>
                                        {this.props.profile.registrationNumber
                                          ? this.props.profile
                                            .registrationNumber
                                          : "not provided"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Phone :</th>
                                      <td>
                                        {this.props.profile.number
                                          ? this.props.profile.number
                                          : "not provided"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Location :</th>
                                      <td>
                                        {this.props.profile.location.description
                                          ? this.props.profile.location
                                            .description
                                          : "not provided"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Registration Date: </th>
                                      <td>
                                        {formatDate(
                                          this.props.profile.createdAt
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div className="card">
                            <div className="card-body">
                              <div className="row mb-2">
                                <div className="col-sm-4">
                                  <div className="search-box mr-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <h4
                                        className="card-title mb-4"
                                        style={{ color: "#495057" }}
                                      >
                                        Document library
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="table-responsive">
                                <table className="table table-centered table-nowrap">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Date modified</th>
                                      <th>Size</th>
                                      <th>Tag</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.profile.complianceDocuments.map(
                                      (doc, idx) => (
                                        <tr key={idx}>
                                          <td>{doc.file.fileName}</td>
                                          <td>
                                            {formatDate(
                                              doc.createdAt
                                            )}
                                          </td>
                                          <td>
                                            {this.bytesToSize(
                                              doc.file.fileSize
                                            )}
                                          </td>
                                          <td>{doc.compliance.title}</td>
                                          <td>
                                            <div className="dropdown">
                                              <a
                                                href="#"
                                                className="dropdown-toggle card-drop"
                                                data-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                                              </a>
                                              <ul className="dropdown-menu dropdown-menu-center pl-0" style={{ "marginLeft": "-141px" }}>
                                                <li>
                                                  <a
                                                    href={`${BASE_URL}compliance/document/download?fileId=${doc.file._id}`}
                                                    target="_blank" rel="noreferrer"
                                                    className="dropdown-item"
                                                  >
                                                    <i className="bx bx-download  text-success"></i>{" "}
                                                    Download
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- end col --> */}

                        <div className="col-lg-4">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ display: "flex" }}
                                >
                                  <h4
                                    className="col-sm-10 card-title"
                                    style={{
                                      color: "#495057",
                                      alignSelf: "start",
                                    }}
                                  >
                                    Primary Contact Person
                                  </h4>
                                </div>
                              </div>
                              <table className="table table-nowrap mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">Full Name :</th>
                                    <td>
                                      {this.props.profile.createdBy
                                        ? this.props.profile.createdBy.name
                                          .firstName +
                                        " " +
                                        this.props.profile.createdBy.name
                                          .lastName
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Mobile :</th>
                                    <td>
                                      {this.props.profile.createdBy
                                        ? this.props.profile.createdBy
                                          .contactNumber
                                        : "not provided"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">E-mail :</th>
                                    <td>
                                      {this.props.profile.createdBy
                                        ? this.props.profile.createdBy.email
                                        : ""}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="card">
                            <div className="card-body">
                              {this.state.isEditingCompliance ? (
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    left: 0,
                                    top: 0,
                                    zIndex: 10,
                                    height: "100%",
                                    background: "#fffbfb3d",
                                    textAlign: "center",
                                    backdropFilter: "blur(6px)",
                                  }}
                                >
                                  <p style={{ top: "50%", fontWeight: "bold" }}>
                                    {" "}
                                    updating compliance ...{" "}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}

                              <h4
                                className="card-title mb-5"
                                style={{ color: "#495057" }}
                              >
                                Compliance Checks
                              </h4>
                              <div className="">
                                <ul className="verti-timeline list-unstyled">
                                  {this.state.businessComplianceList.map(
                                    (complianceItem, idx) =>
                                      complianceItem.isCompliant ? (
                                        <li
                                          key={complianceItem._id}
                                          className="event-list"
                                        >
                                          <div className="event-timeline-dot">
                                            <i className="bx bx-right-arrow-circle"></i>
                                          </div>
                                          <div
                                            className="media"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.toggleCompliance(
                                                complianceItem,
                                                idx
                                              )
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Click to revoke"
                                          >
                                            <div className="mr-3">
                                              <i className="bx bx-check-shield h4 text-primary"></i>
                                            </div>
                                            <div className="media-body">
                                              <div>
                                                <h5 className="font-size-15">
                                                  <a
                                                    href="#"
                                                    className="text-dark"
                                                  >
                                                    {
                                                      complianceItem
                                                        .complianceCheckId.title
                                                    }
                                                  </a>
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      ) : (
                                        <li
                                          key={complianceItem._id}
                                          className="event-list active"
                                        >
                                          <div
                                            className="event-timeline-dot"
                                            style={{ color: "red" }}
                                          >
                                            <i className="bx bx-right-arrow-circle bx-fade-right"></i>
                                          </div>
                                          <div
                                            className="media"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.toggleCompliance(
                                                complianceItem,
                                                idx
                                              )
                                            }
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Click to approve"
                                          >
                                            <div className="mr-3">
                                              <i
                                                className="bx bx-x"
                                                style={{ color: "red" }}
                                              ></i>
                                            </div>
                                            <div className="media-body">
                                              <div>
                                                <h5 className="font-size-15">
                                                  <a
                                                    href="#"
                                                    className="text-dark"
                                                  >
                                                    {
                                                      complianceItem
                                                        .complianceCheckId.title
                                                    }
                                                  </a>
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      )
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- end col --> */}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-events"
                      role="tabpanel"
                      aria-labelledby="v-pills-events-tab"
                    >
                      <div className="pl-4">
                        <div className="row mb-2">
                          <h2
                            style={{
                              fontSize: "29px",
                              textTransform: "uppercase",
                            }}
                            className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                          >
                            Business events
                          </h2>
                        </div>
                        <p className="row mb-4">
                          This section shows all hackathon events this business
                          has participated in
                        </p>
                        <div className="row">
                          {this.state.profile.events.map((contest, index) => (
                            <div key={index} className="col-md-3 col-xl-3">
                              {/* <!-- Simple card --> */}
                              <div
                                onClick={() =>
                                  this.props.history.push(
                                    "/event/" + contest._id
                                  )
                                }
                                className="card"
                                style={{
                                  textAlign: "center",
                                  cursor: "pointer",
                                  boxShadow:
                                    "rgb(136 136 136 / 43%) 3px 5px 11px",
                                }}
                              >
                                <div
                                  style={{
                                    height: "150px",
                                    width: "100%",
                                    backgroundImage:
                                      "url(" + contest.eventBanner.data + ")",
                                    backgroundOrigin: "center",
                                    backgroundSize: "cover",
                                  }}
                                ></div>
                                {/* <img
                                  className="card-img-top img-fluid"
                                  src={contest.banner}
                                  alt="Card image cap"
                                /> */}

                                <div
                                  className="card-body"
                                  style={{ textAlign: "left" }}
                                >
                                  <h4
                                    className="card-title mt-0 text-dark"
                                    style={{ fontSize: "15px" }}
                                  >
                                    {contest.title}
                                  </h4>
                                  {/* <p className="mb-1">Time line</p> */}
                                  <p
                                    className="card-text text-muted"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <b className="text-primary font-weight-bolder">
                                      Starts:{" "}
                                    </b>
                                    {formatDate(
                                      new Date(contest.startDate),

                                    )}{" "}
                                    <br />
                                    <b className="text-primary font-weight-bolder">
                                      Ends:{" "}
                                    </b>
                                    {formatDate(
                                      new Date(contest.endDate),

                                    )}
                                  </p>
                                  <p className="text-dark">
                                    {ReactHtmlParser(
                                      contest.description
                                        .replace(/<\/?[^>]+(>|$)/g, "")
                                        .substring(0, 40)
                                        .trim()
                                        .padEnd(3, ".")
                                    )}
                                  </p>
                                  <div style={{ textAlign: "left" }}>
                                    {contest.tags.map((tag, index) => (
                                      <span
                                        key={{ index }}
                                        href="#"
                                        className="badge badge-primary font-size-11 m-1"
                                      >
                                        {tag}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-members"
                      role="tabpanel"
                      aria-labelledby="v-pills-members-tab"
                    >
                      <div className="pl-4">
                        <div className="row mb-2">
                          <h2
                            style={{
                              fontSize: "29px",
                              textTransform: "uppercase",
                            }}
                            className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                          >
                            Business members
                          </h2>
                        </div>
                        <p className="row mb-4">
                          Users with access to {this.props.profile.businessName}{" "}
                          business profile
                        </p>
                        <div className="card">
                          <div className="card-body">
                            <div className="row mb-2">
                              <div className="col-sm-4">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <h4
                                      className="card-title mb-4"
                                      style={{ color: "#495057" }}
                                    >
                                      Members
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-8">
                                {/* <div className="text-sm-right">
                                            <button type="button" data-toggle="modal" data-target="#member-invite-modal" style={{backgroundColor:"#e2b537", color:"black", borderColor:"#e2b537",  fontWeight:"bold"}} className="btn btn-success btn-rounded waves-effect waves-light mb-2 mr-2"><i className="mdi mdi-plus mr-1"></i>Add new member</button>
                                        </div> */}
                                <div
                                  className="modal fade bs-example-modal-center"
                                  id="member-invite-modal"
                                  style={{ backgroundColor: "#00000080" }}
                                  tabIndex="-1"
                                  role="dialog"
                                  aria-labelledby="mySmallModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title mt-0">
                                          Invite new Business member
                                        </h5>
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <form
                                          className="form-horizontal"
                                          onSubmit={(e) =>
                                            this.handleAccountInvite(e)
                                          }
                                        >
                                          <div className="row form-group">
                                            <div className="col-12">
                                              <label>Business email</label>
                                              <input
                                                onChange={(e) =>
                                                  this.setState({
                                                    inviteEmail: e.target.value,
                                                  })
                                                }
                                                value={this.state.inviteEmail}
                                                className="form-control"
                                                type="email"
                                                placeholder="Business Email"
                                                required
                                              />
                                            </div>
                                          </div>

                                          <div className="row form-group">
                                            <div className="col-12">
                                              <button
                                                className="form-control btn btn-primary"
                                                style={{
                                                  backgroundColor: "#e2b537",
                                                  color: "black",
                                                  borderColor: "#e2b537",
                                                  fontWeight: "bold",
                                                }}
                                                type="submit"
                                              >
                                                Send Invitation
                                              </button>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- /.modal --> */}
                              </div>
                            </div>

                            <div className="table-responsive">
                              <table className="table table-centered table-nowrap">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Number</th>
                                    <th>Email</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.props.profile.members.map((member) => (
                                    <tr>
                                      <td>
                                        {member.name.firstName +
                                          " " +
                                          member.name.lastName}
                                      </td>
                                      <td>
                                        {member.contactNumber
                                          ? member.contactNumber
                                          : "not provided"}
                                      </td>
                                      <td>{member.email}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <div className="card">
                          <div className="card-body">
                            <div className="row mb-2">
                              <div className="col-sm-4">
                                <div className="search-box mr-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <h4
                                      className="card-title"
                                      style={{ color: "#495057" }}
                                    >
                                      Invitations
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="table-responsive">
                              <table className="table table-centered table-nowrap">
                                <thead>
                                  <tr>
                                    <th>Email</th>
                                    <th>Creation Date</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.props.profile.invitations.map(
                                    (invitation) => (
                                      <tr>
                                        <td>{invitation.email}</td>
                                        <td>
                                          {formatDate(
                                            invitation.createdAt
                                          )}
                                        </td>
                                        <td>{invitation.status}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-jobs"
                      role="tabpanel"
                      aria-labelledby="v-pills-jobs-tab"
                    >
                      <div className="pl-4">
                        <div className="row mb-4">
                          <h2
                            style={{
                              fontSize: "29px",
                              textTransform: "uppercase",
                            }}
                            className="dark weight-600 caps inline-block float-left mr-3 text-dark font-weight-bolder"
                          >
                            Posted Jobs
                          </h2>
                        </div>
                        <ContentSkeleton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end row --> */}
            </div>{" "}
            {/**<!-- container-fluid -->**/}
          </div>
          {/* <!-- End Page-content --> */}

          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  {new Date().getFullYear()} © Botswana Innovation Hub
                </div>
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      );
    } else {
      return <AssesmentsSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.businessProfileData.businessProfile,
    businessComplianceList: state.complianceData.businessComplianceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    geBusinessProfile: (profileId) =>
      dispatch(fetchBusinessProfileById(profileId)),
    toggleProfileStatus: (body) => dispatch(updateBusinessStatus(body)),
    getBusinessCompliance: (body) => dispatch(businessComplianceCheck(body)),
    changeCompliance: (body) => dispatch(updateBusinessCompliance(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminBusinessesDetails);
