import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { client } from "../api/client";

class AppUsersList extends Component {
  state = { submitting: [], datatable: null };

  constructor(props) {
    super(props);
    this.disableUser = this.disableUser.bind(this);
    this.createButton = this.createButton.bind(this);
    this.state.submitting = [];
  }

  disableUser(userId, status) {
    console.log(userId);
    this.setState({ submitting: this.state.submitting.concat(userId) });
    client.put("/user/status", { userId, status }).then((res) => {
      this.state.submitting.splice(this.state.submitting.indexOf(userId), 1);
      this.state.datatable
        .clear()
        .rows.add(
          res.data.map((user) => ({
            ...user,
            //   title: user.titles && user.titles.length > 0 ? user.titles[0] : "",
            names: user.name
              ? user.name.firstName + " " + user.name.lastName
              : "",
            location: user.location ? user.location : "",
          }))
        )
        .draw();
    });
  }

  createButton(id, status) {
    return this.state.submitting.indexOf(`${id}`) === -1 ? (
      <button
        style={{
          padding: "2px 14px 2px 14px",
          margin: "0px 14px 0px 14px",
        }}
        className="btn btn-secondary"
        onClick={this.disableUser.bind(this, `${id}`, status)}
      >
        {!status ? "Disable" : "Enable"}
      </button>
    ) : (
      "Submitting..."
    );
  }

  componentDidMount() {
    const self = this;

    this.state.datatable = window.$("#datatable-buttons").DataTable({
      // dom: "Btr<'row vdtnet-footer'<'col-sm-12 col-md-5'i>>",
      // buttons:["excel","pdf"],
      // "autoWidth": false,
      "scrollX": true,
      dom: 'Bfrtip',
      // responsive: {
      //   details: false,
      //   breakpoints: [
      //     { name: 'desktop', width: Infinity },
      //     { name: 'tablet-l', width: 992 },
      //     { name: 'tablet-p', width: 992 },
      //     { name: 'mobile-l', width: 576 },
      //     { name: 'mobile-p', width: 320 }
      //   ],
      // },
      buttons: [
        {
          extend: 'pdfHtml5',
          orientation: 'landscape',
          filename: "Skill Ranker - Users",
          // + new Date().getDay() +
          // "-" + new Date().getMonth() +
          // "-" + new Date().getFullYear() +
          // "-" + new Date().toTimeString(),
          header: false,
          // pageSize: 'LEGAL',
          title: "Skills Ranker User Report",
          messageTop: "Report Generated: " + new Date().toLocaleString(),
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          extend: "excel",
          title: "Skills Ranker User Report",
          messageTop: "Report Generated: " + new Date().toLocaleString(),
          exportOptions: {
            columns: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          extend: "print",
          orientation: 'landscape',
          pageSize: 'LEGAL',
          exportOptions: {
            columns: [1, 2, 3, 4, 5],
          },
        },
      ],
      data: this.props.users.map((user) => ({
        ...user,
        //   title: user.titles && user.titles.length > 0 ? user.titles[0] : "",
        names: user.name ? user.name.firstName + " " + user.name.lastName : "",
        location: user.location ? user.location : "",
        programmingLanguages: user.programmingLanguages.map((m) => m.title).join(", "),
        technologies: user.technologies.map((m) => m.title).join(", "),
        contactNumber: user.contactNumber &&
          typeof user.contactNumber.split("+")[1] !== "undefined" ?
          "+" + user.contactNumber.split("+")[1] :
          "",
        dob: user.dob ? user.dob : "",
        gender: user.gender ? user.gender : "",
      })),
      sort: false,
      columns: [
        {
          createdCell: (td) => {
            window.$(td).css("padding", "2px 0px 0px 14px");
          },
          data: "accountStatus.status",
          render: (_data) =>
            Boolean(_data)
              ? "<i style='font-size:x-large' class='mdi mdi-account-check text-success'></i>"
              : "<i style='font-size:x-large' class='mdi mdi-account text-secondary'></i>",
        },
        { data: "names" },
        { data: "email" },
        { data: "accountType" },
        // { data: "createdAt" },
        {
          data: "programmingLanguages",
        },
        {
          data: "technologies",
        },
        { data: "location" },
        { data: "contactNumber" },
        { data: "dob" },
        { data: "gender" },
        {
          data: "_id",
          innerWidth: 20,
          createdCell: (td, _data, rowData) =>
            ReactDOM.render(
              self.createButton(_data, !rowData.accountStatus.status),
              td
            ),
        },
      ],
    }).buttons().container().appendTo("#datatable-buttons_wrapper .col-md-6:eq(0)");
  }

  render() {
    return (
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body">
            <table
              id="datatable-buttons"
              className="table table-striped table-bordered dt-responsive nowrap"
              style={{
                borderCollapse: "collapse",
                wordWrap: "break-word",
                borderSpacing: 0,
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Names</th>
                  <th>Email</th>
                  <th>Account Type</th>
                  <th>Languages</th>
                  <th>Technologies</th>
                  <th>Location</th>
                  <th>Contact Number</th>
                  <th>DOB</th>
                  <th>Gender</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AppUsersList);
