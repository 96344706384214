import { _getDiscussions, postDiscussion, postDiscussionComment, getDiscussionComments as _getDiscussionComments } from "../../api/discussionService";

export const getDiscussions = (eventId, type) => {
  return (dispatch) => {
    return _getDiscussions(eventId, type).then((response) => {
      const discussions = response.data;
      let body = {};
      switch (type) {
        case "event":
          body = {
            type: "GET_EVENT_DISCUSSIONS",
            eventDiscussions: discussions,
          };
          break;
        case "team":
          body = {
            type: "GET_TEAM_DISCUSSIONS",
            teamDiscussions: discussions,
          };
          break;
        case "judge":
          body = {
            type: "GET_JUDGE_DISCUSSIONS",
            judgeDiscussions: discussions,
          };
          break;
        default:
          break;
      }
      return dispatch(body);
    });

  };
};

export const createDiscussion = (body) => {
  return (dispatch) => {
    return postDiscussion(body)
      .then((response) => {
        const discussion = response.data;
        let body = {};
        switch (discussion.type) {
          case "event":
            body = {
              type: "POST_EVENT_DISCUSSION",
              eventDiscussion: discussion,
            };
            break;
          case "team":
            body = {
              type: "POST_TEAM_DISCUSSION",
              teamDiscussion: discussion,
            };
            break;
          case "judge":
            body = {
              type: "POST_JUDGE_DISCUSSION",
              judgeDiscussion: discussion,
            };
            break;
          default:
            break;
        }
        return dispatch(body);
      })
  };
}

export const createTeamDiscussionComment = (body) => {
  return (dispatch) => {
    return postDiscussionComment(body)
      .then((response) => {
        const res = response.data;
        let comment = res.comment;
        let discussion = res.discussion;

        return dispatch({
          type: "POST_TEAM_DISCUSSION_COMMENT",
          teamComment: comment,
          teamDiscussions: discussion
        });
      })
  };
}

export const createDiscussionComment = (body) => {
  return (dispatch) => {
    return postDiscussionComment(body)
      .then((response) => {
        const { comment } = response.data;

        return dispatch({
          type: "POST_DISCUSSION_COMMENT",
          comment
        });
      })
  };
}

export const getDiscussionComments = (discussionId, type) => {
  return (dispatch) => {
    return _getDiscussionComments(discussionId)
      .then((response) => {
        const discussionComments = response.data;

        let body = {};

        switch (type) {
          case "event":
            body = {
              type: "GET_DISCUSSION_COMMENTS",
              comments: discussionComments,
            };
            break;
          case "team":
            body = {
              type: "GET_TEAM_DISCUSSIONS_COMMENTS",
              teamComments: discussionComments,
            };
            break;
          case "judge":
            body = {
              type: "GET_JUDGE_DISCUSSIONS_COMMENTS",
              judgeComments: discussionComments,
            };
            break;
          default:
            break;
        }
        return dispatch(body);
      })
  };
}

