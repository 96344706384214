import React, { useState } from "react";
import Error from "../partials/Error";

import { Link } from "react-router-dom";
import { isValidEmail, isNotEmpty } from "../../utils/utils";
import { connect } from "react-redux";
import { loginUser } from "../../store/actions/authActions";
import Cookies from "js-cookie";

function Login2(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return isValidEmail(email) && isNotEmpty(password) && password.length >= 8;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      props
        .loginUser({
          email,
          password,
        }).then(() => {

        })
        .catch((err) => {
          displayError(
            err.response
              ? err.response.data.message
              : "Failed to login. Please try again."
          );
          setIsLoading(false);
        });
    } else displayError("Please enter a valid email or password");
  }

  function displayError(_error) {
    setError(_error);
    setTimeout(() => {
      setError("");
    }, 5000);
  }

  return (
    <div className="login-signin-container">
      <div className=" login-wrapper">
        <div className="col login-signup-form" style={{ minHeight: "30rem" }}>

          <h2
            style={{
              marginTop: "1rem",
            }}
          >
            {" "}
            Sign In To Skills Ranker{" "}
          </h2>{" "}
          <p className="d-block d-sm-block d-md-none">
            {" "}

            Don 't have an account? <Link to="/register">
              {" "}
              Register here{" "}
            </Link>{" "}
          </p>{" "}
          {isLoading ? (
            "Logging in..."
          ) : (
            <form className="form" onSubmit={handleSubmit}>
              {" "}
              {error !== "" ? <Error error={error} /> : ""}{" "}
              <div className="mb-4">
                <input
                  id="email"
                  className=" login-signin-input form-control"
                  type="email"
                  required
                  placeholder="Email"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />{" "}
              </div>{" "}
              <div className="mb-4">
                <input
                  className="login-signin-input form-control"
                  id="userpassword"
                  type="password"
                  required
                  minLength="8"
                  maxLength="28"
                  placeholder="Password"
                  onChange={(event) => setPassword(event.target.value)}
                />{" "}
              </div>{" "}
              <div className="mt-3">
                <button
                  type="submit"
                  className=" login-btn btn btn-rounded waves-effect waves-light"
                >
                  {" "}
                  LOGIN{" "}
                </button>{" "}
              </div>{" "}
              <div className="mt-4 text-center">
                <Link to="/forgot" className="text-muted">
                  <i className="mdi mdi-lock mr-1"> </i> Forgot your password ?{" "}
                </Link>{" "}
              </div>{" "}
            </form>
          )}{" "}
        </div>{" "}
        <div className="signup-message d-none d-md-block vl">
          <div className="signup-message-wrapper">
            <div className="mb-4">

              <span>
                <img
                  onClick={() => props.history.push("/")}
                  src="./assets/images/logo2.png"
                  alt="skills ranker logo"
                  className="mb-3"
                  style={{
                    width: "100px",
                    cursor: "pointer",
                  }}
                />{" "}
              </span>{" "}
              <h1
                style={{
                  color: "gray",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                Are you an innovator{" "}
              </h1>{" "}
            </div>{" "}
            <div className="mb-4">
              <p style={{color:"gray"}}> Create an account, Build your profile and have your skills ranked, Be part of our developer
                network. </p>{" "}
            </div>{" "}
            <div className="mt-3">
              <button
                type="button"
                onClick={() => props.history.push("/register")}
                className="signup-1-btn btn btn-rounded waves-effect waves-light"
                style={{color:"black"}}>
                {" "}Register{" "}
              </button>{" "}
            </div>{" "}
            {/* <div className="mt-3">
              <button
                type="button"
                onClick={() => props.history.push("/register")}
                className="signup-1-btn btn btn-rounded waves-effect waves-light"
              >
                {" "}
                Developer account{" "}
              </button>{" "}
            </div>{" "} */}
          </div>{""}
        </div>{" "}
      </div>{" "}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (loginDetails) => dispatch(loginUser(loginDetails)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login2);
