import React, { Component } from "react";
import { connect } from "react-redux";
import Cookie from "js-cookie";
import Cookies from "js-cookie";
import Select from "react-select";
import { client } from "../api/client";
import { getLanguageIcon } from "../utils/utils";
import {
  fetchLanguages,
  fetchAllLanguages,
  updateLanguages,
} from "../store/actions/languagesActions";
import BadgeHorizontal from "./BadgeHorizontal";

class LanguagesList extends Component {
  state = {
    loaded: false,
    updating: false,
    isEdited: false,
    selectedLanguages: [],
    userLanguages: [],
    allLanguages: [],
  };

  constructor(...props) {
    super(...props);

    this.state = {
      loaded: false,
      updating: false,
      userLanguages: [],
    };
  }

  
  /**
   * returns state to render when no information is available,
   * with option to populate new data to state.
   * 
   */
  blank() {
    return (
      <div
        className="ml-4 mr-5"
        onClick={this.toggleEditing}
        style={{
          cursor: "pointer",
          display: "table",
          textAlign: "center",
          width: "100%",
          minHeight: "8rem",
          border: "dashed 1px gray",
        }}
      >
        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
          No languages info yet, click to add data
        </div>
      </div>
    );
  }

  /**
   * get all language options.
   * get all user selected languages.
   * update component state
   */
  componentDidMount() {
    this.props.getLanguages().then(() => {
      const languages = JSON.parse(Cookies.get("user")).programmingLanguages;
      
      if(languages.length > 0 ){

        this.props.getUserLanguages().then(() => {
          this.setState({
            selectedLanguages: this.props.userLanguages,
            userLanguages: this.props.userLanguages,
            allLanguages: this.props.allLanguages,
          });
        });
      }

      this.setState({
            userLanguages: this.props.userLanguages,
            allLanguages: this.props.allLanguages,
      });

    });
  }


/**
 * all users to update their language selections
 * update component state with new selections
 * 
 * @param  {} selectedLanguages new lanaguage selections
 */

  handleChange = (selectedLanguages) => {
    this.setState({
      selectedLanguages,
    });
  };


  /**
   * inidicates if a user is editing their language selections
   * and displays a form with all other language options to choose from
   * 
   */  
  toggleEditing = () => {
    this.setState({ isEdited: !this.state.isEdited });
  };


  /**
   * push profile languages updates to backend
   * 
   * @param  {} languages updated lanaguges
   */  
  updateProfile(languages) {
    this.setState({ isLoading: true });
    client
      .put("/profile", {
        languages,
        userId: JSON.parse(Cookie.get("user"))._id,
      })
      .then((user) => {
        
        
        if (user.data) {
          this.toggleEditing();
          Cookie.set("user", user.data);
          
          
          if(user.data.programmingLanguages.length > 0 ){
            
            this.props.getUserLanguages().then(() => {
              this.setState({
                selectedLanguages: this.props.userLanguages,
                userLanguages: this.props.userLanguages,
                allLanguages: this.props.allLanguages,
              });
            });
          }else{
            
            this.setState({
                userLanguages: [],
                selectedLanguages:[],
            })
          }

        }
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      Array.isArray(this.state.selectedLanguages) &&
      this.state.selectedLanguages.length
    ) {
      this.setState({
        updating: true,
      });
      this.updateProfile(this.state.selectedLanguages);
      // this.props
      //   .updateLanguages("123", this.state.selectedLanguages)
      //   .then(() => {

      //     // this.setState({ this.props.userLanguages});
      //   });
    } else {
      this.updateProfile([]);
    }
  };

  render() {
    return (
      <div className="card pl-4 pr-2">
        {/* <!-- Languages --> */}
        <div className="card-body">
          {!this.state.isEdited ? (
            <div className="float-right md-4 mr-3">
              {" "}
              <button
                onClick={this.toggleEditing}
                className="btn btn-outline-secondary waves-effect waves-light"
              >
                <i className="mdi mdi-lead-pencil"></i> &nbsp; Edit
              </button>
            </div>
          ) : (
            ""
          )}
          <h4 className="card-title text-uppercase" style={{ color: "#000" }}>
            Languages
          </h4>
        </div>
        {this.state.isEdited ? (
          <div className="pr-4 pl-4 pt-2 pb-2">
            {this.state.isLoading ? (
              <p>Submitting profile update...</p>
            ) : (
              <div className="d-flex mb-3">
                <div className="vw-100 mr-5">
                  <Select
                    options={this.state.allLanguages}
                    value={this.state.selectedLanguages}
                    onChange={this.handleChange}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    style={{ width: "-webkit-fill-available" }}
                    placeholder="Select multiple languages"
                  />
                </div>
                <div style={{ display: "contents" }} className="ml-5">
                  <button
                    onClick={this.handleSubmit}
                    // disabled={!this.state.isEdited}
                    className="btn btn-primary ml-2"
                  >
                    Save
                  </button>
                  <button
                    onClick={this.toggleEditing}
                    className="btn btn-secondary ml-2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <div className="row mb-4">
          {this.state.userLanguages.length > 0
            ? this.state.userLanguages.map((item, index) =>
                item ? (
                  <BadgeHorizontal
                    icon={getLanguageIcon(item.img)}
                    data={item.label}
                    key={item.id}
                  />
                ) : (
                  ""
                )
              )
            : this.blank()}
        </div>
        <div
          className="modal fade languages-modal bs-example-modal-center"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="mySmallModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-0">Add more Languages</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="form-horizontal" onSubmit={this.handleSubmit}>
                  <div className="form-group mb-0">
                    <label className="control-label">Select a language</label>
                    <br />
                    {/* {data.map(item =>
                                                <div className="col-lg-2 col-sm-2 mb-3" >
                                                    <div onClick={} style={{backgroundColor:'white',padding: '.6rem', cursor:'pointer', borderRadius: '.5rem', width:'100%', margin: 'auto', textAlign: 'center', boxShadow: 'rgba(18, 38, 63, 0.09) 0px 0.75rem 1.5rem'}}>
                                                        <h6 className="text-truncate" style={{margin: 'auto', fontsize: '20px', lineHeight: 2}}>{item.title}</h6>
                                                        <img className="mb-2" src="assets/images/5847f5bdcef1014c0b5e489c.png" style={{width:'45px', height:'45px'}}/>
                                                    </div>                        
                                                </div>
                                            )} */}
                    <Select
                      options={this.props.allLanguages}
                      onChange={this.handleChange}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select multiple languages"
                    />
                  </div>
                  <div className="mt-4">
                    <button className="btn btn-primary btn-block waves-effect waves-light">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userLanguages: state.languagesData.languagesList.map((i) => ({
      id: i._id,
      value: i._id,
      label: i.title,
      img: i.img,
    })),
    // languages: state.languagesData.allLanguages,
    allLanguages: state.languagesData.allLanguages.map((i) => ({
      id: i._id,
      value: i._id,
      label: i.title,
    })),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserLanguages: () => dispatch(fetchLanguages()),
    updateLanguages: (uid, langs) => dispatch(updateLanguages(uid, langs)),
    getLanguages: () => dispatch(fetchAllLanguages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesList);
