import React, { Component } from "react";
import { withRouter } from "react-router"
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

class PDFExport extends Component {

    state = {
    } 

    

    componentDidMount(){
        
        
    }
    printDiv() { 
         
    }
    printComponent = (id) => {
        
        var component = window.document.getElementById(id).innerHTML; 
        var a = window.open('', '', 'height=1000, width=800'); 
        a.document.write('<html>'); 
        a.document.write('<link href="/assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />');
        a.document.write('<link href="/assets/css/icons.min.css" rel="stylesheet" type="text/css" />');
        a.document.write('<link href="/assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />'); 
        a.document.write('<body >'); 
        a.document.write(component); 
        a.document.write('</body></html>'); 
        a.document.close(); 
        a.print();
    }


    
   render(){

      
    return (
        <>
            <a onClick={() => this.printComponent(this.props.title)} style={{color:'white'}} className="btn btn-primary waves-effect waves-light btn-sm">PDF</a>
            
        </>
    );
    
    }
}


export default withRouter(PDFExport);
