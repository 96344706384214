import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AppUsersList from "../../components/AppUsersList";
import AdminUsersGrid from "../../components/AdminUsersGrid";
import { fetchAppUsers } from "../../store/actions/usersActions";
import AdminUsersGridSkeleton from "../../components/skeletons/AdminUsersGridSkeleton";

class AppUsers extends Component {
  state = {
    users: [],
    useGrid: false,
    isLoaded: false,
    showNotification: false,
  };

  toggleLayout() {
    this.setState({
      users: this.props.users,
      isLoaded: true,
      useGrid: !this.state.useGrid,
    });
  }

  updateUsers(users) {
    try {
      this.setState({ users });
    } catch (error) { }
  }

  componentDidMount() {
    this.props.getUsers().then(() =>
      this.setState({
        isLoaded: true,
        users: this.props.users,
      })
    );
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}{" "}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18"> App Users </h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        {" "}
                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">Utility</a></li> */}{" "}
                        {/* <li className="breadcrumb-item active">System Users</li> */}{" "}
                        <li
                          onClick={() => this.toggleLayout()}
                          style={{
                            cursor: "pointer",
                            fontSize: "18pt",
                          }}
                          className="breadcrumb-item active"
                        >
                          {" "}
                          {this.state.useGrid ? (
                            <i className="bx bx-list-ul"> </i>
                          ) : (
                            <i className="bx bx-grid-alt"></i>
                          )}{" "}
                        </li>
                      </ol>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              {/* <!-- end page title --> */}
              <div className="row">
                {" "}
                {this.state.useGrid ? (
                  <AdminUsersGrid
                    updateUsers={this.updateUsers}
                    users={this.state.users}
                  />
                ) : (
                  <AppUsersList
                    updateUsers={this.updateUsers}
                    users={this.state.users}
                  />
                )}{" "}
              </div>{" "}
            </div>{" "}
            {/** <!-- container-fluid --> **/}{" "}
          </div>{" "}
          {/* <!-- End Page-content --> */}
          <footer className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6"> {new Date().getFullYear()} © Botswana Innovation Hub </div>{" "}
                <div className="col-sm-6">
                  <div className="text-sm-right d-none d-sm-block">
                    Skills Ranker{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </footer>{" "}
        </div>
      );
    } else {
      return <AdminUsersGridSkeleton />;
    }
  }
}

const mapStateToProps = (state) => {


  return {
    users: state.admin.appUsersList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (uid) => dispatch(fetchAppUsers(uid)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppUsers));
